.main-approval-preview-container-section .accordion-body{
  padding: 0;
}
.accordion-approval-preview-button {
  box-shadow: none;
}

.accordion-approval-preview-button:focus {
  box-shadow: none;
}

.accordion-approval-preview-button:not(.collapsed) {
  color: #212529;
}
.accordion-approval-preview-item:not(:first-of-type) {
  border-top: 1px solid #E8E8E8;
  border-radius: 0;
}

.accordion-approval-preview-item:first-of-type .accordion-approval-preview-button {
  border-radius: 0 !important;
}
.accordion-approval-preview-item {
  margin: 15px 0;
  border-radius: 0 !important;
}
.accordion-approval-preview-header button{ 
  border: 1px solid #E8E8E8;
}
.accordion-approval-preview-button:not(.collapsed) {
  color: #2C2C2C !important;
  background-color: #F6F5FF !important;
  box-shadow: none !important;
}
.accordion-approval-preview-body .custom-accordion-approval-preview-header {
  color: #6B6B6B;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 10px;
}
.custom-accordion-approval-preview-tbl th {
  text-align: left;
  padding: 15px 20px;
  vertical-align: bottom;
  border-bottom: 3px solid #ECECED;
}
.custom-accordion-approval-preview-tbl td {
  text-align: left;
  padding: 15px 20px;
  vertical-align: bottom;
}
.custom-accordion-approval-preview-item-content .custom-accordion-approval-preview-tbl tr:first-child td {
  border-top: 0px solid transparent;
}
.custom-accordion-approval-preview-item-content .custom-accordion-approval-preview-tbl td {
  border-top: 1px solid #EEEEEE;
}
.custom-accordion-approval-preview-header {
  display: flex;
  flex-direction: row;
}
.custom-accordion-approval-preview-tbl td .tbl-btn-status {
  font-size: 1.3rem;
  width: 98px;
  border: none;
  border-radius: 30px;
  height: 23px;
  text-align: center;
  padding-top: 2px;
  margin: 0;
}
.tbl-btn-status.not-started {
  color: #2C2C2C;
  background-color: #DDDBDB;
}
.tbl-btn-status.rejected {
  color: #EB1000;
  background-color: #FFE6E6;
}
.tbl-btn-status.in-progress {
  color: #3273DE;
  background-color: #DAECFF;
}
.tbl-btn-status.awaiting {
	color: #E9740A;
    background-color: #FFE9D4;
	white-space: nowrap;
    padding: 5px 15px;
    width: auto;
  }
.tbl-btn-status.approved {
  color: #009C3B;
  background-color: #DBF2E4;
}
.custom-accordion-approval-preview-header .custom-accordion-approval-preview-tbl {
  width: 100%;
}
.custom-accordion-approval-preview-item {
  display: flex;
  flex-direction: horizontal;
  width: 100%;
}
.custom-accordion-approval-preview-item .btn-checkbox {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.custom-accordion-approval-preview-item .btn-action {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: none;
}
.custom-accordion-approval-preview-item .btn-action .no-collapse {
  width: 15px;
  height: 15px;
  background-image: var(--bs-accordion-btn-icon);
  transform: rotate(0deg);
}
.custom-accordion-approval-preview-item .btn-action .yes-collapse {
  width: 15px;
  height: 15px;
  background-image: var(--bs-accordion-btn-icon);
  transform: rotate(180deg);
}
/* .custom-accordion-approval-preview-item .btn-action .check-box {} */
.custom-accordion-approval-preview-item .custom-accordion-approval-preview-item-content {
  display: flex;
  flex-direction: 'horizontal';
  width: 100%;
  align-items: self-start;
}
.custom-accordion-approval-preview-item-content .custom-accordion-approval-preview-tbl {
  width: 100%;
}
.custom-accordion-approval-preview-item-action .btn-action{
  width: 20px;
  height: 20px;
  background-color: transparent;
  margin-right: 15px;
  margin-top: 10px;
}
.custom-accordion-approval-preview-item-action .btn-action .arrow {
  border: solid #6B6B6B;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  width: 12px;
  height: 12px;
}

.custom-accordion-approval-preview-item-action .btn-action .up {
  border: solid #3273DE;
  border-width: 0 2px 2px 0;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 10px;
}

.custom-accordion-approval-preview-item-action .btn-action .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.btn-checkbox {
  margin-top: 13px;
}
.btn-checkbox .form-check {
  width: 13px;
  height: 13px;
  border-radius: 5px;
  position: relative;
}
.radio-btn .form-check-input[type=radio] {
  width: 18px;
  height: 18px;
  position: relative;
  left: -4px;
  top: -1px;
  margin-right: 0.5rem;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  border: 1px solid #C9CACD; 
  opacity: 1;
  border-radius: 50%;
}
.radio-btn .form-check-input:checked[type=radio] {
  width: 18px;
  height: 18px;
  /* background-image: none; */
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e') !important;
  border: 1px solid #C9CACD;
  opacity: 1;
  border-radius: 50%;
}
.radio-btn .form-check-input:disabled[type=radio] {
  width: 18px;
  height: 18px;
  background-image: none;
  /* background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e') !important; */
  border: 1px solid #C9CACD;
  opacity: 1;
  border-radius: 50%;
}

.radio-btn .form-check-input[type=radio] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  text-align: left;
  /* font: normal normal normal 14px/13px Adobe Clean; */
  letter-spacing: 0px;
  color: #2C2C2C;
  opacity: 1;
  cursor: default;
  /* padding: 5px 0 0 10px; */
}
.radio-btn .form-check-input:disabled {
  width: 18px;
  height: 18px;
  pointer-events: none;
  filter: none;
  background: #E0E0E0 0% 0% no-repeat padding-box !important;
  border: 1px solid #C9CACD;
  border-radius: 11px;
  opacity: 1;
}
/* .disabledRadioBtn {} */
.disabledRadioBtn .disabledCircle{
  width: 18px;
  height: 18px;
  display: inline-block;
  pointer-events: none;
  filter: none;
  background: #E0E0E0 0% 0% no-repeat padding-box !important;
  border: 1px solid #C9CACD;
  border-radius: 11px;
  opacity: 1;
  vertical-align: middle;
  margin-right: 10px;
}
.disabledRadioBtn .disabledText{
  display: inline-block;
  vertical-align: middle;
}