/*Theme Colors*/
.Carousel-block.wallOfFame {
  border: 1px solid hsla(0, 0%, 80%, 0.6);
  padding: 2.5rem 3rem !important;
  border-radius: 5px;
}
.Carousel-block.wallOfFame .card {
  border: none;
}
.Carousel-block.wallOfFame .card .card-img-top {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Carousel-block.wallOfFame .card .card-body {
  text-align: center;
}
.Carousel-block.wallOfFame .card .card-body .card-title {
  font-size: 1.6rem;
  color: #2C2C2C;
  font-weight: 600;
}
.Carousel-block.wallOfFame .card .card-body .card-subtitle {
  font-size: 1.5rem;
  color: #6b6b6b;
}
.Carousel-block.wallOfFame .card .card-body .card-text {
  font-size: 1.3rem;
  color: #a7a7a7;
}
.Carousel-block.wallOfFame .react-multiple-carousel__arrow--right {
  bottom: 25px;
  right: 50%;
  margin-right: -240px;
}
@media screen and (max-width: 992px) {
  .Carousel-block.wallOfFame .react-multiple-carousel__arrow--right {
    margin-right: -40px;
  }
}
.Carousel-block.wallOfFame .react-multiple-carousel__arrow--left {
  bottom: 25px;
  left: 50%;
  margin-left: -240px;
}
@media screen and (max-width: 992px) {
  .Carousel-block.wallOfFame .react-multiple-carousel__arrow--left {
    margin-left: -40px;
  }
}
.Carousel-block.wallOfFame.hidecontrols .react-multi-carousel-list {
  padding-bottom: 0;
}
.Carousel-block.wallOfFame .prev-button {
  left: -10px;
}
.Carousel-block.wallOfFame .next-button {
  right: -10px;
}
.Carousel-block.wallOfFame .view-all {
  margin-right: -15px;
}/*# sourceMappingURL=CardCertificationWallOfFame.css.map */