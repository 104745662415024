/*Theme Colors*/
.navigation .logoFooter {
  width: 10.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .logoMobile img {
    height: 25px;
  }
}

.btn-view {
  text-decoration: none !important;
}

.view-btn-font {
  font-size: 1.4rem !important;
}

.searchBoxUp {
  position: absolute;
  border: none;
  width: 100%;
  padding: 1rem;
  z-index: 99999;
  background-color: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3019607843);
}
.searchBoxUp .textSearch {
  color: #6b6b6b;
  font-size: 1.4rem;
}

.collapsing {
  width: 0;
}

.navLine:hover {
  text-shadow: none !important;
  color: #3273DE !important;
}

.notification-cnt {
  background: #eb1000;
  border-radius: 50px;
  padding: 0px 5px;
  font-size: 1.1rem;
  color: #fff;
  top: 0.5rem;
  left: 1.8rem;
  font-weight: bold;
}

.notificationModal .modal-dialog {
  position: absolute;
  width: 100%;
  right: 11rem;
  top: 3.5rem;
  max-width: 620px !important;
}
.notificationModal .modal-dialog .notifications-container {
  border: 0px solid transparent;
}
.notificationModal .modal-dialog .notifications-list {
  padding: 18px 8px;
}
.notificationModal .modal-dialog .notifications-list::before {
  left: 6px;
  right: 6px;
}
.notificationModal .modal-dialog .notifications-list .notifications-icon {
  top: 12px;
}
.notificationModal .modal-dialog .notifications-list .notifications-icon .icon {
  height: 18px;
}
.notificationModal .modal-dialog .notifications-list .notifications-details {
  flex-direction: column;
}
.notificationModal .modal-dialog .notifications-list .notifications-details .assignTime {
  width: 180px;
}
.notificationModal .modal-dialog .notifications-list .notifications-details .assigned {
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  .notificationModal .modal-dialog {
    right: auto;
  }
}
.notificationModal .modalTitle {
  font-size: 1.9rem;
  color: #2c2c2c;
  font-weight: bold;
}
.notificationModal .scroller {
  min-height: 28rem;
  max-height: calc(100vh - 14rem);
  overflow-y: auto;
  scrollbar-width: thin;
}
.notificationModal .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.notificationModal .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.notificationModal .showAll {
  font-size: 1.3rem;
  color: #2799f6;
}
.notificationModal .showAll:hover {
  color: #2c2c2c;
}
.notificationModal .showAll:active {
  color: #2c2c2c;
}
.notificationModal .assigned {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c2c2c;
}
.notificationModal .assigner {
  font-size: 1.4rem;
  color: #6b6b6b;
}
.notificationModal .assignTime {
  color: #6b6b6b;
  font-size: 1.5rem;
}/*# sourceMappingURL=navigation.css.map */