/*Theme Colors*/
.courseReports .css-13cymwt-control .css-1u9des2-indicatorSeparator {
  width: 0px;
}

.courseReportsTable {
  position: relative;
  border: 1px solid #ECECED;
  font-size: 1.7rem;
}
.courseReportsTable .rol-nme {
  color: #2799F6;
}
.courseReportsTable .table {
  margin-bottom: 0;
  border: 1px solid #ECECED;
}
.courseReportsTable .table .headerRows {
  border-bottom: 2px solid #ECECED;
}
.courseReportsTable .table .columnHeaders, .courseReportsTable .table .columnHeadersDetails {
  color: #6B6B6B;
  padding: 1rem;
  font-size: 1.7rem;
  text-align: center;
  white-space: nowrap;
}
.courseReportsTable .table .columnHeaders:first-child, .courseReportsTable .table .columnHeadersDetails:first-child {
  text-align: left;
  width: 18%;
}
.courseReportsTable .table .columnHeadersDetails {
  text-align: center;
  width: 15%;
}
.courseReportsTable .table .columnHeadersDetails:first-child {
  text-align: left;
  width: 25%;
}
.courseReportsTable .table .columnData, .courseReportsTable .table .columnDataDetails {
  color: #2C2C2C;
  font-size: 1.5rem;
  border-left: 0;
  text-align: center;
  padding: 1rem;
}
.courseReportsTable .table .columnData:first-child, .courseReportsTable .table .columnDataDetails:first-child {
  text-align: left;
}
.courseReportsTable .table .columnData .table-cell-button, .courseReportsTable .table .columnDataDetails .table-cell-button {
  background: transparent;
  border: 0px solid transparent;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.courseReportsTable .table .columnData .couponCode, .courseReportsTable .table .columnDataDetails .couponCode {
  color: #000;
  font-family: berninoFont, sans-serif;
  font-size: 100%;
  margin-bottom: 0;
  line-height: 20px;
  padding: 0;
}
.courseReportsTable .table .columnData .scheduledStatus, .courseReportsTable .table .columnDataDetails .scheduledStatus {
  color: #000;
  cursor: none;
}
.courseReportsTable .table .columnData .expiredStatus, .courseReportsTable .table .columnDataDetails .expiredStatus {
  color: #f44336;
}
.courseReportsTable .table .columnData .activeStatus, .courseReportsTable .table .columnDataDetails .activeStatus {
  color: #00b517;
}
.courseReportsTable .table .columnData .cert, .courseReportsTable .table .columnDataDetails .cert {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .courseReportsTable .table .columnData .cert, .courseReportsTable .table .columnDataDetails .cert {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.courseReportsTable .table .columnData .cert .certDesc, .courseReportsTable .table .columnDataDetails .cert .certDesc {
  color: #000;
  font-family: berninoFontBold, sans-serif;
  font-size: 110%;
  white-space: normal;
  text-align: left;
  margin-bottom: 0;
  line-height: 22px;
}
@media screen and (max-width: 1024px) {
  .courseReportsTable .table .columnData .cert .certDesc, .courseReportsTable .table .columnDataDetails .cert .certDesc {
    text-align: center;
    font-size: 90%;
    line-height: 18px;
    margin-top: 0.5rem;
  }
}
.courseReportsTable .table .columnDataDetails {
  text-align: center;
  width: 14%;
}
.courseReportsTable .table .columnDataDetails:first-child {
  text-align: left;
  width: 18%;
}
.courseReportsTable .table .columnDataDetails:nth-child(2) {
  width: 15%;
}

.sortIcons {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  flex-wrap: nowrap;
}
.sortIcons .cpb-1-up {
  height: auto;
}

.pagnation {
  text-align: center;
  flex-grow: 1;
}/*# sourceMappingURL=CourseReports.css.map */