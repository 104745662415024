/*Theme Colors*/
.horizontal-tab-block {
  border: 1px solid #e8e8e8;
  padding: 0 25px 25px 25px;
  border-radius: 5px;
}
.horizontal-tab-block .nav.nav-tabs::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.horizontal-tab-block .nav.nav-tabs {
  width: 100%;
  overflow-x: scroll;
  flex-wrap: nowrap;
  overflow-y: clip;
  margin-top: 1rem;
}
@media screen and (min-width: 576px) {
  .horizontal-tab-block .nav.nav-tabs {
    overflow: hidden;
  }
}
.horizontal-tab-block .nav.nav-tabs .nav-item {
  margin-right: 7.5rem !important;
}
@media screen and (max-width: 576px) {
  .horizontal-tab-block .nav.nav-tabs .nav-item {
    margin-right: 2rem;
  }
}
.horizontal-tab-block .nav.nav-tabs .nav-item .nav-link {
  font-size: 1.7rem;
  width: -moz-max-content;
  width: max-content;
  border: none;
  padding: 0px 0 5px;
}
.horizontal-tab-block .nav.nav-tabs .nav-item .nav-link.active {
  color: #000000;
  text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
  position: relative;
  border-bottom: 4px solid #3273de;
}
.horizontal-tab-block .nav.nav-tabs .nav-item .nav-link.active::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 4.5px;
  background: #3273de;
  bottom: -4px;
  left: 0;
  z-index: 1;
  border-radius: 10px;
}
.horizontal-tab-block .nav.nav-tabs .nav-item .nav-link:hover {
  text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
  border-bottom: 4px solid #3273de;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link:hover {
  border-color: #3273de !important;
}

.tab-content .accordion {
  border: 1px solid #ececed;
  margin-top: 20px;
}
.tab-content .accordion .accordion-item {
  border: none;
  margin: 0;
}
.tab-content .accordion .accordion-item:not(:last-child) .accordion-header .accordion-button:not(.collapsed)::after {
  display: none;
}
.tab-content .accordion .accordion-item:not(:last-child) .accordion-header .accordion-button::after {
  display: none;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button {
  border: none;
  font-size: 1.6rem;
  padding: 15px;
  padding-bottom: 20px;
  position: relative;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button[aria-expanded=true] {
  background-color: #f6f5ff;
  border-left: 3px solid #3273de !important;
  font-weight: bold;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button:hover {
  background-color: #f6f5ff;
  border-left: 3px solid #3273de;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #ececed;
  border-left: 2px solid #3273de;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  right: 20px;
  border: none;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button::after {
  right: 20px;
  border: none;
}
.tab-content .accordion .accordion-item .accordion-header .accordion-button .sub-text {
  color: #6b6b6b;
  font-size: 1.4rem;
  position: absolute;
  top: 25px;
  padding-top: 7px;
}
.tab-content .accordion::after {
  right: 20px;
  border: none;
}
.tab-content .accordion .sub-text {
  color: #6b6b6b;
  font-size: 1.4rem;
  position: absolute;
  top: 25px;
  padding-top: 7px;
}

.list-view .list-view-block {
  border: 1px solid #e8e8e8;
  width: 100%;
}
.list-view .list-view-block .list-view-block-img {
  border-radius: 5px;
  width: 98px;
  height: 68px;
}
.list-view .head-text {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.list-view .sub-text {
  font-size: 1.4rem;
}
.list-view .list-footer {
  background-color: #fafafa;
  display: flex;
  padding: 3px 15px;
  color: #707070;
}
.list-view .list-footer img {
  vertical-align: unset;
}
.list-view .list-footer .progress {
  width: 30%;
  background-color: #d9d9d9;
  margin-top: 1px;
  height: 5px !important;
}
.list-view .list-footer .primary-btn {
  margin-left: auto;
}

.accordion-collapse .list-view.cd {
  margin-left: 2rem;
}

.head-text {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.sub-text {
  font-size: 1.4rem;
}

.list-footer {
  background-color: #ececed;
  display: flex;
  padding: 3px 15px;
  color: #707070;
}
.list-footer img {
  vertical-align: unset;
}
.list-footer .progress {
  width: 30%;
  background-color: #d9d9d9;
  margin-top: 1px;
  height: 5px !important;
}
.list-footer .primary-btn {
  margin-left: auto;
}/*# sourceMappingURL=SolutionEnablement.css.map */