@import '../../assets/scss/variables';

.horizontal-tab-block {
  border: 1px solid #e8e8e8;
  padding: 0 25px 25px 25px;
  border-radius: 5px;
  .nav.nav-tabs::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .nav.nav-tabs {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: nowrap;
    overflow-y: clip;
    margin-top: 1rem;
    @media screen and (min-width: 576px) {
      // overflow-x:hidden;
      overflow: hidden;
    }

    .nav-item {
      margin-right: 7.5rem !important;

      @media screen and (max-width: 576px) {
        margin-right: 2rem;
      }
      .nav-link {
        // color: #000000 !important;
        // font-size: 1.7rem;
        font-size: 1.7rem;
        width: max-content;
        border: none;
        padding: 0px 0 5px;
        &.active {
          //  font-weight: 900;
          color: #000000;
          text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
          position: relative;
          border-bottom: 4px solid #3273de;
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 4.5px;
            background: #3273de;
            bottom: -4px;
            left: 0;
            z-index: 1;
            border-radius: 10px;
          }
        }
        &:hover {
          //  font-weight: 900;
          text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;

          border-bottom: 4px solid #3273de;
        }
      }
    }
  }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link:hover {
  border-color: #3273de !important;
}
.tab-content {
  .accordion {
    border: 1px solid #ececed;
    margin-top: 20px;
    .accordion-item {
      border: none;
      margin: 0;
      &:not(:last-child) {
        .accordion-header {
          .accordion-button {
            &:not(.collapsed) {
              &::after {
                display: none;
              }
            }
            &::after {
              display: none;
            }
          }
        }
      }
      .accordion-header {
        .accordion-button {
          border: none;
          font-size: 1.6rem;
          padding: 15px;
          padding-bottom: 20px;
          position: relative;
          &[aria-expanded="true"]{
            background-color: #f6f5ff;
            border-left: 3px solid #3273de !important;
            font-weight: bold;
          }
          &:hover {
            background-color: #f6f5ff;
            border-left: 3px solid #3273de;
          }

          &:not(.collapsed) {
            background-color: #ececed;
            border-left: 2px solid #3273de;
            &::after {
              right: 20px;
              border: none;
            }
          }
          &::after {
            right: 20px;
            border: none;
          }

          .sub-text {
            color: #6b6b6b;
            font-size: 1.4rem;
            position: absolute;
            top: 25px;
            padding-top: 7px;
          }
        }
      }
    }
    &::after {
      right: 20px;
      border: none;
    }

    .sub-text {
      color: #6b6b6b;
      font-size: 1.4rem;
      position: absolute;
      top: 25px;
      padding-top: 7px;
    }
  }
}

.list-view {
  .list-view-block {
    border: 1px solid #e8e8e8;
    //margin-top: 20px;
    width: 100%;
    //margin-left: 2rem;
    //&.cd {
    //    margin-left: 2rem;
    //  }
    .list-view-block-img {
      border-radius: 5px;
      width: 98px;
      height: 68px;
    }
  }
  .head-text {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .sub-text {
    font-size: 1.4rem;
  }
  .list-footer {
    background-color: #fafafa;
    display: flex;
    padding: 3px 15px;
    color: #707070;
    // flex-wrap: wrap;
    img {
      vertical-align: unset;
    }
    .progress {
      width: 30%;
      background-color: #d9d9d9;
      margin-top: 1px;
      height: 5px !important;
    }
    .primary-btn {
      margin-left: auto;
      // background-color: #fff;
    }
  }
}
.accordion-collapse {
  .list-view {
    &.cd {
      margin-left: 2rem;
    }
  }
}
.head-text {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.sub-text {
  font-size: 1.4rem;
}
.list-footer {
  background-color: #ececed;
  display: flex;
  padding: 3px 15px;
  color: #707070;
  // flex-wrap: wrap;
  img {
    vertical-align: unset;
  }
  .progress {
    width: 30%;
    background-color: #d9d9d9;
    margin-top: 1px;
    height: 5px !important;
  }
  .primary-btn {
    margin-left: auto;
  }
}
