@import "../../assets/scss/variables";

.learnerReports{
//  border: 1px solid #E8E8E8;
//  padding: 2rem;
  .css-13cymwt-control{
  .css-1u9des2-indicatorSeparator{
    width: 0px;
  }}
}

.learnerReportsTable {
  // margin: 1rem 4rem;
  position: relative;
  border: 1px solid #ECECED;
  font-size: 1.7rem;

  // @media screen and (max-width: 1024px) {
  //   margin: 1rem 2rem;
  // }
  .table {
    margin-bottom: 0;
    border: 1px solid #ECECED;
    .headerRows{
      border-bottom: 2px solid #ECECED;
    }

    .columnHeaders {
      color: #6B6B6B;
      font-size: 1.7rem;
      font-weight: 900;
      text-align: center;
      padding: 1rem;
	  white-space: nowrap;
    width: 15%;
    &:first-child {
      text-align: left;
      width: 18%;
    }
    &:nth-child(2) {
      text-align: center;
      width: 15%;
    }
    &:nth-child(6) {
      text-align: center;
      width: 10%;
    }

      span {
        svg {
          position: absolute;
          top: 0.9rem;
          height: 11px;
          width: 14px;
        }
      }

      .sortIcons {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        top: -5px;
        flex-wrap: nowrap;
        
        .cpb-1-up {
         height: auto;
        }
      }
    }

    .columnData {
      color:  #2C2C2C;
      font-size: 1.5rem;
      // border: 1px solid #000;
      border-left: 0;
      text-align: center;
      // vertical-align: middle;
      // white-space: nowrap;
      padding: 1rem;
      // width: 15%;

      &:first-child {
        text-align: left;
        // margin-bottom: 0;
        // color: #000;
        // font-family: berninoFontBold, sans-serif;
        // line-height: 20px;
      }
      &:nth-child(2) {
        text-align: center;
        width: 10%;
      }
      &:nth-child(6) {
        text-align: center;
        // width: 10%;
      }
      

      .couponCode {
        font-family: berninoFont, sans-serif;
        font-size: 100%;
        margin-bottom: 0;
        color: #000;
        line-height: 20px;
        padding: 0;
      }

      
      .table-cell-button {
        background: transparent;
        border: 0px solid transparent;
        text-decoration: underline;
        text-underline-offset: 3px;
      }

      .cert {
        display: flex;
        justify-content: space-around;
        align-items: center;

        // flex-direction: row;
        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .certDesc {
          font-family: berninoFontBold, sans-serif;
          color: #000;
          font-size: 110%;
          white-space: normal;
          text-align: left;
          margin-bottom: 0;
          line-height: 22px;

          @media screen and (max-width: 1024px) {
            text-align: center;
            font-size: 90%;
            line-height: 18px;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
.view-skill-journey-btn{
  border: 1px solid #2C2C2C !important;
    padding: 1px 20px !important;
    font-size: 1.3rem !important;
    border-radius: 30px !important;
    width: 15rem !important;
}
.sortIcons  {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  flex-wrap: nowrap;
  
  .cpb-1-up {
   height: auto;
  //  rotate: 180deg;
  }
  // .cpb-1-up {
  //   height: auto;
  //   filter: opacity(80%);
  //   &.active{
  //    filter: opacity(1);
  //   }
  //  //  rotate: 180deg;
  //  }
  //  .cpb-1{
  //    filter: opacity(80%);
  //   &.active{
  //    filter: opacity(1);
  //   }
  //  }
}
.pagnation{
  text-align: center;
  flex-grow: 1;
}
