@import '../../assets/scss/variables';
.allCourses {
  margin-top: 1rem;

  @media screen and (max-width: 991px) {
    margin-bottom: 2rem;
  }
  .all-courses-mobile {
    display: none;
    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
    }

    p {
      visibility: hidden;
    }
    .filterbtn {
      display: none;
      .filterbg {
        background-image: url('/src/assets/images/filter.svg');
        background-repeat: no-repeat;
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-bottom: -5px;
        margin-right: 5px;
      }

      @media (max-width: 991px) {
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        border: 1px solid #2c2c2c;
        padding: 2px 12px;
        margin-bottom: 1rem;
      }
    }
  }
  

  .search-cls {
    &:focus {
      border-bottom: none;
    }
  }
  @media (max-width: 767px) {
    .leftSectionAllCourses {
      display: none;
    }
  }

  .leftSectionAllCourses {
    border: 1px solid #e8e8e8;

    .titleHead {
      border-bottom: 1px solid #e8e8e8;
      .filterBy {
        font-size: 2.1rem;
        font-weight: bold;
      }
      .btn-view {
        font-size: 1.4rem;
        color: #2799f6;
        font-weight: 600;
      }
    }
    .subHead {
      font-size: 1.7rem;
      color: #6b6b6b;
    }
    .scroller {
      max-height: 10rem;
      overflow: auto;
      scrollbar-width: thin;
    }
    .scroller::-webkit-scrollbar {
      width: 0.5rem;
      background-color: #d9d6d6;
    }

    .scroller::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
  }
  .rightSectionAllCourses {
    // height:157.9rem;
    border: 1px solid #e8e8e8;
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
    .css-13cymwt-control {
      font-weight: bold;
    }
    .css-1nmdiq5-menu div {
      max-height: 50rem;
      width: auto;
      overflow: auto;
    }
    @media (max-width: 449px) {
      .css-1nmdiq5-menu {
        min-width: 9rem;
        overflow: auto;
      }
    }

    .css-1nmdiq5-menu div::-webkit-scrollbar {
      width: 0.5rem;
      background-color: #d9d6d6;
    }

    .css-1nmdiq5-menu div::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
    .card-block {
      width: 28.5rem;
      background: #ffffff;
      text-align: left;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      flex: 0 1 30%;
      position: relative;
      max-height: 100%;
      //margin: 10px 10px;
      @media screen and (max-width: 991px) {
        width: auto;

        //margin: 5px 10px 15px 0;
      }
      .btn.primary-btn {
        // padding: 2px 10px;
      }
    }
    .all-courses-div {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1rem;
      @media screen and (max-width: 376px) and (orientation: portrait) {
        display: block;
        margin-left: 0;
        margin-bottom: 2rem;
      }
      @media screen and (min-width: 377px) and (max-width: 767px) and (orientation: portrait) {
        display: block;
        margin-left: 1.5rem;
      }
    }
  }
}
.success-modal-container-mobile {
  padding: 1rem;
  // border: 1px solid #E8E8E8;

  .titleHead {
    border-bottom: 1px solid #e8e8e8;

    .filterBy {
      font-size: 1.7rem;
      font-weight: bold;
    }

    .btn-view {
      font-size: 1.4rem;
      color: #2799f6;
      font-weight: 600;
    }
  }

  .subHead {
    font-size: 1.7rem;
    color: #6b6b6b;
  }

  .scroller {
    max-height: 10rem;
    overflow: auto;
  }

  .scroller::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #d9d6d6;
  }

  .scroller::-webkit-scrollbar-thumb {
    background-color: #888888;
  }
}
.searh-icon {
  background-image: url('../../assets/images/search.png');
  background-repeat: no-repeat;
  background-position: 95%;
}
// .css-13cymwt-control{

.icon-class {
  display: none;
}
.css-1nmdiq5-menu {
  .icon-class {
    display: block;
  }
}
.searchBox1 {
  font-size: 1.4rem !important;
  padding-right: 4rem !important;
}
.loading-label {
  color: black !important; /* Or the desired color for the label */
}
.filterCheckbox {
  padding-bottom: 0.5rem;
  input {
    margin-right: 0.2rem;
    height: 2rem;
    width: 2rem;
    margin-top: 0px;
    border-color: #c9cacd;
    border-radius: 2px;
  }
  span {
    color: #2c2c2c;
    // margin-left: 0.3rem;
  }
}

