/*Theme Colors*/
.my-team-skills-submission {
  padding: 0px 30px;
  /* Styling modal */
}
@media screen and (max-width: 767px) {
  .my-team-skills-submission {
    margin-bottom: 3rem;
  }
}
.my-team-skills-submission .pageBorder {
  padding: 20px;
  border: 1px solid #e8e8e8;
  min-height: 75px;
}
.my-team-skills-submission .main-section {
  position: relative;
  padding: 20px;
}
.my-team-skills-submission .top-container-section {
  margin-bottom: 10px;
  position: sticky;
  top: 149px;
  z-index: 999;
  background: #fff;
  padding-bottom: 8px;
}
@media screen and (max-width: 997px) {
  .my-team-skills-submission .top-container-section {
    top: 98px;
  }
}
.my-team-skills-submission .main-section .search-section {
  margin-bottom: 30px;
}
.my-team-skills-submission .main-section .display-message {
  display: block;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  border-radius: 5px;
  padding: 35px 0 0 40%;
  color: #ffffff;
  font-size: 1.7rem;
}
.my-team-skills-submission .btn-view {
  font-size: 1.5rem;
  color: #2799f6;
  font-weight: 600;
}
.my-team-skills-submission .displayMessageClose {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  color: #666666;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 1.8rem;
  font-weight: bold;
}
.my-team-skills-submission .main-section .display-message-success {
  background-color: #009c3b;
}
.my-team-skills-submission .button-section {
  text-align: right;
}
.my-team-skills-submission .alm-btn-primary,
.my-team-skills-submission .alm-btn-primary:hover {
  color: #2c2c2c !important;
  font-size: 1.6rem !important;
  min-width: 100px !important;
  height: 39px;
  background: #ffffff !important;
  border: 2px solid #2c2c2c !important;
  border-radius: 50px !important;
  opacity: 1;
  display: inline-block;
  vertical-align: middle !important;
}
.my-team-skills-submission .alm-btn-link {
  color: #2799f6 !important;
  font-size: 1.6rem;
  height: 39px;
  background: #ffffff !important;
  border: 0px solid #2c2c2c !important;
  border-radius: 50px !important;
}
.my-team-skills-submission .alm-btn-submit-for-approval {
  width: 190px !important;
  margin-top: 22px;
}
.my-team-skills-submission .title-label {
  font-size: 1.5rem;
  text-align: left;
  letter-spacing: 0px;
  color: #6b6b6b;
  opacity: 0.9;
  min-height: 14px;
}
.my-team-skills-submission .filter-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.my-team-skills-submission .filter-section .buttnmrgn {
  margin: 0 0 10px 5px;
}
.my-team-skills-submission .filter-section .react-select-container .css-1qprcsu-option,
.my-team-skills-submission .filter-section .react-select-container .css-1vr111p-option {
  text-align: left;
  font-size: 1.4rem !important;
  font-weight: normal !important;
  letter-spacing: 0px;
  color: #2c2c2c;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.my-team-skills-submission .filter-section .react-select-container .css-ik6y5r,
.my-team-skills-submission .filter-section .react-select-container .css-11unzgr {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #f3f3f3;
  opacity: 1;
}
.my-team-skills-submission .top-container-section .container-section {
  text-align: left;
  font-size: 2.5rem;
  letter-spacing: 0px;
  color: #2c2c2c;
  opacity: 1;
}
.my-team-skills-submission .container-section-level {
  line-height: 36px;
}
.my-team-skills-submission .top-container-section .container-section .btn-clear-all .alm-btn-link {
  color: #2799f6 !important;
  font-size: 1.6rem;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0px solid #2c2c2c !important;
  border-radius: 50px !important;
}
.my-team-skills-submission .success-modal .modal-container {
  width: 100%;
  text-align: center;
}
.my-team-skills-submission .success-modal .modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.my-team-skills-submission .success-modal .modal-dialog {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 100% !important;
}
.my-team-skills-submission .success-modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-team-skills-submission .success-modal .modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.my-team-skills-submission .success-modal .modal-content {
  padding: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 13px;
  opacity: 1;
  width: 500px;
  margin: 0 auto;
}
.my-team-skills-submission .success-modal .modal-body {
  padding: 48px 48px 0 48px !important;
}
.my-team-skills-submission .success-modal .modal-footer {
  width: 100%;
  justify-content: center;
  padding: 20px 0;
}
.my-team-skills-submission .success-modal .modal-content {
  padding: 48px;
}
@keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
.my-team-skills-submission .dropdown-content {
  font-size: 1.5rem;
}
.my-team-skills-submission .dropdown-content .options::-webkit-scrollbar {
  overflow-y: auto;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #d9d6d6;
}
.my-team-skills-submission .dropdown-content .options::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.my-team-skills-submission .dropdown-content .options li {
  word-break: break-all;
}
.my-team-skills-submission .dropdown-content .options li label {
  padding: 0.5rem 1rem;
}
.my-team-skills-submission .modal-container .alm-btn-primary,
.my-team-skills-submission .modal-container .alm-btn-primary:hover {
  color: #2c2c2c !important;
  font-size: 1.6rem !important;
  min-width: 100px !important;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #2c2c2c !important;
  border-radius: 50px !important;
  opacity: 1;
  display: inline-block;
  vertical-align: middle !important;
}
.my-team-skills-submission .success-icon {
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background-image: url("../../assets/images/modalSuccessIcon.svg");
  background-repeat: no-repeat;
  background-size: 68px 68px;
}
.my-team-skills-submission .success-text-message {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
}
.my-team-skills-submission .css-17v20rl-control {
  width: 100%;
}
.my-team-skills-submission .css-b62m3t-container {
  margin-right: 1rem;
}
.my-team-skills-submission .skill-submission-btn span {
  vertical-align: unset;
}
.my-team-skills-submission .skill-submission .skill-submission-btn .btn-label span {
  vertical-align: unset;
}
.my-team-skills-submission .skill-submission .skill-submission-btn .btn-label-approve i,
.my-team-skills-submission .skill-submission .skill-submission-btn .btn-label-reject i {
  vertical-align: unset;
}

.success-text-message2 {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
  width: 100%;
}

@media screen and (max-width: 1309px) {
  .searchDeviceFix {
    margin-right: auto !important;
  }
}
@media screen and (max-width: 700px) and (min-width: 300px) {
  .searchDeviceFix {
    width: 100%;
  }
}/*# sourceMappingURL=MyTeamSkillsSubmission.css.map */