/*Theme Colors*/
.manageTemplates {
  margin-top: 3rem;
}
.manageTemplates .horizontal-tab-block {
  border: 1px solid #e8e8e8;
  padding: 0 25px 25px 25px;
  border-radius: 5px;
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs {
  width: 100%;
  overflow-x: scroll;
  flex-wrap: nowrap;
  overflow-y: clip;
  margin-top: 1rem;
}
@media screen and (min-width: 576px) {
  .manageTemplates .horizontal-tab-block .nav.nav-tabs {
    overflow: hidden;
  }
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs .nav-item {
  margin-right: 7.5rem !important;
}
@media screen and (max-width: 576px) {
  .manageTemplates .horizontal-tab-block .nav.nav-tabs .nav-item {
    margin-right: 2rem;
  }
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs .nav-item .nav-link {
  font-size: 1.7rem;
  width: -moz-max-content;
  width: max-content;
  border: none;
  padding: 0px 0 5px;
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs .nav-item .nav-link.active {
  color: #000000;
  text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
  position: relative;
  border-bottom: 4px solid #3273de;
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs .nav-item .nav-link.active::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 4.5px;
  background: #3273de;
  bottom: -4px;
  left: 0;
  z-index: 1;
  border-radius: 10px;
}
.manageTemplates .horizontal-tab-block .nav.nav-tabs .nav-item .nav-link:hover {
  text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
  border-bottom: 4px solid #3273de;
}
.manageTemplates .nav-tabs .nav-link.active,
.manageTemplates .nav-tabs .nav-item.show .nav-link,
.manageTemplates .nav-tabs .nav-link.active:hover,
.manageTemplates .nav-tabs .nav-link:hover {
  border-color: #3273de !important;
}
.manageTemplates .tab-content .accordion {
  border: 1px solid #ececed;
  margin-top: 20px;
}
.manageTemplates .tab-content .accordion .accordion-item {
  border: none;
  margin: 0;
}
.manageTemplates .tab-content .accordion .accordion-item:not(:last-child) .accordion-header .accordion-button:not(.collapsed)::after {
  display: none;
}
.manageTemplates .tab-content .accordion .accordion-item:not(:last-child) .accordion-header .accordion-button::after {
  display: none;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button {
  border: none;
  font-size: 1.6rem;
  padding: 15px;
  padding-bottom: 20px;
  position: relative;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button[aria-expanded=true] {
  background-color: #f6f5ff;
  border-left: 3px solid #3273de !important;
  font-weight: bold;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button:hover {
  background-color: #f6f5ff;
  border-left: 3px solid #3273de;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #ececed;
  border-left: 2px solid #3273de;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  right: 20px;
  border: none;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button::after {
  right: 20px;
  border: none;
}
.manageTemplates .tab-content .accordion .accordion-item .accordion-header .accordion-button .sub-text {
  color: #6b6b6b;
  font-size: 1.4rem;
  position: absolute;
  top: 25px;
  padding-top: 7px;
}
.manageTemplates .tab-content .accordion::after {
  right: 20px;
  border: none;
}
.manageTemplates .tab-content .accordion .sub-text {
  color: #6b6b6b;
  font-size: 1.4rem;
  position: absolute;
  top: 25px;
  padding-top: 7px;
}
.manageTemplates .list-view .list-view-block {
  border: 1px solid #e8e8e8;
  width: 100%;
}
.manageTemplates .list-view .list-view-block .list-view-block-img {
  border-radius: 5px;
  width: 98px;
  height: 68px;
}
.manageTemplates .list-view .head-text {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.manageTemplates .list-view .sub-text {
  font-size: 1.4rem;
}
.manageTemplates .list-view .list-footer {
  background-color: #fafafa;
  display: flex;
  padding: 3px 15px;
  color: #707070;
}
.manageTemplates .list-view .list-footer img {
  vertical-align: unset;
}
.manageTemplates .list-view .list-footer .progress {
  width: 30%;
  background-color: #d9d9d9;
  margin-top: 1px;
  height: 5px !important;
}
.manageTemplates .list-view .list-footer .primary-btn {
  margin-left: auto;
}
.manageTemplates .accordion-collapse .list-view.cd {
  margin-left: 2rem;
}
.manageTemplates .head-text {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.manageTemplates .sub-text {
  font-size: 1.4rem;
}
.manageTemplates .list-footer {
  background-color: #ececed;
  display: flex;
  padding: 3px 15px;
  color: #707070;
}
.manageTemplates .list-footer img {
  vertical-align: unset;
}
.manageTemplates .list-footer .progress {
  width: 30%;
  background-color: #d9d9d9;
  margin-top: 1px;
  height: 5px !important;
}
.manageTemplates .list-footer .primary-btn {
  margin-left: auto;
}
.manageTemplates .card1 .card {
  width: 50rem;
  min-height: 18rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .manageTemplates .card1 .card {
    width: 25rem;
  }
}
.manageTemplates .card1 .card .card-img-top {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}
.manageTemplates .card1 .card-body {
  text-align: center;
  min-height: 160px;
}
.manageTemplates .card1 .card-body .card-title {
  font-size: 1.6rem;
  color: #2C2C2C;
  font-weight: 600;
}
.manageTemplates .card1 .card-body .card-subtitle {
  font-size: 1.5rem;
  color: #6b6b6b;
}
.manageTemplates .card1 .card-body .card-text {
  font-size: 1.3rem;
  color: #a7a7a7;
}
@media screen and (max-width: 767px) {
  .manageTemplates .card1 .card-footer {
    padding: 5px;
  }
}
@media screen and (max-width: 767px) {
  .manageTemplates .footerButton {
    margin: 10px;
  }
}
.manageTemplates .fileValidation {
  color: #eb1000;
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
}
.manageTemplates .alm-file-upload-conatainer-content {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.manageTemplates .alm-file-upload-content {
  width: 300px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .manageTemplates .alm-file-upload-content {
    text-align: center;
    width: 210px;
  }
}
.manageTemplates .alm-file-upload-content .alm-file-upload-icon {
  display: inline-block;
  vertical-align: middle;
  width: 3.1rem;
  height: 3.1rem;
  margin-right: 1rem;
}
.manageTemplates .alm-file-upload-content .alm-file-upload-input {
  display: inline-block;
  vertical-align: middle;
  width: 28%;
  font-size: 1.5rem;
}
.manageTemplates .alm-file-upload-content .alm-file-upload-label {
  color: #707070;
  font-size: 1.5rem;
  vertical-align: middle;
  width: 30%;
  padding-left: 20px;
}
.manageTemplates .form-label {
  margin-bottom: 0;
}
.manageTemplates .alm-file-upload-uploaded-files img {
  vertical-align: text-bottom;
}
.manageTemplates .alm-file-upload-uploaded-files {
  margin-top: 1rem;
}
.manageTemplates .alm-file-upload-uploaded-files .navBackgroundColor {
  margin: 0 5px;
  display: block;
}
@media screen and (max-width: 767px) {
  .manageTemplates .alm-file-upload-uploaded-files .navBackgroundColor {
    width: 210px;
  }
}
.manageTemplates .form-label {
  margin-bottom: 0;
}
.manageTemplates .btnColorBg {
  background-color: #2799f6;
  border: 1px solid transparent;
  color: #fafafa;
}
.manageTemplates .btnColorBg:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.manageTemplates .statusText {
  font-size: 1.5rem;
  margin-top: 10px;
}
.manageTemplates .updateTemplateCard {
  margin: auto;
}
.manageTemplates .infoSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Base button styles */
.btnTable {
  border: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: hidden;
  display: inline-block;
}

.btnTable:hover .iconChanges {
  opacity: 1;
  transform: scale(1.2);
}

.btnTable:hover {
  transform: translateY(-2px);
  opacity: 1.5;
}

.btnTable:active {
  transform: translateY(1px);
  opacity: 0.8;
}

.right-barSelect {
  position: relative;
}
.right-barSelect::after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 4px;
  width: 1.5px;
  height: 1.2rem;
  background-color: #dadada;
  margin-left: 4px;
}

.right-barNewTemplate {
  position: relative;
}
.right-barNewTemplate::after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 4px;
  width: 1.5px;
  height: 1.2rem;
  background-color: #dadada;
  margin-left: 6px;
}

.timeStamp img {
  vertical-align: revert;
  position: relative;
  top: 2px;
}

.existingTemplates .learnerReports .learnerReportsTable {
  border: 1px solid #e8e8e8;
}
.existingTemplates .statusUI {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
}
.existingTemplates .infoSectionExisting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.existingTemplates .timeStamp img {
  vertical-align: revert;
}

.failedMessage {
  color: #eb1000;
}

.successMessage {
  color: #009C3B;
}

.iconChanges {
  height: 17px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.buttonUi {
  cursor: auto !important;
}

.btnTemplate {
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer; /* Default cursor */
}
.btnTemplate.no-underline {
  text-decoration: none; /* Remove underline */
}
.btnTemplate.no-pointer {
  cursor: default; /* Prevent pointer cursor when count is 0 */
}

.tableHeadings {
  text-align: center;
}/*# sourceMappingURL=Templates.css.map */