/*Theme Colors*/
.ViewDetail .head-text {
  font-size: 1.7rem;
  font-weight: 600;
}
.ViewDetail .btn-back {
  border: none;
  background: none;
  padding: 0 0 15px;
  margin: 0;
  font-size: 1.9rem;
  color: #3273de !important;
}
.ViewDetail .list-view {
  padding-right: 0 !important;
}

.viewSession {
  border: 1px solid #e8e8e8;
  padding: 20px;
  border-radius: 5px;
}/*# sourceMappingURL=ViewDetail.css.map */