/*Theme Colors*/
.ImportCsv .importCsvrow h3 {
  color: white;
  font-size: 2.8rem;
}
.ImportCsv .card {
  font-weight: bold;
  border: 0.1rem solid #e8e8e8;
  border-radius: 0.5rem;
  height: 100%;
}
.ImportCsv .card .card-body {
  font-weight: bold;
}
.ImportCsv .card .card-body .card-title {
  font-size: 1.6rem;
  font-weight: bold;
}
.ImportCsv .card .card-body .card-title .dropdownItem {
  background-color: #ffffff;
  color: #000000;
  border: 0.1rem solid #ededed;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.ImportCsv .card-footer {
  background: #fafafa;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.ImportCsv .card-footer img {
  vertical-align: revert;
}
.ImportCsv .text-browse {
  color: #2799f6;
}
.ImportCsv .text-Select,
.ImportCsv .otherText {
  font-size: 1.5rem;
}
.ImportCsv .text-Select {
  color: #707070;
}
.ImportCsv .statusText {
  color: #6b6b6b;
}
.ImportCsv .dropDownIcon {
  background: #f5f5f5;
  padding: 0.8rem 1rem 0rem 1rem;
  height: 3.1rem;
  width: 3.1rem;
  border-radius: 50%;
}
.ImportCsv .dropDownIcon img {
  vertical-align: revert;
}
.ImportCsv .navBackgroundColor {
  background-color: #f6f5ff;
  line-height: 28px;
  padding: 3px 10px;
}
.ImportCsv .navBackgroundColor img {
  vertical-align: revert;
}
.ImportCsv .right-bar {
  position: relative;
}
.ImportCsv .right-bar::after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 2px;
  width: 1.5px;
  height: 1.2rem;
  background-color: #dadada;
}
.ImportCsv .alm-file-upload-conatainer-content {
  display: block;
  margin: 30px auto;
  position: relative;
}
.ImportCsv .alm-file-upload-content {
  width: 300px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .ImportCsv .alm-file-upload-content {
    text-align: center;
  }
}
.ImportCsv .alm-file-upload-content .alm-file-upload-icon {
  display: inline-block;
  vertical-align: middle;
  width: 3.1rem;
  height: 3.1rem;
  margin-right: 1rem;
}
.ImportCsv .alm-file-upload-content .alm-file-upload-input {
  display: inline-block;
  vertical-align: middle;
  width: 28%;
  font-size: 1.5rem;
}
.ImportCsv .alm-file-upload-content .alm-file-upload-label {
  color: #707070;
  font-size: 1.5rem;
  vertical-align: middle;
  width: 30%;
  padding-left: 20px;
}
.ImportCsv .form-label {
  margin-bottom: 0;
}
.ImportCsv .alm-file-upload-uploaded-files {
  margin-top: 1rem;
}
.ImportCsv .alm-file-upload-uploaded-files .navBackgroundColor {
  margin: 0 5px;
  display: block;
}
.ImportCsv .form-label {
  margin-bottom: 0;
}

/* Styling modal */
@keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
.modal-container .alm-btn-primary,
.modal-container .alm-btn-primary:hover {
  color: #2c2c2c !important;
  font-size: 1.6rem !important;
  min-width: 100px !important;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #2c2c2c !important;
  border-radius: 50px !important;
  opacity: 1;
  display: inline-block;
  vertical-align: middle !important;
}

.success-icon {
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background-image: url("../../assets/images/modalSuccessIcon.svg");
  background-repeat: no-repeat;
  background-size: 68px 68px;
}

.success-text-message {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
}

.success-text-message2 {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
  width: 100%;
}

.show-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.show-loader .position-relative {
  top: 50%;
}

.show-display-box {
  font-size: 2.1rem;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  padding: 12% 20% 0 20%;
  text-align: center;
}

.show-display-box .display-box-message {
  background-color: white;
  color: green;
  padding: 10px;
}

.show-display-box .display-box-message-fail {
  background-color: white;
  color: red;
  padding: 10px;
}

.button-tooltip {
  background: transparent;
  border: none;
  padding: 0;
}
.button-tooltip img {
  vertical-align: unset !important;
  position: relative;
  top: 2px;
}

.infoHeader {
  font-size: 1.9rem !important;
  color: #222222;
  opacity: 1;
  font-weight: 600 !important; /* Left-align the text inside the modal body */
}

.infoPopup-body {
  max-height: 500px; /* Adjust the max height as needed */
  overflow-y: auto;
}

/* Adjust the padding to your liking */
.infoPopup-body .success-text-message2 {
  padding-right: 16px; /* Add padding to the right to make space for the scrollbar */
}

.infoPopup-body {
  font-size: 1.9rem;
  color: #222222;
  opacity: 1;
  width: 100%; /* Left-align the text inside the modal body */
}/*# sourceMappingURL=Importcsv.css.map */