/*Theme Colors*/
.soienablementteam {
  border: 1px solid #e8e8e8;
  padding: 2rem;
}
.soienablementteam .head-text {
  font-size: 1.7rem;
  font-weight: 600;
}
.soienablementteam .enablement-inner {
  border: 1px solid #e8e8e8;
  padding: 2rem;
}
.soienablementteam .enablement-inner.card_box {
  padding-right: 0;
}
.soienablementteam .enablement-inner.card_box .reachout {
  padding: 0;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner {
  border: 1px solid #cccccc;
  border-right: none;
  padding: 2rem 0.5rem;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner .dotted-border {
  border-bottom: 1.5px dashed #e8e8e8;
  margin-bottom: 20px;
  margin-top: 20px;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner .mail {
  padding: 3rem 1rem;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner .submit-file {
  padding: 3rem 1rem;
}
.soienablementteam .enablement-inner .circle-block img {
  padding: 9px 6px 9px 9px;
  background: #f5f5f5;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}
.soienablementteam .card {
  border: none;
}
.soienablementteam .card .card-img-top {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0 auto;
}
.soienablementteam .card .card-body {
  text-align: center;
}
.soienablementteam .card .card-body .card-title {
  font-size: 1.6rem;
  color: #2C2C2C;
  font-weight: 600;
}
.soienablementteam .card .card-body .card-subtitle {
  font-size: 1.5rem;
  color: #6b6b6b;
}
.soienablementteam .card .card-body .card-text {
  font-size: 1.4rem;
  color: #a7a7a7;
  margin-bottom: 0;
}/*# sourceMappingURL=enablementTeam.css.map */