@import "../../assets/scss/variables";

.emailtemplate{
  .quill{
    .ql-snow{
      a {
        color: #2799F6 !important;
    }  
      .ql-editing{
        left:39% !important;
        border: 2px solid #ccc !important;
    box-shadow: 7px 7px 8px #ddd !important;
    @media screen and (max-width:767px) {
      left: 2% !important;
    }
    @media screen and (min-width:767px) and (max-width:1028px) {
      left: 22% !important;
    }
      }
    }
  }
  .message-box{
    .info-text{
      position: relative;
      img{
       position: relative;
       top:-3px
      }
      .link-color{
        color:rgba(39, 153, 246, 1);
        cursor: pointer;
      }
      button{
        background: transparent;
        border: none;
        padding: 0;
      }
      .info-show-box{
        position: absolute;
        background-color: #fff;
        right:106%;
        padding: 10px 20px;
        min-width: 575px;
        top:-10px;
        border-radius: 5px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
        border: 1px solid rgba(167, 167, 167, 1);
        z-index: 2;
        @media (max-width: 991px) {
          min-width: 300px
        } 
        P{
          margin-bottom: 5px!important;
          font-size: 1.4rem;
        }
        &::after{
          content: "";
          background-image: url(../../assets/images/rightArrow.svg);
          display: inline-block;
          position: absolute;
          background-repeat: no-repeat;
          background-size: 100%;
          height: 35px;
          width: 35px;
          right: -21px;
          top: 0;
        }
        .variable-list{
          border-bottom: 1px solid #eee;
          display: flex;
          flex-flow: nowrap;
          padding-bottom: 3px;
          margin-bottom: 3px;
          &:last-child{
            padding-bottom: 0px;
            margin-bottom: 0px;
            border-bottom: none;
          }
          .variable-text{
            margin-right: 2rem;
            display: flex;
            align-items: flex-start;
            min-width: 108px;
          }
          .variable-details{
            flex-direction: column;
            display: flex;
          }
        }
      }
    }
  }
  .nav-pills{
    border: 1px solid #ececed;
  }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        /* background-color: var(--bs-nav-pills-link-active-bg); */
    }
    .nav-pills .nav-link {
        display: block;
        border-radius: 0px;
        border-left: 3px solid transparent!important;
		color: #6B6B6B;
		&:hover{
      background-color: #F6F5FF;
		}
      }
      
      .nav-pills .nav-link.active {
        background-color: #F6F5FF;
        border-left: 3px solid #3273de!important;
        font-weight: bold;
        color: #000;
      }
      
      .topendbtn{
        font-weight: bold;
      }

      
      
    .content-container {
        display: flex;
        flex-direction: column;
        
        .content-item {
            border: 1px solid #ECECED;
            padding: 10px;
            margin-bottom: -1px;
        }
    }
    .subject-line-container{
        label{
            color: #6B6B6B;
            font-size: 1.4rem;
        }
        .subject-line-input {
            width: 50%;
            height: 4rem;
            border: 1px solid #EDEDED;
            border-radius: 4px;
            font-weight: bold;
            font-size: 1.5rem;
			margin-bottom: 1rem;
			@media screen and (max-width:991px){
				width: 100%;
				
			}
        }
}
    .main-content-box{
        border: 1px solid #ECECED;
        padding: 1rem;
        margin-top: 1.8rem;
        
        .box-container {
            display: flex;
            flex-direction: row;
            align-items: center;
           
        }
    }
        .email-content-header{
            font-size: 1.7rem;
            color: #2C2C2C;
            border: 1px solid #EDEDED;
            padding: 1.5rem;
            .email-content-main{
                border: 1px solid #EDEDED;
                overflow: auto;
                max-height: 13rem;
                padding: 2rem;
            }
            .email-content-main::-webkit-scrollbar {
                width: 3px; /* Set the width of the scrollbar */
              }
              
              .email-content-main::-webkit-scrollbar-track {
                background-color: #FFFFFF; /* Set the background color of the scrollbar track */
                border-radius: 4px; /* Set the border radius of the scrollbar track */
              }
              
              .email-content-main::-webkit-scrollbar-thumb {
                background-color: #A7A7A7; /* Set the color of the scrollbar thumb */
                border-radius: 4px; /* Set the border radius of the scrollbar thumb */
              }
              
              .email-content-main::-webkit-scrollbar-thumb:hover {
                background-color: #555; /* Set the color of the scrollbar thumb on hover */
              }
        }
    
  
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    margin-right: 10px;
  }
  
        
    
    
    
}