@font-face {
    font-family: 'Adobe Clean';
    src: url('AdobeClean-ExtraBold.eot');
    src: url('AdobeClean-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('AdobeClean-ExtraBold.woff2') format('woff2'),
        url('AdobeClean-ExtraBold.woff') format('woff'),
        url('AdobeClean-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('AdobeClean-SemiLight.eot');
    src: url('AdobeClean-SemiLight.eot?#iefix') format('embedded-opentype'),
        url('AdobeClean-SemiLight.woff2') format('woff2'),
        url('AdobeClean-SemiLight.woff') format('woff'),
        url('AdobeClean-SemiLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('AdobeClean-Bold.eot');
    src: url('AdobeClean-Bold.eot?#iefix') format('embedded-opentype'),
        url('AdobeClean-Bold.woff2') format('woff2'),
        url('AdobeClean-Bold.woff') format('woff'),
        url('AdobeClean-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('AdobeClean-Regular.eot');
    src: url('AdobeClean-Regular.eot?#iefix') format('embedded-opentype'),
        url('AdobeClean-Regular.woff2') format('woff2'),
        url('AdobeClean-Regular.woff') format('woff'),
        url('AdobeClean-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

