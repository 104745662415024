/*Theme Colors*/
.learnerReports .css-13cymwt-control .css-1u9des2-indicatorSeparator {
  width: 0px;
}

.learnerReportsTable {
  position: relative;
  border: 1px solid #ECECED;
  font-size: 1.7rem;
}
.learnerReportsTable .table {
  margin-bottom: 0;
  border: 1px solid #ECECED;
}
.learnerReportsTable .table .headerRows {
  border-bottom: 2px solid #ECECED;
}
.learnerReportsTable .table .columnHeaders {
  color: #6B6B6B;
  font-size: 1.7rem;
  font-weight: 900;
  text-align: center;
  padding: 1rem;
  white-space: nowrap;
  width: 15%;
}
.learnerReportsTable .table .columnHeaders:first-child {
  text-align: left;
  width: 18%;
}
.learnerReportsTable .table .columnHeaders:nth-child(2) {
  text-align: center;
  width: 15%;
}
.learnerReportsTable .table .columnHeaders:nth-child(6) {
  text-align: center;
  width: 10%;
}
.learnerReportsTable .table .columnHeaders span svg {
  position: absolute;
  top: 0.9rem;
  height: 11px;
  width: 14px;
}
.learnerReportsTable .table .columnHeaders .sortIcons {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  flex-wrap: nowrap;
}
.learnerReportsTable .table .columnHeaders .sortIcons .cpb-1-up {
  height: auto;
}
.learnerReportsTable .table .columnData {
  color: #2C2C2C;
  font-size: 1.5rem;
  border-left: 0;
  text-align: center;
  padding: 1rem;
}
.learnerReportsTable .table .columnData:first-child {
  text-align: left;
}
.learnerReportsTable .table .columnData:nth-child(2) {
  text-align: center;
  width: 10%;
}
.learnerReportsTable .table .columnData:nth-child(6) {
  text-align: center;
}
.learnerReportsTable .table .columnData .couponCode {
  font-family: berninoFont, sans-serif;
  font-size: 100%;
  margin-bottom: 0;
  color: #000;
  line-height: 20px;
  padding: 0;
}
.learnerReportsTable .table .columnData .table-cell-button {
  background: transparent;
  border: 0px solid transparent;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.learnerReportsTable .table .columnData .cert {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .learnerReportsTable .table .columnData .cert {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.learnerReportsTable .table .columnData .cert .certDesc {
  font-family: berninoFontBold, sans-serif;
  color: #000;
  font-size: 110%;
  white-space: normal;
  text-align: left;
  margin-bottom: 0;
  line-height: 22px;
}
@media screen and (max-width: 1024px) {
  .learnerReportsTable .table .columnData .cert .certDesc {
    text-align: center;
    font-size: 90%;
    line-height: 18px;
    margin-top: 0.5rem;
  }
}

.view-skill-journey-btn {
  border: 1px solid #2C2C2C !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 15rem !important;
}

.sortIcons {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  flex-wrap: nowrap;
}
.sortIcons .cpb-1-up {
  height: auto;
}

.pagnation {
  text-align: center;
  flex-grow: 1;
}/*# sourceMappingURL=LearnerReports.css.map */