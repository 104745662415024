@import '../../assets/scss/variables';
.Carousel-block.recommendation {
  .time-recent .clock-img {
    margin-top: 0;
    --bs-gutter-x: 0;
  }
  .react-multi-carousel-track {
    // padding-bottom: 20px;
  }
  .carousel-container{
    padding-left: 5px !important;
    .react-multi-carousel-list.carousel-container{
      margin-left: 2px;
    } 
  }
  .card-block {
    //   .card-title{
    //    height: 3rem !important;
    //   }
    .card-content {
      .time-recent {
        display: flex;
      }
    }
    .discription {
      height: 45px;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 20px;

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .react-multiple-carousel__arrow--right {
    bottom: 25px;
    right: 50%;
    margin-right: -240px;
    @media screen and (max-width: 992px) {
      margin-right: -40px;
    }
  }
  .react-multiple-carousel__arrow--left {
    bottom: 25px;
    left: 50%;
    margin-left: -240px;
   
    @media screen and (max-width: 992px) {
      margin-left: -40px;
    }
   
  }
  
  .react-multi-carousel-list {
    // padding-bottom: 55px;
    // margin-left: 8px;
    .react-multi-carousel-item {
      // margin-left: 1px;      
    }
  }
  .prev-button {
    left: -30px;
  }
  .next-button {
    right: -25px;
  }
}
