/*Theme Colors*/
.allCourses {
  margin-top: 1rem;
}
@media screen and (max-width: 991px) {
  .allCourses {
    margin-bottom: 2rem;
  }
}
.allCourses .all-courses-mobile {
  display: none;
}
@media (max-width: 991px) {
  .allCourses .all-courses-mobile {
    display: flex;
    justify-content: space-between;
  }
}
.allCourses .all-courses-mobile p {
  visibility: hidden;
}
.allCourses .all-courses-mobile .filterbtn {
  display: none;
}
.allCourses .all-courses-mobile .filterbtn .filterbg {
  background-image: url("/src/assets/images/filter.svg");
  background-repeat: no-repeat;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  margin-bottom: -5px;
  margin-right: 5px;
}
@media (max-width: 991px) {
  .allCourses .all-courses-mobile .filterbtn {
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    border: 1px solid #2c2c2c;
    padding: 2px 12px;
    margin-bottom: 1rem;
  }
}
.allCourses .search-cls:focus {
  border-bottom: none;
}
@media (max-width: 767px) {
  .allCourses .leftSectionAllCourses {
    display: none;
  }
}
.allCourses .leftSectionAllCourses {
  border: 1px solid #e8e8e8;
}
.allCourses .leftSectionAllCourses .titleHead {
  border-bottom: 1px solid #e8e8e8;
}
.allCourses .leftSectionAllCourses .titleHead .filterBy {
  font-size: 2.1rem;
  font-weight: bold;
}
.allCourses .leftSectionAllCourses .titleHead .btn-view {
  font-size: 1.4rem;
  color: #2799f6;
  font-weight: 600;
}
.allCourses .leftSectionAllCourses .subHead {
  font-size: 1.7rem;
  color: #6b6b6b;
}
.allCourses .leftSectionAllCourses .scroller {
  max-height: 10rem;
  overflow: auto;
  scrollbar-width: thin;
}
.allCourses .leftSectionAllCourses .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.allCourses .leftSectionAllCourses .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.allCourses .rightSectionAllCourses {
  border: 1px solid #e8e8e8;
}
.allCourses .rightSectionAllCourses .css-1u9des2-indicatorSeparator {
  display: none;
}
.allCourses .rightSectionAllCourses .css-13cymwt-control {
  font-weight: bold;
}
.allCourses .rightSectionAllCourses .css-1nmdiq5-menu div {
  max-height: 50rem;
  width: auto;
  overflow: auto;
}
@media (max-width: 449px) {
  .allCourses .rightSectionAllCourses .css-1nmdiq5-menu {
    min-width: 9rem;
    overflow: auto;
  }
}
.allCourses .rightSectionAllCourses .css-1nmdiq5-menu div::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.allCourses .rightSectionAllCourses .css-1nmdiq5-menu div::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.allCourses .rightSectionAllCourses .card-block {
  width: 28.5rem;
  background: #ffffff;
  text-align: left;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
  position: relative;
  max-height: 100%;
}
@media screen and (max-width: 991px) {
  .allCourses .rightSectionAllCourses .card-block {
    width: auto;
  }
}
.allCourses .rightSectionAllCourses .all-courses-div {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
}
@media screen and (max-width: 376px) and (orientation: portrait) {
  .allCourses .rightSectionAllCourses .all-courses-div {
    display: block;
    margin-left: 0;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 377px) and (max-width: 767px) and (orientation: portrait) {
  .allCourses .rightSectionAllCourses .all-courses-div {
    display: block;
    margin-left: 1.5rem;
  }
}

.success-modal-container-mobile {
  padding: 1rem;
}
.success-modal-container-mobile .titleHead {
  border-bottom: 1px solid #e8e8e8;
}
.success-modal-container-mobile .titleHead .filterBy {
  font-size: 1.7rem;
  font-weight: bold;
}
.success-modal-container-mobile .titleHead .btn-view {
  font-size: 1.4rem;
  color: #2799f6;
  font-weight: 600;
}
.success-modal-container-mobile .subHead {
  font-size: 1.7rem;
  color: #6b6b6b;
}
.success-modal-container-mobile .scroller {
  max-height: 10rem;
  overflow: auto;
}
.success-modal-container-mobile .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.success-modal-container-mobile .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}

.searh-icon {
  background-image: url("../../assets/images/search.png");
  background-repeat: no-repeat;
  background-position: 95%;
}

.icon-class {
  display: none;
}

.css-1nmdiq5-menu .icon-class {
  display: block;
}

.searchBox1 {
  font-size: 1.4rem !important;
  padding-right: 4rem !important;
}

.loading-label {
  color: black !important; /* Or the desired color for the label */
}

.filterCheckbox {
  padding-bottom: 0.5rem;
}
.filterCheckbox input {
  margin-right: 0.2rem;
  height: 2rem;
  width: 2rem;
  margin-top: 0px;
  border-color: #c9cacd;
  border-radius: 2px;
}
.filterCheckbox span {
  color: #2c2c2c;
}/*# sourceMappingURL=FilterSearch.css.map */