@import '../../assets/scss/variables';
.navigation {
  .logoFooter {
    width: 7.5rem;
  }
}
.logoMobile{
  @media screen and (min-width:767px) and (max-width:1023px) {    
    img{
      height: 25px
    }
  }
}
.btn-view{
  text-decoration: none !important;
}
.view-btn-font{
  font-size: 1.4rem !important;
}
.searchBoxUp {
  position: absolute;
  border: none;
  width: 100%;
  padding: 1rem;
  z-index: 99999;
  background-color: #fff;
  box-shadow: 0px 3px 8px #0000004d;
  .textSearch {
    color: #6b6b6b;
    font-size: 1.4rem;
  }
}
.collapsing {
  width: 0;
}
.navLine {
  &:hover {
    text-shadow: none !important;
    // border-bottom: 3px solid #EB1000;
    color:#3273DE !important;
  }
}
.notification-cnt {
  background: #eb1000;
  border-radius: 50px;
  padding: 0px 5px;
  font-size: 1.1rem;
  color: #fff;
  top: 0.5rem;
  left: 1.8rem;
  font-weight: bold;
}
.notificationModal {
  .modal-dialog {
    position: absolute;
    width: 100%;
    right: 11rem;
    top: 3.5rem;
    max-width: 620px!important;
    .notifications-container {
      border: 0px solid transparent;
    }
    .notifications-list { 
      padding: 18px 8px;          
      &::before {
        left: 6px;
        right: 6px;
      }
      .notifications-icon { 
        top: 12px;
        .icon {
          height: 18px;
        }
      }
      .notifications-details { 
        flex-direction: column;
        .assignTime {
          width: 180px;
        }        
        .assigned { 
          font-weight: normal;
        }
      }
    }
    @media screen and (max-width: 767px) {
      right:auto;
    }
  }
  .modalTitle {
    font-size: 1.9rem;
    color: #2c2c2c;
    font-weight: bold;
  }

  .scroller {
    //max-height: 32.5rem; 
    //height: calc(100vh - 32.5rem);
    min-height: 28rem;
    max-height: calc(100vh - 14rem);
    overflow-y: auto;
    scrollbar-width: thin;
  }
  .scroller::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #d9d6d6;
  }

  .scroller::-webkit-scrollbar-thumb {
    background-color: #888888;
  }
  .showAll {
    font-size: 1.3rem;
    color: #2799f6;
    &:hover {
      color: #2c2c2c;
    }
    &:active {
      color: #2c2c2c;
    }
  }
  .assigned {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2c2c2c;
  }
  .assigner {
    font-size: 1.4rem;
    color: #6b6b6b;
  }
  .assignTime {
    color: #6b6b6b;
    font-size: 1.5rem;
  }
}
