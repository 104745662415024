/*Theme Colors*/
.soienablementteam {
  border: 1px solid #e8e8e8;
  padding: 2rem;
}
.soienablementteam .quill .ql-snow .ql-editing {
  left: 39% !important;
  border: 2px solid #ccc !important;
  box-shadow: 7px 7px 8px #ddd !important;
}
@media screen and (max-width: 767px) {
  .soienablementteam .quill .ql-snow .ql-editing {
    left: 2% !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1028px) {
  .soienablementteam .quill .ql-snow .ql-editing {
    left: 22% !important;
    top: 0 !important;
  }
}
.soienablementteam .head-text {
  font-size: 1.7rem;
  font-weight: 600;
}
.soienablementteam .enablement-inner {
  border: 1px solid #e8e8e8;
  padding: 2rem;
}
.soienablementteam .enablement-inner.card_box {
  padding-right: 0;
}
@media screen and (max-width: 767px) {
  .soienablementteam .enablement-inner.card_box {
    padding: 2rem;
  }
}
.soienablementteam .enablement-inner.card_box .reachout {
  padding: 0;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner {
  border: none !important;
  border-left: 1px solid #e8e8e8 !important;
  border-right: none;
  padding: 2rem 0.5rem;
}
@media screen and (max-width: 767px) {
  .soienablementteam .enablement-inner.card_box .reachout .reachout-inner {
    border-right: 1px solid #ccc;
  }
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner .dotted-border {
  border-bottom: 1.5px dashed #e8e8e8;
  margin-bottom: 20px;
  margin-top: 20px;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner .mail {
  padding: 3rem 1rem;
}
.soienablementteam .enablement-inner.card_box .reachout .reachout-inner .submit-file {
  padding: 3rem 1rem;
}
.soienablementteam .enablement-inner .circle-block img {
  padding: 9px 6px 9px 9px;
  background: #f5f5f5;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}
.soienablementteam .card {
  border: none;
}
.soienablementteam .card .card-img-top {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0 auto;
}
.soienablementteam .card .card-body {
  text-align: center;
}
.soienablementteam .card .card-body .card-title {
  font-size: 1.6rem;
  color: #2C2C2C;
  font-weight: 600;
}
.soienablementteam .card .card-body .card-subtitle {
  font-size: 1.5rem;
  color: #6b6b6b;
}
.soienablementteam .card .card-body .card-text {
  font-size: 1.4rem;
  color: #a7a7a7;
  margin-bottom: 0;
}

.show-for-manager .topendbtn {
  font-weight: bold;
}
.show-for-manager .btn.primary-btn {
  border-width: 1.5px;
}
.show-for-manager .box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.show-for-manager .nav-pills .nav-link {
  display: block;
  border: 1px solid #ececed;
  border-radius: 0px;
  background-color: #f9fafa;
  font-weight: bold;
}
.show-for-manager .footerSIO img {
  vertical-align: unset;
}/*# sourceMappingURL=enablementTeam.css.map */