@import '../../assets/scss/variables';
.skill-submission{
    .col-grid{
        flex: 0 0 auto;
        width: auto;
    }

    .card{
        .daterow{
        display: flex;
        flex-direction: row;
        .card-body{
            padding: 2rem 2rem 0.5rem 2rem;
            margin-bottom: 0 !important;
            flex: 0 0 auto;
            width: 50%;
            .textSubject{
                font-size: 1.4rem;
            }
            .subject-line-container{
                min-width: 22rem;
                @media screen and (max-width: 767px) {
                  width: auto;
                  }  
                input{
                    width: 100%;
                    font-size: 1.6rem;
                    padding:.6rem;
                    border: 1px solid #CED4DA;
                    border-radius: 5px;
                }
            }
        }
    }
    .download-msg{
        padding: 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        span{
            display: inline-flex;
        }
        button{
            font-size: 1.6rem; 
            margin-left: 5px;
            color: #2799F6;
        }
    }
    .card-footer {
        background: #fafafa;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        padding:0.5rem 2rem;
        color: #6B6B6B;
        font-size: 1.6rem;
        .primary-btn{
            font-size: 1.6rem;
        }
    }
    }
}