.searchInputs {
  display: flex;
  position: relative;
}

.search {
  border-bottom: 2px solid #e1e1e2;
}
.search input {
  background-color: white;
  border: 0;
  font-size: 1.7rem;
  padding: 15px 0;
  height: 20px;
  width: 100%;
}
.search input:focus {
  outline: none;
}

.searchIcon {
  height: 32px;
  background-color: white;
  display: grid;
  place-items: center;
  border: 0;
  position: absolute;
  right: 0;
  border-bottom: 2px solid #e1e1e2;
}
.searchIcon .pi-search {
  font-size: 1.6rem;
}
.searchIcon .pi-search:hover {
  color: #2799f6;
}

.searchClass {
  border-bottom: 0;
}

.searchInputDiv {
  border: 1px solid #ededed;
  border-radius: 5px;
  display: flex;
  padding: 0.3rem 1rem;
  position: relative;
}

.searchBtn {
  border-bottom: 0;
}/*# sourceMappingURL=SearchBar.css.map */