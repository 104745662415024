@import '../../assets/scss/variables';
.soienablementteam {
  .quill{
    .ql-snow{
      .ql-editing{
        left:39% !important;
        border: 2px solid #ccc !important;
    box-shadow: 7px 7px 8px #ddd !important;
    @media screen and (max-width:767px) {
      left: 2% !important;
    }
    @media screen and (min-width:767px) and (max-width:1028px) {
      left: 22% !important;
      top:0 !important;
    }
      }
    }
  }
  border: 1px solid #e8e8e8;
  padding: 2rem;
  .head-text {
    font-size: 1.7rem;
    font-weight: 600;
  }
  .enablement-inner {
    border: 1px solid #e8e8e8;
    padding: 2rem;
    &.card_box {
      padding-right: 0;
	  @media screen and (max-width: 767px) {
		padding: 2rem;
	  }
      .reachout {
        padding: 0;
        .reachout-inner {
          border: none !important;
          border-left: 1px solid #e8e8e8 !important;
          border-right: none;
          padding: 2rem 0.5rem;
          @media screen and (max-width: 767px) {
            border-right: 1px solid #ccc;
          }
          .dotted-border {
            border-bottom: 1.5px dashed #e8e8e8;
            margin-bottom: 20px;
            margin-top: 20px;
          }
          .mail {
            padding: 3rem 1rem;
          }
          .submit-file {
            padding: 3rem 1rem;
          }
        }
      }
    }
    .circle-block {
      img {
        padding: 9px 6px 9px 9px;
        background: #f5f5f5;
        border-radius: 50%;
        height: 45px;
        width: 45px;
      }
    }
  }

  .card {
    .card-img-top {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin: 0 auto;
    }
    border: none;
    .card-body {
      text-align: center;
      .card-title {
        font-size: 1.6rem;
        color: $primaryColorBlack;
        font-weight: 600;
      }
      .card-subtitle {
        font-size: 1.5rem;
        color: #6b6b6b;
      }
      .card-text {
        font-size: 1.4rem;
        color: #a7a7a7;
        margin-bottom: 0;
      }
    }
  }
}

.show-for-manager {
  .topendbtn {
    font-weight: bold;
  }
  .btn.primary-btn {
    border-width: 1.5px;
  }
  .box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .nav-pills .nav-link {
    display: block;
    border: 1px solid #ececed;
    border-radius: 0px;
    background-color: #f9fafa;
    font-weight: bold;
  }
  .footerSIO{
    img{
      vertical-align: unset;
    }
  }
}
