@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.roleskillsjourney {
  .horizontal-tab-block{
    padding: 0 25px 25px 25px;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-b62m3t-container {
    // width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .top-section {
    @media screen and (max-width: 767px) {
      flex-direction: column !important;
    }
    .selectDrop {
      width: 85%;
      @media screen and (max-width: 767px) {
        width: 100%;
        flex-direction: column !important;
      }
      .dropD1 {
        @media screen and (max-width: 767px) {
          padding-top: 0.5rem;
          width: 50%;
        }
      }
      .dropD2 {
        @media screen and (max-width: 767px) {
          padding-top: 0.5rem;
          width: 50%;
        }
      }
    }

    .butnSpace {
      width: 15%;
      @media screen and (max-width: 767px) {
        margin-top: 5px;
        width: 100%;
      }
      .submitskills {
        width: 100%;
        @media screen and (max-width: 767px) {
          // padding: 7px;
          // width: 100%;
        }
      }
    }
    .custom-wrap {
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
      button {
        white-space: nowrap;
      }
    }
	input[type="checkbox"]{
		height: 22px;
		width: 22px;
		margin-top: 0.1rem;
		margin-right: 1rem;
		border-radius: 2px;
		border-color: #C9CACD;
	}
	label{
		span{
			font-size: 1.7rem;
			color: #2c2c2c;
		}
	}
  }
}

.learnerMember label{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh; /* Adjust this value for desired vertical centering */
    // background-color: #f0f0f0; /* Background color */
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

.failure-modal-container-body {
  text-align: center;
  .failed-icon {
    width: 68px;
    height: 68px;
    margin: 0 auto;
    // background-image: url("../../assets/images/failed_icon.svg");
    background-repeat: no-repeat;
    background-size: 68px 68px;
  }
  .failure-text-message {
    text-align: center;
    font-size: 1.9rem;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    padding: 36px 0 20px 0;
  }
}
.failure-modal-container-footer {
  .btn-view {
    color: #222222;
    font-size: 1.7rem;
    font-weight: bold;
  }
}
.assign-people-modal {
  .modal-header {
    box-shadow: 0px 1px 3px #00000029;
  }
  .header-line {
    font-weight:bold;
    font-size: 2.1rem;
  }
  .search-bar {
    border-radius: 0 !important;
    // border: 0;
    border-bottom: 1px solid #e5e7e9;
    margin: 1rem 0;
    background-image: url('../../assets/images/search-bar.svg');
    background-position: 98%;
    background-repeat: no-repeat;
    font-size: 1.5rem;
  }
  .custom-height {
    max-height: 13rem;
    overflow-y: scroll;
    //height: 13rem;
    /* width */

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
      @supports (-moz-appearance: none) {
        background: #f1f1f1;
        border-radius: 10px;
      }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #9fa0a5;
      border-radius: 10px;
      @supports (-moz-appearance: none) {
        background: #9fa0a5;
        border-radius: 10px;
      }
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #828387;
      @supports (-moz-appearance: none) {
        background: #828387;
      }
    }
  }
}
.data-not-found {
  text-align: center;
  padding: 25px;
  font-size: 2.1rem;
  color: red;
}
.select-option {
  width: 190px;
  margin-right: 15px;
}
.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}
