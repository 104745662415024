/*Theme Colors*/
.emailvptemplate .centercard {
  height: 50vh;
  width: 100%;
}
.emailvptemplate .card .card-body {
  padding: 2rem 2rem 0.5rem 2rem;
}
.emailvptemplate .card .card-body:nth-child(2) {
  margin-bottom: 2rem;
}
.emailvptemplate .card .card-body .textSubject {
  color: #6b6b6b;
  font-size: 1.4rem;
}
.emailvptemplate .card .card-body #subjectInput {
  font-size: 1.5rem;
  font-weight: bold;
  resize: none;
}
.emailvptemplate .card .card-body .scroller {
  max-height: 20rem;
  overflow-y: auto;
  scrollbar-width: thin;
}
.emailvptemplate .card .card-body .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.emailvptemplate .card .card-body .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.emailvptemplate .card .card-body .error-color {
  color: red;
}
.emailvptemplate .card .card-footer {
  background: #fafafa;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  font-size: 1.25rem;
}
.emailvptemplate .card .card-footer img {
  position: relative;
  top: -2px;
}/*# sourceMappingURL=EditVpTemplate.css.map */