/*Theme Colors*/
.roleskillsjourney .horizontal-tab-block {
  padding: 0 25px 25px 25px;
}
.roleskillsjourney .css-1u9des2-indicatorSeparator {
  display: none;
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .css-b62m3t-container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .top-section {
    flex-direction: column !important;
  }
}
.roleskillsjourney .top-section .selectDrop {
  width: 85%;
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .top-section .selectDrop {
    width: 100%;
    flex-direction: column !important;
  }
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .top-section .selectDrop .dropD1 {
    padding-top: 0.5rem;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .top-section .selectDrop .dropD2 {
    padding-top: 0.5rem;
    width: 50%;
  }
}
.roleskillsjourney .top-section .butnSpace {
  width: 15%;
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .top-section .butnSpace {
    margin-top: 5px;
    width: 100%;
  }
}
.roleskillsjourney .top-section .butnSpace .submitskills {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .roleskillsjourney .top-section .custom-wrap {
    flex-wrap: wrap;
  }
}
.roleskillsjourney .top-section .custom-wrap button {
  white-space: nowrap;
}
.roleskillsjourney .top-section input[type=checkbox] {
  height: 22px;
  width: 22px;
  margin-top: 0.1rem;
  margin-right: 1rem;
  border-radius: 2px;
  border-color: #C9CACD;
}
.roleskillsjourney .top-section label span {
  font-size: 1.7rem;
  color: #2c2c2c;
}

.learnerMember label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Adjust this value for desired vertical centering */
}

.failure-modal-container-body {
  text-align: center;
}
.failure-modal-container-body .failed-icon {
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: 68px 68px;
}
.failure-modal-container-body .failure-text-message {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
}

.failure-modal-container-footer .btn-view {
  color: #222222;
  font-size: 1.7rem;
  font-weight: bold;
}

.assign-people-modal .modal-header {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
}
.assign-people-modal .header-line {
  font-weight: bold;
  font-size: 2.1rem;
}
.assign-people-modal .search-bar {
  border-radius: 0 !important;
  border-bottom: 1px solid #e5e7e9;
  margin: 1rem 0;
  background-image: url("../../assets/images/search-bar.svg");
  background-position: 98%;
  background-repeat: no-repeat;
  font-size: 1.5rem;
}
.assign-people-modal .custom-height {
  max-height: 13rem;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.assign-people-modal .custom-height::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}
.assign-people-modal .custom-height::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
@supports (-moz-appearance: none) {
  .assign-people-modal .custom-height::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
}
.assign-people-modal .custom-height::-webkit-scrollbar-thumb {
  background: #9fa0a5;
  border-radius: 10px;
}
@supports (-moz-appearance: none) {
  .assign-people-modal .custom-height::-webkit-scrollbar-thumb {
    background: #9fa0a5;
    border-radius: 10px;
  }
}
.assign-people-modal .custom-height::-webkit-scrollbar-thumb:hover {
  background: #828387;
}
@supports (-moz-appearance: none) {
  .assign-people-modal .custom-height::-webkit-scrollbar-thumb:hover {
    background: #828387;
  }
}

.data-not-found {
  text-align: center;
  padding: 25px;
  font-size: 2.1rem;
  color: red;
}

.select-option {
  width: 190px;
  margin-right: 15px;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}/*# sourceMappingURL=RoleSkillsJourney.css.map */