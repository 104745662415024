@import "../../assets/scss/variables";
.certificaion-all{
    border: 1px solid #E8E8E8;
    padding: 2rem;
    .head-text{
        font-size: 1.7rem;
        font-weight: 600;
    }
    .card{
        .card-img-top{
            border-radius: 50%;
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
        border:none;
        .card-body{
            text-align: center;
            .card-title{
                font-size: 1.6rem;
                color:$primaryColorBlack;
                font-weight: 600;
            }
            .card-subtitle{
                font-size: 1.5rem;
                color:#6B6B6B;
                
            }
            .card-text{
                font-size: 1.3rem;
                color:#A7A7A7;
            }
        }
    }
}