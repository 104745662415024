@import '../../assets/scss/variables';
.myskilljourney {
  padding: 0px 30px;
  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }

  //  padding: 0px;
  .pageBorder {
    padding: 20px;
    border: 1px solid #e8e8e8;
  }
  .myskilljourney .main-section {
    margin-top: 30px;
    position: relative;
    padding: 20px 30px;
  }
  .top-container-section {
    // margin-bottom: 20px;
    position: sticky;
    top: 14.9rem;
    z-index: 999;
    background: #fff;
    padding-bottom: 8px;
    @media screen and (max-width:991px) {
      top: 90px;
    }
  }
  .main-section .search-section {
    margin-bottom: 30px;
  }
  .main-section .display-message {
    display: block;
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    border-radius: 5px;
    padding: 35px 0 0 40%;
    color: #ffffff;
    font-size: 1.7rem;
  }
  .btn-view {
    font-size: 1.5rem;
    color: #2799f6;
    font-weight: 600;
    text-decoration: none;
  }
  .displayMessageClose {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #666666;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background: #ffffff;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .main-section .display-message-success {
    background-color: #009c3b;
  }
  .button-section {
    text-align: right;
  }
  .alm-btn-primary,
  .alm-btn-primary:hover {
    color: #2c2c2c !important;
    font-size: 1.6rem !important;
    min-width: 100px !important;
    height: 39px;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 2px solid #2c2c2c !important;
    border-radius: 50px !important;
    opacity: 1;
    display: inline-block;
    vertical-align: middle !important;
  }
  .alm-btn-link {
    color: #2799f6 !important;
    font-size: 1.6rem;
    height: 39px;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 0px solid #2c2c2c !important;
    border-radius: 50px !important;
  }
  .alm-btn-submit-for-approval {
    width: 190px !important;
    margin-top: 22px;
  }

  .title-label {
    //font-weight: bold;
    font-size: 1.5rem;
    line-height: 22px;
    text-align: left;
    letter-spacing: 0px;
    color: #6b6b6b;
    opacity: 0.9;
    min-height: 22px;
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }

  .filter-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filter-section .react-select-container .css-1qprcsu-option,
  .filter-section .react-select-container .css-1vr111p-option {
    text-align: left;
    // font: normal normal bold 14px/14px Adobe Clean !important;
    font-weight: normal !important;
    letter-spacing: 0px;
    color: #2c2c2c;
    background: #ffffff;
    opacity: 1;
  }
  .filter-section .react-select-container .css-ik6y5r,
  .filter-section .react-select-container .css-11unzgr {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f3f3f3;
    opacity: 1;
  }

  .top-container-section .container-section {
    text-align: left;
    line-height: 36px;
    letter-spacing: 0px;
    color: #2c2c2c;
    opacity: 1;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .top-container-section .container-section .btn-clear-all .alm-btn-link {
    color: #2799f6 !important;
    font-size: 1.6rem;
    height: 39px;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 0px solid #2c2c2c !important;
    border-radius: 50px !important;
  }
  .cancel-btn {
    font-weight: bold;
    font-size: 1.5rem;
  }

  /* Styling modal */
  .modal-container {
    width: 100%;
    text-align: center;
  }
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .modal .modal-content {
    padding: 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 13px;
    opacity: 1;
    width: 500px;
    margin: 0 auto;
  }
  .modal .modal-body {
    padding: 48px 48px 0 48px !important;
  }
  .modal .modal-footer {
    width: 100%;
    justify-content: center;
    padding: 20px 0;
  }
  // .modal-dialog {}
  .modal-content {
    padding: 48px;
  }

  @-webkit-keyframes modal-animation {
    from {
      top: -100px;
      opacity: 0;
    }
    to {
      top: 0px;
      opacity: 1;
    }
  }

  @keyframes modal-animation {
    from {
      top: -100px;
      opacity: 0;
    }
    to {
      top: 0px;
      opacity: 1;
    }
  }

  // .modal-container {}
  .modal-container .alm-btn-primary,
  .modal-container .alm-btn-primary:hover {
    color: #2c2c2c !important;
    font-size: 1.6rem !important;
    min-width: 100px !important;
    height: 39px;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 2px solid #2c2c2c !important;
    border-radius: 50px !important;
    opacity: 1;
    display: inline-block;
    vertical-align: middle !important;
  }
  // .success-modal-container {}
  .success-icon {
    width: 68px;
    height: 68px;
    margin: 0 auto;
    background-image: url('../../assets/images/modalSuccessIcon.svg');
    background-repeat: no-repeat;
    background-size: 68px 68px;
  }

  .success-text-message {
    text-align: center;
    // font: normal normal normal 18px/26px Adobe Clean;
    font-size: 1.9rem;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    padding: 36px 0 20px 0;
  }
}
.reject-modal {
  .reject-icon {
    width: 7rem;
    height: 3rem;
    margin: 0;
    background-image: url('../../assets/images/reject-icon.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    display: block;
  }
  .success-modal-container {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0 1.5rem 0;
    background-color: #f6f5ff;
    border: 1px solid #ececed;
    margin-bottom: 2rem;
  }
  .success-text-message {
    text-align: center;
    // font: normal normal normal 18px/26px Adobe Clean;
    font-size: 1.9rem;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    padding: 0 0 1.5rem 0;
    margin-bottom: 0;
  }
  .reason {
    text-align: left;
    // font: normal normal normal 13px/24px Adobe Clean;
    letter-spacing: 0px;
    color: #222222;
    margin-bottom: 0.5rem;
  }
  .reason-textarea {
    resize: none;
    width: 100%;
    border: 1px solid #ececed;
    border-radius: 4px;
    padding: 0.5rem;
    &:focus-visible {
      border: 2px solid #ececed;
      outline: none;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
      @supports (-moz-appearance: none) {
        background: #f1f1f1;
        border-radius: 10px;
      }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #9fa0a5;
      border-radius: 10px;
      @supports (-moz-appearance: none) {
        background: #9fa0a5;
        border-radius: 10px;
      }
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #828387;
      @supports (-moz-appearance: none) {
        background: #828387;
      }
    }
  }

  // .primary-btn {
  //   font-size: 1.7rem;
  // }
}

/* Styling modal */
//.modal-container {
//    width: 100%;
//    text-align: center;
//}
//.modal:before {
//    content: '';
//    display: inline-block;
//    height: 100%;
//    vertical-align: middle;
//}

//.modal-dialog {
//    display: inline-block;
//    vertical-align: middle;
//    margin: 0 auto !important;
//    width: 100% !important;
//    max-width: 100% !important;
//}
//.modal {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//}
//.modal:before {
//    content: '';
//    display: inline-block;
//    height: 100%;
//    vertical-align: middle;
//}
//.modal .modal-content {
//    padding: 0px;
//    background: #FFFFFF 0% 0% no-repeat padding-box;
//    border: 1px solid #707070;
//    border-radius: 13px;
//    opacity: 1;
//    width:500px;
//    margin: 0 auto;
//}
//.modal .modal-body {
//    padding: 48px 48px 0 48px !important;
//}
//.modal .modal-footer {
//    width: 100%;
//    justify-content: center;
//    padding: 20px 0;
//}
//.modal-dialog {}
//.modal-content {
//    padding: 48px;
//}

//@-webkit-keyframes modal-animation {
//    from {
//        top: -100px;
//        opacity: 0;
//    }
//    to {
//        top: 0px;
//        opacity: 1;
//    }
//}

//@keyframes modal-animation {
//    from {
//        top: -100px;
//        opacity: 0;
//    }
//    to {
//        top: 0px;
//        opacity: 1;
//    }
//}

//.modal-container {}
//.modal-container .alm-btn-primary,
//.modal-container .alm-btn-primary:hover {
//    color: #2C2C2C !important;
//    font-size: 1.6rem !important;
//    min-width: 100px !important;
//    height: 39px;
//    background: #FFFFFF 0% 0% no-repeat padding-box !important;
//    border: 2px solid #2C2C2C !important;
//    border-radius: 50px !important;
//    opacity: 1;
//    display: inline-block;
//    vertical-align: middle !important;
//}

.success-icon {
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background-image: url('../../assets/images/modalSuccessIcon.svg');
  background-repeat: no-repeat;
  background-size: 68px 68px;
}
.success-text-message {
  text-align: center;
  // font: normal normal normal 18px/26px Adobe Clean;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
}
.container-section {
  .filter-section {
    .select-section {
      //font-size: 1.7rem;
      font-weight: 300;
      @media screen and (max-width: 767px) and (orientation: portrait) {
        width: 100%;
      }
      width: 200px;
      margin-right: 8px;
      .multi-select {
        .dropdown-heading {
          font-size: 1.5rem;
        }
        .dropdown-content {
          //font-size: 1.5rem;
          z-index: 9999;
          .options {
            &::-webkit-scrollbar {
              width: 0.5rem;
              height: 0.3rem;
              background-color: #d9d6d6;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #888888;
            }
            li {
              word-break:keep-all;
              label {
                padding: 0.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.btn-new{
  .btn-label-approve i,.btn-label-approve span{
    vertical-align: unset !important;
  }
  
  .btn-label-reject i, .btn-label-reject span{
    vertical-align: unset !important;
  }
}

// .btn-new .btn-label-approve i,
//   .btn-new .btn-label-reject i {
//     vertical-align: unset;
//   }