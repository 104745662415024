@import '../../assets/scss/variables';

.headerTeam {
  border: 1px solid #e8e8e8;
  padding:2.5rem 2rem;
  position: relative;
  .resetbtnFont{
  .btn{
  border-width: 0;
  }
  .btn-view {
    font-size: 1.5rem;
    color: #2799f6;
    font-weight: 600;
    &:active {
      border: none ;
    }
    &:first-child:active {
      border: none ;
    }
    &:hover{
      color: var(--bs-btn-active-color);
    }
  }}
  .input-group {
    border-bottom: 1px solid #e8e8e8;
    width: 20rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
    input {
      font-size: 1.5rem;
      padding: 10px 30px 5px 5px;
    }
  }
  .search-icon {
    padding: 0px;
    background-image: url('../../assets/images/search-bar.svg');
    background-repeat: no-repeat;
    background-position: 95%;
    border: none;
  }
  .css-13cymwt-control {
    .css-1u9des2-indicatorSeparator {
      width: 0px;
    }
  }
  .filterMargin {
    margin-bottom: 0.05rem;
    color: #6B6B6B;
  }
  
  .css-1nmdiq5-menu {
    .scroller::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.3rem;
      background-color: #d9d6d6;
    }
    // z-index: 9999;
    // max-height: 120px;
    // overflow-y: none;
    div {
      // word-break: break-all;
      width: 100%;
      // background-color: #fff;
      //color: #000;
      &::-webkit-scrollbar {
        width: 0.3rem;
        height: 0.3rem;
        background-color: #d9d6d6;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888888;
      }
    }
    // div:hover{
    //   font-weight: bold; 
    // }
  }
  
  .css-1dimb5e-singleValue,.css-1jqq78o-placeholder{
    font-weight: bold;
  }
}
.myteam-search {
  position: absolute;
  top: 4.5rem;
  @media screen and (max-width: 1023px) {
    position: relative;
    top: unset;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.headerTable {
  background-color: #f6f5ff;
  border: 1px solid #ececed;
}
.tableContainer {
  // margin: 1rem 4rem;
  position: relative;
  border: 1px solid #ececed;
  font-size: 1.7rem;

  @media screen and (max-width: 1024px) {
    // margin: 1rem 2rem;
  }
  .rol-nme {
    color: #2799f6;
  }
  .table {
    margin-bottom: 0;

    tr {
      border-width: 1px;
    }
    .columnHeaders {
      color: #6b6b6b;
      font-size: 1.7rem;
      text-align: center;
      padding: 1rem;
      white-space: nowrap;
      width: 12.5%;
      &:first-child {
        text-align: left;
        width: 15%;
      }
      &:last-child {
        width: 10%;
      }
      span {
        svg {
          position: absolute;
          top: 0.9rem;
          height: 11px;
          width: 14px;
        }
      }

      .sortIcon {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        top: -5px;
        flex-wrap: nowrap;
        // position: absolute;
        // top: 1.4rem;

        .cpb-1-up {
          height: auto;
        }
      }
    }

    .columnData {
      color: #2c2c2c;
      font-size: 1.5rem;
      // border: 1px solid #000;
      // border-left: 0;
      text-align: center;
      // vertical-align: middle;
      // white-space: nowrap;
      padding: 1rem;
      vertical-align: middle;
      &:first-child {
        text-align: left;
      }
    }
  }
}
.status-notStart {
  border: 0px solid #2c2c2c !important;
  background-color: #dddbdb !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 11rem !important;
}
.status-approvd {
  border: 0px solid #2c2c2c !important;
  background-color: #dbf2e4 !important;
  color: #009c3b !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 11rem !important;
}
.status-awaitng {
  border: 0px solid #2c2c2c !important;
  background-color: #daecff !important;
  color: #3273de !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 11rem !important;
}
//.view-skill-journey-btn.btn.primary-btn {
//  border: 1px solid #2c2c2c !important;
//  white-space: nowrap;
//  color: #2C2C2C;
//    font-weight: 600;
//    border-radius: 25px;
//    padding: 5px 20px;
//    font-size: 1.5rem;
//    text-transform: capitalize;
//}

.manager-my-team {
  display: none;
}
// .manager-my-team-filter-by-role {
//   display: none;
// }
.manager-my-team-filter-by-skill {
  display: none;
}
.filterBy,
.pgno,
.select-option {
  //  width: auto;
  margin-right: 15px;
}
.noData {
  // font-family: Adobe Clean, Regular;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;
}
