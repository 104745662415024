@import '../../assets/scss/variables';
.soienablementteam {
  border: 1px solid #e8e8e8;
  padding: 2rem;
  .head-text {
    font-size: 1.7rem;
    font-weight: 600;
  }
  .enablement-inner {
    border: 1px solid #e8e8e8;
    padding: 2rem;
    &.card_box {
      padding-right: 0;
      .reachout {
        padding: 0;
        .reachout-inner {
          border: 1px solid #cccccc;
          border-right: none;
          padding: 2rem 0.5rem;
          .dotted-border {
            border-bottom: 1.5px dashed #e8e8e8;
            margin-bottom: 20px;
            margin-top: 20px;
          }
          .mail {
            padding: 3rem 1rem;
          }
          .submit-file {
            padding: 3rem 1rem;
          }
        }
      }
    }
    .circle-block {
      img {
        padding: 9px 6px 9px 9px;
        background: #f5f5f5;
        border-radius: 50%;
        height: 45px;
        width: 45px;
      }
    }
  }

  .card {
    .card-img-top {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin: 0 auto;
    }
    border: none;
    .card-body {
      text-align: center;
      .card-title {
        font-size: 1.6rem;
        color: $primaryColorBlack;
        font-weight: 600;
      }
      .card-subtitle {
        font-size: 1.5rem;
        color: #6b6b6b;
      }
      .card-text {
        font-size: 1.4rem;
        color: #a7a7a7;
        margin-bottom: 0;
      }
    }
  }
}
