@import '../../assets/scss/variables';

.emailvptemplate {
  .centercard {
    height: 50vh;
    width: 100%;
  }
  .card {
    .card-body {
      padding: 2rem 2rem 0.5rem 2rem;
      &:nth-child(2) {
        margin-bottom: 2rem;
      }
      .textSubject {
        color: #6b6b6b;
        font-size: 1.4rem;
      }
      #subjectInput {
        font-size: 1.5rem;
        font-weight: bold;
        resize: none;
      }
      .scroller {
        max-height: 20rem;
        overflow-y: auto;
        scrollbar-width: thin;
      }
      .scroller::-webkit-scrollbar {
        width: 0.5rem;
        background-color: #d9d6d6;
      }

      .scroller::-webkit-scrollbar-thumb {
        background-color: #888888;
      }
      .error-color {
        color: red;
      }
    }
    .card-footer {
      background: #fafafa;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
      font-size: 1.25rem;
      img {
        position: relative;
        top: -2px;
      }
    }
  }
}
