@import '../../assets/scss/variables';
// body{
//   padding-bottom: 0 ;
// }

.signin-block {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.76) 10%, rgba(256, 256, 256, 0)),
    url(../../assets/images/login_bg.png) no-repeat fixed;
  background-size: cover;
  background-position: right;
  position: relative;
  @include respond(portView) {
    background-position: center top;
  }
  .login-block-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 50rem;
    left: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include respond(portView) {
      left: 50%;
      width: auto;
    }
    .login-box {
      border-radius: 5px;
      background: #fff;
      align-items: center;
      display: flex;
      padding: 3rem;
      flex-direction: column;
    }
  }

  .login-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    .president-text{
      color: $ColorDarkGray;
    }
  }

  .round-cta {
    border-radius: 20px;
    background: #eff6ff;
    color: $secondaryColor;
    border: 1px solid #cbcdfa;
    padding: 8px 26px;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .fill {
    @extend .round-cta;
    border-color: $buttonColor;
    background: $primaryColorRed;
    color: $primaryColorWhite;
    margin-left: 14px;
  }
}

.headding-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  font-weight: 700;
  color: $primaryColorRed;
  span {
    font-weight: 400;
  }
}
