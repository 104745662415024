@import '../../assets/scss/variables';

.pl-30 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

// .search-block.mob {
//     display: none;
// }

.profile-cta {
  padding: 10px 0;

  h3 {
    // color: #00A19D;
    font-size: 2.1rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 992px) {
      display: block;
      margin-bottom: 10px;
    }
  }

  .user-edit {
    @media screen and (max-width: 990px) {
      margin-top: 90px;
      align-items: baseline;
    }
  }

  .page-title {
    @media screen and (max-width: 992px) {
      margin-top: 0;
    }
  }

  .txt-align {
    text-align: end;

    @media screen and (max-width: 992px) {
      text-align: start;
    }
  }

  .round-cta {
    border-radius: 20px;
    // background: $white;
    color: $buttonColor;
    border: 1px solid $buttonColor;
    padding: 8px 26px;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .fill {
    border-color: $buttonColor;
    background: $buttonColor;
    // color: $white;
    margin-left: 14px;
  }
}

.profile-block {
  background-image: url(../../assets/images/user-profilebg.png);
  // background-size: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;

  &.edit-block {
    padding: 30px 20px;

    .edit-icon {
      position: relative;

      .edit {
        position: absolute;
        width: auto;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background: #000;
        padding: 7px;
        border: none;
      }
    }

    input[type='text'] {
      border: 1px solid #d2d2d2;
      background: none;
      margin-top: 8px;
      // color: $white;
      text-align: center;
      padding: 5px 20px;
      border-radius: 10px;
    }

    .user-name {
      width: 90%;

      #userName {
        font-size: 2.1rem;
      }

      #userPost {
        font-size: 1.5rem;
      }
    }

    .user-description {
      width: 90%;
      font-size: 1.7rem;

      textarea {
        background-color: transparent;
        // color: $white;
        padding: 10px;
        height: 165px;
        resize: none;
        border-radius: 10px;
      }
    }
  }

  opacity: 1;

  img {
    width: 130px;
    border: 4px solid #51b9dc;
    border-radius: 100%;
  }
}

.profile-content {
  // background-color: $white;
  padding: 25px;
  height: 100%;
  border-radius: 6px;

  h5,
  h6 {
    font-size: 1.5rem;
  }

  select {
    -moz-appearance: none !important;
    /* Firefox */
    -webkit-appearance: none !important;
    /* Safari and Chrome */
    appearance: none !important;
  }

  .form-select,
  .form-control {
    // width: 70%;
    margin: 15px 0;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .form-control {
    padding: 10px !important;
    font-weight: bold;
  }

  #timezone {
    padding: 0.375rem 0.75rem !important;
  }

  #password {
    border: none;
    width: 100%;
    padding: 0;
    font-size: 30px;
    color: #aaacad;
    pointer-events: none;
  }

  #editpassword {
    width: auto;
    color: #aaacad;
    letter-spacing: 0.5em;
  }

  h5 {
    font-weight: bold;
    padding-bottom: 20px;

    span {
      font-size: 2.5rem;
    }
  }

  .default-color {
    color: #aaacad;
  }

  .multiselect {
    div {
      margin: 15px 0;
    }
  }

  .form-check {
    margin: 15px 0;
  }
}

.user-name {
  font-size: 1.7rem;
  margin: 10px 0;

  span {
    font-size: 2.1rem;
  }
}

.multiselect-container {
  .searchWrapper {
    padding: 2px 5px;
    // width: 80%;

    &::after {
      content: url(../../assets/images/downarrow.svg);
      position: absolute;
      right: 7px;
    }
  }

  .optionListContainer {
    width: 80%;
  }

  &.multiSelectContainer {
    color: #000;
    background-color: transparent;

    li {
      color: #000;
      background-color: transparent;

      &:hover {
        color: #000;
        background-color: transparent;
      }
    }
  }
}

.profileSetting {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  margin: 1rem 2.3rem 0 2.3rem !important;

  .profile-left-section {
    color: white;
    background-color: #000;
    position: relative;
    @media screen and (max-width: 767px) {
      margin-top: 8rem;
    }

    .profile {
      width: 126px;
      height: 126px;
      position: absolute;
      left: 50%;
      margin: 0 auto;
      transform: translateX(-50%);
      top: -60px;

      .userImage {
        border: 5px solid #fff;
        overflow: hidden;
        width: 100%;
        border-radius: 100%;
        height: 100%;
        background-color: #ccc;
      }
    }

    .profile-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #ccc;
    }

    .camera-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        vertical-align: bottom;
        cursor: pointer;
      }
      // @media screen and (max-width:480px) {
      //     left: 14.5rem;
      // }

      // @media screen and (min-width:480px) and (max-width:767px) {
      //     left: 28rem;
      // }

      // @media screen and (min-width:767px) and (max-width:1024px) {
      //     left: 15rem;
      // }

      // @media screen and (min-width:1024px) and (max-width:1200px) {
      //     left: 28rem;
      // }
    }

    .nameText {
      background-color: #000;
      top: 50%;
      left: 0%;
      margin-top: 2.5rem;
      width: 100%;
      z-index: -1;
      padding: 5rem 0rem 2rem 0rem;

      p {
        font-size: 2.3rem;
        margin-top: 3rem;
      }

      .designation {
        font-size: 1.7rem;
		//padding: 0 1rem;

        @media screen and (max-width:480px) {
          font-size: 1.48rem;
		  word-break: break-all;
		  display: block;
		  padding: 0 1rem;
        }
      }
    }

    .right-bar {
      position: relative;
    }

    .right-bar::after {
      content: '';
      position: absolute;
      right: 0;
      top: 4px;
      width: 1.5px;
      height: 1.5rem;
      background-color: #dadada;
      @media screen and (max-width:480px) {
          width: 0;        
      }
    }
  }

  .profile-right-section {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      margin-top: 2rem;
    }

    .content-section {
      // border: 1px solid #CCCCCC99;
      // height: 15rem;
      .form-control-profile {
        resize: none;
        min-height: 16.5rem;
        font-size: 1.5rem;
        @media screen and (max-width:1088px) {
          min-height: 18.8rem;
          
        }
      }
    }

    .about-section {
      font-weight: bold;
      font-size: 1.7rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }
}
.custom-margin-profile {
  @media screen and (max-width: 767px) {
    margin-top: 1rem;
  }

  .btn-view {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .primary-btn {
    font-size: 1.4rem;
    font-weight: bold;
  }
}
