/*Theme Colors*/
.pl-30 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.profile-cta {
  padding: 10px 0;
}
.profile-cta h3 {
  font-size: 2.1rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .profile-cta h3 {
    display: block;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 990px) {
  .profile-cta .user-edit {
    margin-top: 90px;
    align-items: baseline;
  }
}
@media screen and (max-width: 992px) {
  .profile-cta .page-title {
    margin-top: 0;
  }
}
.profile-cta .txt-align {
  text-align: end;
}
@media screen and (max-width: 992px) {
  .profile-cta .txt-align {
    text-align: start;
  }
}
.profile-cta .round-cta {
  border-radius: 20px;
  color: var(--buttonColor);
  border: 1px solid var(--buttonColor);
  padding: 8px 26px;
  font-size: 1.6rem;
  font-weight: 600;
}
.profile-cta .fill {
  border-color: var(--buttonColor);
  background: var(--buttonColor);
  margin-left: 14px;
}

.profile-block {
  background-image: url(../../assets/images/user-profilebg.png);
  display: flex;
  justify-content: center;
  padding: 30px;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}
.profile-block.edit-block {
  padding: 30px 20px;
}
.profile-block.edit-block .edit-icon {
  position: relative;
}
.profile-block.edit-block .edit-icon .edit {
  position: absolute;
  width: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #000;
  padding: 7px;
  border: none;
}
.profile-block.edit-block input[type=text] {
  border: 1px solid #d2d2d2;
  background: none;
  margin-top: 8px;
  text-align: center;
  padding: 5px 20px;
  border-radius: 10px;
}
.profile-block.edit-block .user-name {
  width: 90%;
}
.profile-block.edit-block .user-name #userName {
  font-size: 2.1rem;
}
.profile-block.edit-block .user-name #userPost {
  font-size: 1.5rem;
}
.profile-block.edit-block .user-description {
  width: 90%;
  font-size: 1.7rem;
}
.profile-block.edit-block .user-description textarea {
  background-color: transparent;
  padding: 10px;
  height: 165px;
  resize: none;
  border-radius: 10px;
}
.profile-block img {
  width: 130px;
  border: 4px solid #51b9dc;
  border-radius: 100%;
}

.profile-content {
  padding: 25px;
  height: 100%;
  border-radius: 6px;
}
.profile-content h5,
.profile-content h6 {
  font-size: 1.5rem;
}
.profile-content select {
  -moz-appearance: none !important;
  /* Firefox */
  -webkit-appearance: none !important;
  /* Safari and Chrome */
  appearance: none !important;
}
.profile-content .form-select,
.profile-content .form-control {
  margin: 15px 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.profile-content .form-control {
  padding: 10px !important;
  font-weight: bold;
}
.profile-content #timezone {
  padding: 0.375rem 0.75rem !important;
}
.profile-content #password {
  border: none;
  width: 100%;
  padding: 0;
  font-size: 30px;
  color: #aaacad;
  pointer-events: none;
}
.profile-content #editpassword {
  width: auto;
  color: #aaacad;
  letter-spacing: 0.5em;
}
.profile-content h5 {
  font-weight: bold;
  padding-bottom: 20px;
}
.profile-content h5 span {
  font-size: 2.5rem;
}
.profile-content .default-color {
  color: #aaacad;
}
.profile-content .multiselect div {
  margin: 15px 0;
}
.profile-content .form-check {
  margin: 15px 0;
}

.user-name {
  font-size: 1.7rem;
  margin: 10px 0;
}
.user-name span {
  font-size: 2.1rem;
}

.multiselect-container .searchWrapper {
  padding: 2px 5px;
}
.multiselect-container .searchWrapper::after {
  content: url(../../assets/images/downarrow.svg);
  position: absolute;
  right: 7px;
}
.multiselect-container .optionListContainer {
  width: 80%;
}
.multiselect-container.multiSelectContainer {
  color: #000;
  background-color: transparent;
}
.multiselect-container.multiSelectContainer li {
  color: #000;
  background-color: transparent;
}
.multiselect-container.multiSelectContainer li:hover {
  color: #000;
  background-color: transparent;
}

.profileSetting {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  opacity: 1;
  margin: 1rem 2.3rem 0 2.3rem !important;
}
.profileSetting .profile-left-section {
  color: white;
  background-color: #000;
  position: relative;
}
@media screen and (max-width: 767px) {
  .profileSetting .profile-left-section {
    margin-top: 8rem;
  }
}
.profileSetting .profile-left-section .profile {
  width: 126px;
  height: 126px;
  position: absolute;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
  top: -60px;
}
.profileSetting .profile-left-section .profile .userImage {
  border: 5px solid #fff;
  overflow: hidden;
  width: 100%;
  border-radius: 100%;
  height: 100%;
  background-color: #ccc;
}
.profileSetting .profile-left-section .profile-icon {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #ccc;
}
.profileSetting .profile-left-section .camera-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profileSetting .profile-left-section .camera-icon img {
  vertical-align: bottom;
  cursor: pointer;
}
.profileSetting .profile-left-section .nameText {
  background-color: #000;
  top: 50%;
  left: 0%;
  margin-top: 2.5rem;
  width: 100%;
  z-index: -1;
  padding: 5rem 0rem 2rem 0rem;
}
.profileSetting .profile-left-section .nameText p {
  font-size: 2.3rem;
  margin-top: 3rem;
}
.profileSetting .profile-left-section .nameText .designation {
  font-size: 1.7rem;
}
@media screen and (max-width: 480px) {
  .profileSetting .profile-left-section .nameText .designation {
    font-size: 1.48rem;
    word-break: break-all;
    display: block;
    padding: 0 1rem;
  }
}
.profileSetting .profile-left-section .right-bar {
  position: relative;
}
.profileSetting .profile-left-section .right-bar::after {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  width: 1.5px;
  height: 1.5rem;
  background-color: #dadada;
}
@media screen and (max-width: 480px) {
  .profileSetting .profile-left-section .right-bar::after {
    width: 0;
  }
}
.profileSetting .profile-right-section {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .profileSetting .profile-right-section {
    flex-direction: column-reverse;
    margin-top: 2rem;
  }
}
.profileSetting .profile-right-section .content-section .form-control-profile {
  resize: none;
  min-height: 16.5rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 1088px) {
  .profileSetting .profile-right-section .content-section .form-control-profile {
    min-height: 18.8rem;
  }
}
.profileSetting .profile-right-section .about-section {
  font-weight: bold;
  font-size: 1.7rem;
}
.profileSetting .profile-right-section p {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 767px) {
  .custom-margin-profile {
    margin-top: 1rem;
  }
}
.custom-margin-profile .btn-view {
  font-size: 1.6rem;
  font-weight: bold;
}
.custom-margin-profile .primary-btn {
  font-size: 1.4rem;
  font-weight: bold;
}/*# sourceMappingURL=profile.css.map */