.breadCrumb{
    position: sticky;
    top: 119px;
    z-index: 999;
    background: #fff;
    padding-bottom: 8px;
}  
@media screen and (max-width: 991px) {
    .breadCrumb{
        top: 69px;
    } 
}    




/* .disable-component {
    display: none;
} */
.custom-accordion-container-tbl tr {
    border-bottom: 1px solid #EEEEEE;
}
.custom-accordion-container-tbl tr:last-of-type {
    border-bottom: 0px solid #EEEEEE;
}
.container-tbl-tr-bg {
    background-color: #FAFAFA;
}
.custom-accordion-item-content .custom-accordion-tbl-cus {
    font-size: 1.4rem;
    color: #6B6B6B;
    border-bottom: 0px solid transparent;
}
.custom-accordion-item-content .custom-accordion-tbl-cus tr td{
    
        vertical-align: top !important;
    
}
/* .custom-accordion-item-content .custom-accordion-tbl-cus tr td:nth-child(1) {
    text-align: left;
} */
.custom-accordion-item-content .custom-accordion-tbl-cus tr td {
    font-size: 1.6rem;
    font-weight: 900;
    vertical-align: top;
}

.main-approval-preview-container-section .accordion-body {
    overflow-x: auto;
}


 

/* react-calendar */
.react-calendar .react-calendar__viewContainer button {
    height: 34px;
    line-height: 27px;
}
.calendar-block .react-calendar__tile--active {
    background-color: transparent;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: transparent;
}

.react-calendar .react-calendar__viewContainer button.react-calendar__tile abbr:hover,
.react-calendar .react-calendar__viewContainer button.react-calendar__tile abbr:focus {
    background:#D1E2FF;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    line-height: 27px;
    display: inline-block;
}

.react-calendar .react-calendar__viewContainer button.react-calendar__tile--active abbr{
    color: #fff;
    background: #3273DE;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    line-height: 27px;
    display: inline-block;
    padding: 0px;
    padding-right: 1px;
    padding-left: 1px;
    padding-top: 1px;
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile--now abbr{
    color: #fff;
    background: #3273DE;
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile--now abbr:hover,
.react-calendar .react-calendar__viewContainer button.react-calendar__tile--now abbr:focus
{
    color: #000;
    background: #D1E2FF
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day abbr{
    color: #000;
    background: #D1E2FF
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile--now.react-calendar__tile.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day abbr{
    color: #fff;
    background: #3273DE;
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.highlight-date abbr{
    color: #fff;
    background: #3273DE;
}
.react-calendar .react-calendar__viewContainer button.highlight-date abbr{    
    background: #D2D2D2;
    color: #7d7d7d;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    line-height: 27px;
    display: inline-block;
    padding: 0px;
    padding-right: 1px;
    padding-left: 1px;
    padding-top: 1px;
}
.react-calendar .react-calendar__viewContainer button abbr {
    color: #7d7d7d;
    padding-right: 1px;
    padding-left: 1px;
    padding-top: 1px;
    line-height: 28px;
  }
.calendar-block .react-calendar__tile--active abbr {    
    background: #3273de;
}
.calendar-block .highlight-date abbr {    
    background-color:#D2D2D2;
  color: white; 
}

.react-calendar__tile--now {
    background: transparent;
}

.react-calendar__tile--now abbr {
    background: #96BDFA;
    color:#fff;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    line-height: 27px;
    display: inline-block;
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__year-view__months__month abbr{
    width: 100%;
    border-radius: 0;
}
.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__decade-view__years__year:hover,
 .react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__century-view__decades__decade:hover {
    background-color: #D1E2FF;
  }

.card-content-section {
    min-height: 100px;
}
.card-block .card-title {
    height: 4rem;
    /* height: auto!important; */
}
.custom-accordion-container-tbl .custom-thead {
    border-bottom: 2px solid #ECECED!important;
}

.form-check .form-check-input {
    margin-left: -1.3em;
}

.font14size{
    font-size: 1.5rem;
  }

.btnCLICK{
    font-size: 1.5rem;
    color: #2799F6 ;
    text-decoration: none;
}
    .btnCLICK:hover{
        color: #2799F6 ;
    }
    .btnCLICK:active{
        color: #2799F6 ;
    }
    .btnCLICK:focus{
        color: #2799F6 ;
        box-shadow: none;
        outline: none;
    
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.2em;
}
.submittedRatings .btn {
    cursor: default;
}
.accordion-button{
    font-size: 1.6rem;
    font-weight: bold;
}
.fade.show.popover.bs-popover-end{
    z-index: 10;
}
.horizontal-tab-block.accord .accordion-collapse ul{    
        position: static;    
}
.fontSize15Text{
    font-size: 15px !important;
    margin-bottom: 0.7rem ;
}
.fontSize14Text{
    font-size: 15px !important;
}