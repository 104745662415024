/*Theme Colors*/
.Carousel-block.recommendation .time-recent .clock-img {
  margin-top: 0;
  --bs-gutter-x: 0;
}
.Carousel-block.recommendation .carousel-container {
  padding-left: 5px !important;
}
.Carousel-block.recommendation .carousel-container .react-multi-carousel-list.carousel-container {
  margin-left: 2px;
}
.Carousel-block.recommendation .card-block .card-content .time-recent {
  display: flex;
}
.Carousel-block.recommendation .card-block .discription {
  height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
}
@supports (-webkit-line-clamp: 2) {
  .Carousel-block.recommendation .card-block .discription {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.Carousel-block.recommendation .react-multiple-carousel__arrow--right {
  bottom: 25px;
  right: 50%;
  margin-right: -240px;
}
@media screen and (max-width: 992px) {
  .Carousel-block.recommendation .react-multiple-carousel__arrow--right {
    margin-right: -40px;
  }
}
.Carousel-block.recommendation .react-multiple-carousel__arrow--left {
  bottom: 25px;
  left: 50%;
  margin-left: -240px;
}
@media screen and (max-width: 992px) {
  .Carousel-block.recommendation .react-multiple-carousel__arrow--left {
    margin-left: -40px;
  }
}
.Carousel-block.recommendation .prev-button {
  left: -30px;
}
.Carousel-block.recommendation .next-button {
  right: -25px;
}/*# sourceMappingURL=Recommendation.css.map */