@import '../../assets/scss/variables';

.manageTemplates {
  margin-top: 3rem;

  .horizontal-tab-block {
    border: 1px solid #e8e8e8;
    padding: 0 25px 25px 25px;
    border-radius: 5px;

    .nav.nav-tabs::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    .nav.nav-tabs {
      width: 100%;
      overflow-x: scroll;
      flex-wrap: nowrap;
      overflow-y: clip;
      margin-top: 1rem;

      @media screen and (min-width: 576px) {
        // overflow-x:hidden;
        overflow: hidden;
      }

      .nav-item {
        margin-right: 7.5rem !important;

        @media screen and (max-width: 576px) {
          margin-right: 2rem;
        }

        .nav-link {
          // color: #000000 !important;
          // font-size: 1.7rem;
          font-size: 1.7rem;
          width: max-content;
          border: none;
          padding: 0px 0 5px;

          &.active {
            //  font-weight: 900;
            color: #000000;
            text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
            position: relative;
            border-bottom: 4px solid #3273de;

            &::after {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              height: 4.5px;
              background: #3273de;
              bottom: -4px;
              left: 0;
              z-index: 1;
              border-radius: 10px;
            }
          }

          &:hover {
            //  font-weight: 900;
            text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;

            border-bottom: 4px solid #3273de;
          }
        }
      }
    }
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active:hover,
  .nav-tabs .nav-link:hover {
    border-color: #3273de !important;
  }

  .tab-content {
    .accordion {
      border: 1px solid #ececed;
      margin-top: 20px;

      .accordion-item {
        border: none;
        margin: 0;

        &:not(:last-child) {
          .accordion-header {
            .accordion-button {
              &:not(.collapsed) {
                &::after {
                  display: none;
                }
              }

              &::after {
                display: none;
              }
            }
          }
        }

        .accordion-header {
          .accordion-button {
            border: none;
            font-size: 1.6rem;
            padding: 15px;
            padding-bottom: 20px;
            position: relative;

            &[aria-expanded="true"] {
              background-color: #f6f5ff;
              border-left: 3px solid #3273de !important;
              font-weight: bold;
            }

            &:hover {
              background-color: #f6f5ff;
              border-left: 3px solid #3273de;
            }

            &:not(.collapsed) {
              background-color: #ececed;
              border-left: 2px solid #3273de;

              &::after {
                right: 20px;
                border: none;
              }
            }

            &::after {
              right: 20px;
              border: none;
            }

            .sub-text {
              color: #6b6b6b;
              font-size: 1.4rem;
              position: absolute;
              top: 25px;
              padding-top: 7px;
            }
          }
        }
      }

      &::after {
        right: 20px;
        border: none;
      }

      .sub-text {
        color: #6b6b6b;
        font-size: 1.4rem;
        position: absolute;
        top: 25px;
        padding-top: 7px;
      }
    }
  }

  .list-view {
    .list-view-block {
      border: 1px solid #e8e8e8;
      //margin-top: 20px;
      width: 100%;

      //margin-left: 2rem;
      //&.cd {
      //    margin-left: 2rem;
      //  }
      .list-view-block-img {
        border-radius: 5px;
        width: 98px;
        height: 68px;
      }
    }

    .head-text {
      font-size: 1.7rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .sub-text {
      font-size: 1.4rem;
    }

    .list-footer {
      background-color: #fafafa;
      display: flex;
      padding: 3px 15px;
      color: #707070;

      // flex-wrap: wrap;
      img {
        vertical-align: unset;
      }

      .progress {
        width: 30%;
        background-color: #d9d9d9;
        margin-top: 1px;
        height: 5px !important;
      }

      .primary-btn {
        margin-left: auto;
        // background-color: #fff;
      }
    }
  }

  .accordion-collapse {
    .list-view {
      &.cd {
        margin-left: 2rem;
      }
    }
  }

  .head-text {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .sub-text {
    font-size: 1.4rem;
  }

  .list-footer {
    background-color: #ececed;
    display: flex;
    padding: 3px 15px;
    color: #707070;

    // flex-wrap: wrap;
    img {
      vertical-align: unset;
    }

    .progress {
      width: 30%;
      background-color: #d9d9d9;
      margin-top: 1px;
      height: 5px !important;
    }

    .primary-btn {
      margin-left: auto;
    }
  }

  // .cardStyle{
  //   width: 25rem;
  //   margin: 0 auto;
  // }
  .card1 {
    .card {
      width: 50rem;
      min-height: 18rem;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        width: 25rem;
      }

      .card-img-top {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }

    // border: none;
    .card-body {
      text-align: center;
      min-height: 160px;

      // min-height: 100px;
      .card-title {
        font-size: 1.6rem;
        color: $primaryColorBlack;
        font-weight: 600;
      }

      .card-subtitle {
        font-size: 1.5rem;
        color: #6b6b6b;
      }

      .card-text {
        font-size: 1.3rem;
        color: #a7a7a7;
      }
    }

    .card-footer {
      @media screen and (max-width: 767px) {
        padding: 5px;
      }
    }
  }

  .footerButton {
    @media screen and (max-width: 767px) {
      margin: 10px;
    }
  }

  .fileValidation {
    color: #eb1000;
    margin-top: 10px;
    font-weight: 500;
    text-align: center;
  }

  .alm-file-upload-conatainer-content {
    // display: flex;
    // margin: 30px auto;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    // position: relative;
  }

  .alm-file-upload-content {
    width: 300px;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      text-align: center;
      width: 210px;

    }
  }

  .alm-file-upload-content .alm-file-upload-icon {
    display: inline-block;
    vertical-align: middle;
    width: 3.1rem;
    height: 3.1rem;
    margin-right: 1rem;
  }

  .alm-file-upload-content .alm-file-upload-input {
    display: inline-block;
    vertical-align: middle;
    width: 28%;
    font-size: 1.5rem;
  }

  .alm-file-upload-content .alm-file-upload-label {
    color: #707070;
    font-size: 1.5rem;
    vertical-align: middle;
    width: 30%;
    padding-left: 20px;
  }

  .form-label {
    margin-bottom: 0;
  }

  .alm-file-upload-uploaded-files {
    img {
      vertical-align: text-bottom;
    }
  }

  .alm-file-upload-uploaded-files {
    //position: absolute;
    //left: 27%;
    //bottom: -50px;
    margin-top: 1rem;
  }

  .alm-file-upload-uploaded-files .navBackgroundColor {
    margin: 0 5px;
    display: block;

    @media screen and (max-width: 767px) {
      width: 210px;
    }
  }

  .form-label {
    margin-bottom: 0;
  }

  .btnColorBg {
    background-color: #2799f6;
    border: 1px solid transparent;
    color: #fafafa;
  }

  .btnColorBg:disabled {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);

  }

  .statusText {
    font-size: 1.5rem;
    margin-top: 10px;
  }

  .updateTemplateCard {
    margin: auto;
  }

  .infoSection {
    display: flex;
    flex-direction: row;
    justify-content: center;

  }


}

// writing css of existing template here due to scss extension issue

/* Base button styles */
.btnTable {
  border: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: hidden;
  display: inline-block;
}

.btnTable:hover .iconChanges {
  opacity: 1; 
  transform: scale(1.2); 
}

.btnTable:hover {
  transform: translateY(-2px); 
  opacity: 1.5; 
}

.btnTable:active {
  transform: translateY(1px); 
  opacity: 0.8; 
}


.right-barSelect {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 4px;
    width: 1.5px;
    height: 1.2rem;
    background-color: #dadada;
    margin-left: 4px;
  }

}

.right-barNewTemplate {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 4px;
    width: 1.5px;
    height: 1.2rem;
    background-color: #dadada;
    margin-left: 6px;
  }

}

.timeStamp {
  img {
    vertical-align: revert;
    position: relative;
    top: 2px;
  }
}

.existingTemplates {
  .learnerReports {
    .learnerReportsTable {
      // .tableHeading,.tableRows{
      //   th{
      //     width: 0;
      //   }
      // }
      border: 1px solid #e8e8e8;
    }
  }

  .statusUI {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
  }

  .infoSectionExisting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .timeStamp {
    img {
      vertical-align: revert;
    }
  }
}

.failedMessage {
  color: #eb1000;
}

.successMessage {
  color: #009C3B;
}

.iconChanges {
  height: 17px;
  // opacity: 0.7; 
  transition: opacity 0.3s ease, transform 0.3s ease; 
}
.buttonUi{
  cursor: auto !important;;
}
.btnTemplate {
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer; /* Default cursor */

  &.no-underline {
    text-decoration: none; /* Remove underline */
  }
  &.no-pointer {
    cursor: default; /* Prevent pointer cursor when count is 0 */
  }

}

.tableHeadings{
  text-align: center;
}