@import "./Fonts-Adobe-Clean/AdobeClean.css";
html {
  /* we need 1rem = 10px  */
  font-size: 62.5%;
  position: relative;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: Adobe Clean,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    background-color: #ffffff;
    padding-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-toast-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}