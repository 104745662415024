@import '../../assets/scss/variables';
.Carousel-block.wallOfFame {
  border: 1px solid hsla(0, 0%, 80%, 0.6);
  padding: 2.5rem 3rem !important;
  border-radius: 5px;
  .card {
    .card-img-top {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
    border: none;
    .card-body {
      text-align: center;
      .card-title {
        font-size: 1.6rem;
        color: $primaryColorBlack;
        font-weight: 600;
      }
      .card-subtitle {
        font-size: 1.5rem;
        color: #6b6b6b;
      }
      .card-text {
        font-size: 1.3rem;
        color: #a7a7a7;
      }
    }
  }
  //.react-multiple-carousel__arrow--left {
  //    bottom: 25px;
  //    left: 43%;
  //    @include respond(lg-deviec){
  //        left: 40%;
  //     }
  //     @include respond(sm-deviec){
  //        left: 43%;
  //		bottom: 0;
  //     }
  //}
  //.react-multiple-carousel__arrow--right {
  //    bottom: 25px;
  //    right: 43%;
  //    @include respond(lg-deviec){
  //        right: 40%;
  //     }
  //     @include respond(sm-deviec){
  //        right: 43%;
  //		bottom: 0;
  //     }
  //}
  .react-multiple-carousel__arrow--right {
    bottom: 25px;
    right: 50%;
    margin-right: -240px;
    @media screen and (max-width: 992px) {
      margin-right: -40px;
    }
  }
  .react-multiple-carousel__arrow--left {
    bottom: 25px;
    left: 50%;
    margin-left: -240px;
   
    @media screen and (max-width: 992px) {
      margin-left: -40px;
    }
   
  }
  &.hidecontrols{
  .react-multi-carousel-list{
    padding-bottom: 0;
   }
}
 .prev-button {
  left: -10px;
}
.next-button {
    right: -10px;
  }
.view-all{
  margin-right: -15px;
}
}
