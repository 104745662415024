@import "../../assets/scss/variables";

.courseReports{
//  border: 1px solid #E8E8E8;
//  padding: 2rem;
  .css-13cymwt-control{
  .css-1u9des2-indicatorSeparator{
    width: 0px;
  }}
}

.courseReportsTable {
  // margin: 1rem 4rem;
  position: relative;
  border: 1px solid #ECECED;
  font-size: 1.7rem;

  // @media screen and (max-width: 1024px) {
  //   margin: 1rem 2rem;
  // }
  .rol-nme{
    color: #2799F6;
  }
  .table {
    margin-bottom: 0;
    border: 1px solid #ECECED;
    .headerRows{
      border-bottom: 2px solid #ECECED;
    }

    .columnHeaders, .columnHeadersDetails{
      color: #6B6B6B;
      padding: 1rem;
      font-size: 1.7rem;
      text-align: center;
	  white-space: nowrap;
    // width: 15%;
    &:first-child {
      text-align: left;
      width: 18%;
    }
    // &:nth-child(2) {
    //   width: 15%;
    // }
    // &:last-child {
    //   width: 15%;
    // }
      

  }
  
  .columnHeadersDetails{
    text-align: center;
    width: 15%;
    &:first-child{
      text-align: left;
      width: 25%;
    }
  }
  

    .columnData, .columnDataDetails {
      color:  #2C2C2C;
      font-size: 1.5rem;
      border-left: 0;
      text-align: center;
      padding: 1rem;

      &:first-child {
        text-align: left;
        // margin-bottom: 0;
        // color: #000;
        // font-family: berninoFontBold, sans-serif;
        // line-height: 20px;
      }
      
      .table-cell-button {
        background: transparent;
        border: 0px solid transparent;
        text-decoration: underline;
        text-underline-offset: 3px;
      }

      .couponCode {
        color: #000;
        font-family: berninoFont, sans-serif;
        font-size: 100%;
        margin-bottom: 0;
        line-height: 20px;
        padding: 0;
      }

      .scheduledStatus {
        color: #000;
        cursor: none;
      }

      .expiredStatus {
        color: #f44336;
      }

      .activeStatus {
        color: #00b517;
      }
      

      .cert {
        display: flex;
        justify-content: space-around;
        align-items: center;

        // flex-direction: row;
        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .certDesc {
          color: #000;
          font-family: berninoFontBold, sans-serif;
          font-size: 110%;
          white-space: normal;
          text-align: left;
          margin-bottom: 0;
          line-height: 22px;

          @media screen and (max-width: 1024px) {
            text-align: center;
            font-size: 90%;
            line-height: 18px;
            margin-top: 0.5rem;
          }
        }
      }
    }
    .columnDataDetails{
      text-align: center;
      width: 14%;
      &:first-child{
        text-align: left;
        width: 18%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      // &:last-child {
      //   width: 10%;
      // }
      
    }
  }
}
.sortIcons  {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  flex-wrap: nowrap;
  
  .cpb-1-up {
   height: auto;
  //  rotate: 180deg;
  }
}
.pagnation{
  text-align: center;
  flex-grow: 1;
}

