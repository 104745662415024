@import '../../assets/scss/variables';

.ImportCsv {
  .importCsvrow {
    h3 {
      color: white;
      font-size: 2.8rem;
    }
  }
  .card {
    font-weight: bold;
    border: 0.1rem solid #e8e8e8;
    border-radius: 0.5rem;
    height: 100%;
    .card-body {
      font-weight: bold;
      .card-title {
        font-size: 1.6rem;
        font-weight: bold;
        .dropdownItem {
          background-color: #ffffff;
          color: #000000;
          border: 0.1rem solid #ededed;
          border-radius: 0.5rem;
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }
  .card-footer {
    background: #fafafa;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    img {
      vertical-align: revert;
    }
  }

  .text-browse {
    color: #2799f6;
  }
  .text-Select,
  .otherText {
    font-size: 1.5rem;
  }
  .text-Select {
    color: #707070;
  }
  .statusText {
    color: #6b6b6b;
  }

  .dropDownIcon {
    background: #f5f5f5;
    padding: 0.8rem 1rem 0rem 1rem;
    height: 3.1rem;
    width: 3.1rem;
    border-radius: 50%;
    img {
      vertical-align: revert;
    }
  }
  .navBackgroundColor {
    background-color: #f6f5ff;
    line-height: 28px;
    padding: 3px 10px;
    img {
      vertical-align: revert;
    }
  }

  .right-bar {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 100%;
      bottom: 2px;
      width: 1.5px;
      height: 1.2rem;
      background-color: #dadada;
    }
  }
  .alm-file-upload-conatainer-content {
    display: block;
    margin: 30px auto;
    position: relative;
  }
  .alm-file-upload-content {
    width: 300px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  .alm-file-upload-content .alm-file-upload-icon {
    display: inline-block;
    vertical-align: middle;
    width: 3.1rem;
    height: 3.1rem;
    margin-right: 1rem;
  }
  .alm-file-upload-content .alm-file-upload-input {
    display: inline-block;
    vertical-align: middle;
    width: 28%;
    font-size: 1.5rem;
  }
  .alm-file-upload-content .alm-file-upload-label {
    color: #707070;
    font-size: 1.5rem;
    vertical-align: middle;
    width: 30%;
    padding-left: 20px;
  }
  .form-label {
    margin-bottom: 0 ;
  }
  .alm-file-upload-uploaded-files {
    //position: absolute;
    //left: 27%;
    //bottom: -50px;
    margin-top: 1rem;
  }
  .alm-file-upload-uploaded-files .navBackgroundColor {
    margin: 0 5px;
    display: block;
  }
  .form-label {
    margin-bottom: 0 ;
  }
}
/* Styling modal */
//.modal-container {
//  width: 100%;
//  text-align: center;
//}
//.modal:before {
//  content: '';
//  display: inline-block;
//  height: 100%;
//  vertical-align: middle;
//}

//.modal-dialog {
//  display: inline-block;
//  vertical-align: middle;
//  margin: 0 auto !important;
//  width: 100% !important;
//  max-width: 100% !important;
//}
//.modal {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}
//.modal:before {
//  content: '';
//  display: inline-block;
//  height: 100%;
//  vertical-align: middle;
//}
//.modal .modal-content {
//  padding: 0px;
//  background: #ffffff 0% 0% no-repeat padding-box;
//  border: 1px solid #707070;
//  border-radius: 13px;
//  opacity: 1;
//  width: 500px;
//  margin: 0 auto;
//}
//.modal .modal-body {
//  padding: 48px 48px 0 48px !important;
//}
//.modal .modal-footer {
//  width: 100%;
//  justify-content: center;
//  padding: 20px 0;
//}
//.modal-dialog {
//}
//.modal-content {
//  padding: 48px;
//}

@-webkit-keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}

@keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}


.modal-container .alm-btn-primary,
.modal-container .alm-btn-primary:hover {
  color: #2c2c2c !important;
  font-size: 1.6rem !important;
  min-width: 100px !important;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #2c2c2c !important;
  border-radius: 50px !important;
  opacity: 1;
  display: inline-block;
  vertical-align: middle !important;
}

.success-icon {
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background-image: url('../../assets/images/modalSuccessIcon.svg');
  background-repeat: no-repeat;
  background-size: 68px 68px;
}
.success-text-message {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
}
.success-text-message2 {
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  padding: 36px 0 20px 0;
  width: 100%;
}
.show-loader {
  position: absolute;
  // background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.show-loader .position-relative {
  top: 50%;
  //  left: 0 !important;
}
.show-display-box {
  font-size: 2.1rem;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  padding: 12% 20% 0 20%;
  text-align: center;
}
.show-display-box .display-box-message {
  background-color: white;
  color: green;
  padding: 10px;
}
.show-display-box .display-box-message-fail {
  background-color: white;
  color: red;
  padding: 10px;
}

.button-tooltip{
    background: transparent;
    border: none;
    padding: 0;
    img{
      vertical-align: unset !important;
      position: relative;
    top: 2px;
    }
}
.infoHeader {
  font-size: 1.9rem !important;
  color: #222222;
  opacity: 1;
 font-weight: 600 !important; /* Left-align the text inside the modal body */
}
.infoPopup-body {
  max-height: 500px; /* Adjust the max height as needed */
  overflow-y: auto;
}

/* Adjust the padding to your liking */
.infoPopup-body .success-text-message2 {
  padding-right: 16px; /* Add padding to the right to make space for the scrollbar */
}
.infoPopup-body {
     font-size: 1.9rem;
     color: #222222;
     opacity: 1;
     width: 100%; /* Left-align the text inside the modal body */
}
//@-webkit-keyframes modal-animation {
//  from {
//    top: -100px;
//    opacity: 0;
//  }
//  to {
//    top: 0px;
//    opacity: 1;
//  }
//}

//@keyframes modal-animation {
//  from {
//    top: -100px;
//    opacity: 0;
//  }
//  to {
//    top: 0px;
//    opacity: 1;
//  }
//}

//.modal-container {
//}
//.modal-container .alm-btn-primary,
//.modal-container .alm-btn-primary:hover {
//  color: #2c2c2c !important;
//  font-size: 1.6rem !important;
//  min-width: 100px !important;
//  height: 39px;
//  background: #ffffff 0% 0% no-repeat padding-box !important;
//  border: 2px solid #2c2c2c !important;
//  border-radius: 50px !important;
//  opacity: 1;
//  display: inline-block;
//  vertical-align: middle !important;
//}
//.success-modal-container {
//}
//.success-icon {
//  width: 68px;
//  height: 68px;
//  margin: 0 auto;
//  background-image: url('../../assets/images/modalSuccessIcon.svg');
//  background-repeat: no-repeat;
//  background-size: 68px 68px;
//}
//.success-text-message {
//  text-align: center;
//  font: normal normal normal 18px/26px Adobe Clean;
//  letter-spacing: 0px;
//  color: #222222;
//  opacity: 1;
//  padding: 36px 0 20px 0;
//}
//.show-loader {
//  position: absolute;
//  background-color: rgba(0, 0, 0, 0.1);
//  width: 100%;
//  height: 100%;
//}
//.show-loader .position-relative {
//  top: 70% !important;
//  left: 0 !important;
//}
//.show-display-box {
//  font-size: 2.1rem;
//  position: absolute;
//  background-color: rgba(0, 0, 0, 0.3);
//  width: 100%;
//  height: 100%;
//  padding: 12% 20% 0 20%;
//  text-align: center;
//}
//.show-display-box .display-box-message {
//  background-color: white;
//  color: green;
//  padding: 10px;
//}
