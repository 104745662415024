/*Theme Colors*/
.emailtemplate .quill .ql-snow a {
  color: #2799F6 !important;
}
.emailtemplate .quill .ql-snow .ql-editing {
  left: 39% !important;
  border: 2px solid #ccc !important;
  box-shadow: 7px 7px 8px #ddd !important;
}
@media screen and (max-width: 767px) {
  .emailtemplate .quill .ql-snow .ql-editing {
    left: 2% !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1028px) {
  .emailtemplate .quill .ql-snow .ql-editing {
    left: 22% !important;
  }
}
.emailtemplate .message-box .info-text {
  position: relative;
}
.emailtemplate .message-box .info-text img {
  position: relative;
  top: -3px;
}
.emailtemplate .message-box .info-text .link-color {
  color: rgb(39, 153, 246);
  cursor: pointer;
}
.emailtemplate .message-box .info-text button {
  background: transparent;
  border: none;
  padding: 0;
}
.emailtemplate .message-box .info-text .info-show-box {
  position: absolute;
  background-color: #fff;
  right: 106%;
  padding: 10px 20px;
  min-width: 575px;
  top: -10px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
  border: 1px solid rgb(167, 167, 167);
  z-index: 2;
}
@media (max-width: 991px) {
  .emailtemplate .message-box .info-text .info-show-box {
    min-width: 300px;
  }
}
.emailtemplate .message-box .info-text .info-show-box P {
  margin-bottom: 5px !important;
  font-size: 1.4rem;
}
.emailtemplate .message-box .info-text .info-show-box::after {
  content: "";
  background-image: url(../../assets/images/rightArrow.svg);
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 35px;
  width: 35px;
  right: -21px;
  top: 0;
}
.emailtemplate .message-box .info-text .info-show-box .variable-list {
  border-bottom: 1px solid #eee;
  display: flex;
  flex-flow: nowrap;
  padding-bottom: 3px;
  margin-bottom: 3px;
}
.emailtemplate .message-box .info-text .info-show-box .variable-list:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.emailtemplate .message-box .info-text .info-show-box .variable-list .variable-text {
  margin-right: 2rem;
  display: flex;
  align-items: flex-start;
  min-width: 108px;
}
.emailtemplate .message-box .info-text .info-show-box .variable-list .variable-details {
  flex-direction: column;
  display: flex;
}
.emailtemplate .nav-pills {
  border: 1px solid #ececed;
}
.emailtemplate .nav-pills .nav-link.active, .emailtemplate .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  /* background-color: var(--bs-nav-pills-link-active-bg); */
}
.emailtemplate .nav-pills .nav-link {
  display: block;
  border-radius: 0px;
  border-left: 3px solid transparent !important;
  color: #6B6B6B;
}
.emailtemplate .nav-pills .nav-link:hover {
  background-color: #F6F5FF;
}
.emailtemplate .nav-pills .nav-link.active {
  background-color: #F6F5FF;
  border-left: 3px solid #3273de !important;
  font-weight: bold;
  color: #000;
}
.emailtemplate .topendbtn {
  font-weight: bold;
}
.emailtemplate .content-container {
  display: flex;
  flex-direction: column;
}
.emailtemplate .content-container .content-item {
  border: 1px solid #ECECED;
  padding: 10px;
  margin-bottom: -1px;
}
.emailtemplate .subject-line-container label {
  color: #6B6B6B;
  font-size: 1.4rem;
}
.emailtemplate .subject-line-container .subject-line-input {
  width: 50%;
  height: 4rem;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .emailtemplate .subject-line-container .subject-line-input {
    width: 100%;
  }
}
.emailtemplate .main-content-box {
  border: 1px solid #ECECED;
  padding: 1rem;
  margin-top: 1.8rem;
}
.emailtemplate .main-content-box .box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emailtemplate .email-content-header {
  font-size: 1.7rem;
  color: #2C2C2C;
  border: 1px solid #EDEDED;
  padding: 1.5rem;
}
.emailtemplate .email-content-header .email-content-main {
  border: 1px solid #EDEDED;
  overflow: auto;
  max-height: 13rem;
  padding: 2rem;
}
.emailtemplate .email-content-header .email-content-main::-webkit-scrollbar {
  width: 3px; /* Set the width of the scrollbar */
}
.emailtemplate .email-content-header .email-content-main::-webkit-scrollbar-track {
  background-color: #FFFFFF; /* Set the background color of the scrollbar track */
  border-radius: 4px; /* Set the border radius of the scrollbar track */
}
.emailtemplate .email-content-header .email-content-main::-webkit-scrollbar-thumb {
  background-color: #A7A7A7; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}
.emailtemplate .email-content-header .email-content-main::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}
.emailtemplate .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  margin-right: 10px;
}/*# sourceMappingURL=EmailTemplate.css.map */