/*Theme Colors*/
.skill-submission .col-grid {
  flex: 0 0 auto;
  width: auto;
}
.skill-submission .card .daterow {
  display: flex;
  flex-direction: row;
}
.skill-submission .card .daterow .card-body {
  padding: 2rem 2rem 0.5rem 2rem;
  margin-bottom: 0 !important;
  flex: 0 0 auto;
  width: 50%;
}
.skill-submission .card .daterow .card-body .textSubject {
  font-size: 1.4rem;
}
.skill-submission .card .daterow .card-body .subject-line-container {
  min-width: 22rem;
}
@media screen and (max-width: 767px) {
  .skill-submission .card .daterow .card-body .subject-line-container {
    width: auto;
  }
}
.skill-submission .card .daterow .card-body .subject-line-container input {
  width: 100%;
  font-size: 1.6rem;
  padding: 0.6rem;
  border: 1px solid #CED4DA;
  border-radius: 5px;
}
.skill-submission .card .download-msg {
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
}
.skill-submission .card .download-msg span {
  display: inline-flex;
}
.skill-submission .card .download-msg button {
  font-size: 1.6rem;
  margin-left: 5px;
  color: #2799F6;
}
.skill-submission .card .card-footer {
  background: #fafafa;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  padding: 0.5rem 2rem;
  color: #6B6B6B;
  font-size: 1.6rem;
}
.skill-submission .card .card-footer .primary-btn {
  font-size: 1.6rem;
}/*# sourceMappingURL=SkillSubmissionReport.css.map */