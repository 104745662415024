@import "../../../assets/scss/variables";
.roleskillsjourney{
    .nav-tabs {
        .nav-item {
            
        }  
    }
    
    .btn-view {
        font-size: 1.7rem;
        color: #3273DE !important;
    }

}