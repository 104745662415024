.react-calendar__month-view__days__day--weekend {
  color: #0a0909;
}
.react-calendar__month-view__weekdays {
  color: #7d7d7d;
  font-size: 1.2rem;
}
.react-calendar__month-view__days {
  font-size: 1.4rem;
}
.react-calendar__month-view__weekdays__weekday abbr{
	text-decoration: none !important;
  cursor: default !important;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  color: #777777;
}
.react-calendar__century-view__decades{
  font-size: 1.4rem;
} 
.react-calendar__navigation__label__labelText--from{
	font-weight: bold;
}
.react-calendar__navigation button {
  min-width: 20px;
}

/* CSS file */
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year.highlighted-year,.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year.highlighted-year:hover {
  background-color: #3273DE;
  color: #fff;
}
