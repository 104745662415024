@import '../../assets/scss/variables';
.allCourses {
  .filterbtn {
    display: none;
    @media (max-width: 767px) {
      display: block;
      font-size: 2.1rem;
      font-weight: bold;
    }
  }
  .search-cls {
    &:focus {
      border-bottom: none;
    }
  }
  @media (max-width: 767px) {
    .leftSectionAllCourses {
      display: none;
    }
  }

  .leftSectionAllCourses {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    // // position: sticky;
    // // top: 150px;
    // z-index: 999;
    background: #fff;
    // height: calc(100vh - 130px);
    // overflow-y: auto;
    scrollbar-width: thin;
    @-moz-document url-prefix() {
      &.scroller {
        overflow-y: scroll;
        scrollbar-color: #d9d6d6 #888;
        scrollbar-width: thin;
      }
    }
    &.scroller::-webkit-scrollbar {
      width: 0.5rem;
      background-color: #d9d6d6;
      height: 0.5rem;
    }

    &.scroller::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
    .titleHead {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 1.5rem;
      .filterBy {
        font-size: 2.1rem;
        font-weight: bold;
        white-space: nowrap;
        @media screen and (max-width: 991px) {
          font-size: 1.9rem;
        }
      }
      .btn-view {
        font-size: 1.5rem;
        color: #2799f6;
        font-weight: 600;
        white-space: nowrap;
      }
    }
    .subHead {
      font-size: 1.7rem;
      color: #6b6b6b;
      font-weight: bold;
    }

    .scroller {
      max-height: 14.4rem;
      overflow: auto;
      scrollbar-width: thin;
    }
    @-moz-document url-prefix() {
      .scroller {
        overflow-y: scroll;
        scrollbar-color: #d9d6d6 #888;
        scrollbar-width: thin;
      }
    }
    .scroller::-webkit-scrollbar {
      width: 0.5rem;
      background-color: #d9d6d6;
      height: 0.5rem;
    }

    .scroller::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
    .filterCheckbox.form-check{
      position: relative;
      padding-left: 2em;
      .form-check-input{
        position: absolute;
        top:0;
        left: 2rem;
      }
    }
  }
  .rightSectionAllCourses {
    // height:157.9rem;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1nmdiq5-menu div {
      max-height: 50rem;
      width: auto;
      overflow: auto;
    }
    @media (max-width: 449px) {
      .css-1nmdiq5-menu {
        min-width: 9rem;
        overflow: auto;
      }
    }

    .css-1nmdiq5-menu div::-webkit-scrollbar {
      width: 0.5rem;
      background-color: #d9d6d6;
    }

    .css-1nmdiq5-menu div::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
  
    .card-block {
      // width: 28.5rem;
      //width: 28.5rem;

      // width: 29.6rem;
      // min-width: 29.6rem;
      // max-width: min-content;
      width: 100%;

      background: #ffffff;
      text-align: left;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      //flex: 0 1 31.6%;
      position: relative;
      max-height: 100%;
      // margin-right: auto;
      // margin-top: 10px;
      // margin-bottom: 10px;
    
      // @media (max-width: 1720px) {
      //   flex: 0 1 31.5%;
      // } 
      // @media (max-width: 1660px) {
      //   flex: 0 1 31.2%;
      // } 
     
      // @media screen and (max-width:1366px) and (min-width:1025px){
      //   flex: 0 1 29.6%;
      //   margin-left: 14px;
      // }
      // @media screen and (max-width:1024px) and (min-width:768px){
      //   flex: 0 1 30%;
        
      // }
      // @media screen and (max-width:767px) {
      //   flex: 0 1 46%;
      //   margin: 10px auto;
      // }
      // @media screen and (max-width:500px) {
      //   flex: 0 1 92%;
      //   margin: 10px auto;
      // }
    }
  }
}
.mobileCard{

  @media screen and (max-width:767px){
    display: flex !important;
    margin-left: 0 !important;
  }
  .infinite-scroll-component__outerdiv{
    width: 100%;
  }
}
.centeredCards{
  .infinite-scroll-component__outerdiv{
    width: 100%;
  }
}
.success-modal-container-mobile {
  padding: 1rem;
  // border: 1px solid #E8E8E8;

  .titleHead {
    border-bottom: 1px solid #e8e8e8;

    .filterBy {
      font-size: 1.7rem;
      font-weight: bold;
    }

    .btn-view {
      font-size: 1.4rem;
      color: #2799f6;
      font-weight: 600;
    }
  }

  .subHead {
    font-size: 1.7rem;
    color: #6b6b6b;
  }

  .scroller {
    max-height: 13.6rem;
    overflow: auto;
    scrollbar-width: thin;
  }

  .scroller::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #d9d6d6;
  }

  .scroller::-webkit-scrollbar-thumb {
    background-color: #888888;
  }
}
.searh-icon {
  background-image: url('../../assets/images/search.png');
  background-repeat: no-repeat;
  background-position: 99%;
  &::placeholder {
    color: red;
  }
}
// .css-13cymwt-control{

.icon-class {
  display: none;
}
.css-1nmdiq5-menu {
  .icon-class {
    display: block;
  }
}




//all-courses-info
.all-courses-info {
  display: flex;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .right-info {
    flex: 80%;
    width: 80%;
    transform: translateX(0px);
    transition: .5s;
    @media (min-width: 992px) and (max-width: 1280px) {
      flex: 70%;
      width: 70%;
    }
    @media (max-width: 991px) {
      width: 100%;
      flex: 100%;
      padding-left: 0px;
    }
  }
  .left-info {
    //flex: 329px;
    flex: 22% 1;
    margin-bottom: 3rem;
    padding-right: 20px;    
    @media (max-width: 991px) {
      display: none;
    }  
  }
}


.sidebarOther{
  position: relative !important;
  z-index: 4;
  width: calc(100% + 0px);
}
.sidebarBotton{
  position: fixed;
  bottom: 60px; /* Stick to the bottom */
  top: auto;
  //width: 346px;
  //width: calc(20% + 14px);
  width: calc(20% - 6px);
  @media (max-width: 1720px) {
    //width: calc(20% + 10px);
    width: calc(20% - 10px);
  } 
  @media (max-width: 1660px) {
    //width: calc(20% + 7px);
    width: calc(20% - 13px);
  } 
  @media (max-width: 1366px) {
    //width: calc(20% + 6px);
    width: calc(20% - 14px);
  }  
  @media (max-width: 1280px) {
    //width: calc(24% + 6px);
    width: calc(24% - 14px);
  }  
  @media (max-width: 1024px) {
    //width: calc(24% + 0px);
    width: calc(24% - 20px);
  } 
  @media (max-width: 991px) {    
    position: relative;
    bottom: none;
    width: 100%;
  }  
}
