.info-show {
  position: absolute;
  background-color: #fff;
  left: 16%;
  padding: 8px;
  min-width: 250px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
  border: 1px solid rgb(167, 167, 167);
  z-index: 2;
  top: -49px;
}
@media (max-width: 991px) {
  .info-show {
    min-width: 300px;
  }
}
.info-show P {
  margin-bottom: 5px !important;
  font-size: 1.4rem;
}
.info-show::after {
  content: "";
  background-image: url(../../assets/images/right-arrow.svg);
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 35px;
  width: 35px;
  left: 10%;
  bottom: -22px;
  rotate: 90deg;
}

.associated-skill {
  background: transparent;
  border: none;
  color: #3273de;
}

.popover-body {
  font-size: 12px;
}/*# sourceMappingURL=CourseCard.css.map */