/*Theme Colors*/
.Carousel-block.sioenablement {
  border: 1px solid hsla(0, 0%, 80%, 0.6);
  border-radius: 5px;
}
.Carousel-block.sioenablement .title-block {
  padding: 15px;
  min-height: 35px;
}
.Carousel-block.sioenablement .card {
  border: none;
}
.Carousel-block.sioenablement .card .card-img-top {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Carousel-block.sioenablement .card .card-body {
  text-align: center;
}
.Carousel-block.sioenablement .card .card-body .card-title {
  font-size: 1.6rem;
  color: #2C2C2C;
  font-weight: 600;
}
.Carousel-block.sioenablement .card .card-body .card-subtitle {
  font-size: 1.5rem;
  color: #6b6b6b;
}
.Carousel-block.sioenablement .card .card-body .card-text {
  font-size: 1.3rem;
  color: #a7a7a7;
}
.Carousel-block.sioenablement .reachout {
  background-color: #FFFFFF;
  height: 100%;
  padding: 0;
  border-left: 1px solid #e8e8e8;
}
.Carousel-block.sioenablement .reachout .mail .desc-text {
  padding-top: 10px;
}
.Carousel-block.sioenablement .reachout .mail a {
  color: #2799f6;
  text-decoration: none;
}
.Carousel-block.sioenablement .reachout .submit-file .desc-text {
  padding-top: 10px;
}
.Carousel-block.sioenablement .reachout .submit-file .submit-enablement {
  background-color: #EB1000;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 25px;
  margin-top: 10px;
}
.Carousel-block.sioenablement .dotted-border {
  border-bottom: 1.5px dashed #e8e8e8;
  margin-bottom: 20px;
  margin-top: 20px;
}
.Carousel-block.sioenablement .react-multiple-carousel__arrow--right {
  bottom: 25px;
  right: 50%;
  margin-right: -240px;
}
@media screen and (max-width: 992px) {
  .Carousel-block.sioenablement .react-multiple-carousel__arrow--right {
    margin-right: -40px;
  }
}
.Carousel-block.sioenablement .react-multiple-carousel__arrow--left {
  bottom: 25px;
  left: 50%;
  margin-left: -240px;
}
@media screen and (max-width: 992px) {
  .Carousel-block.sioenablement .react-multiple-carousel__arrow--left {
    margin-left: -40px;
  }
}
.Carousel-block.sioenablement .react-multi-carousel-list {
  width: 100%;
}
.Carousel-block.sioenablement .prev-button {
  left: 0px;
}
.Carousel-block.sioenablement .next-button {
  right: 0px;
}/*# sourceMappingURL=SIOEnablemnetTeam.css.map */