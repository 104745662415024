@import '../../assets/scss/variables';

.breadCrumb{
    .link-button {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        font-size: 1.4rem;
        // text-decoration: underline;
        color: #2C2C2C; /* or any link color of your choice */
      }
      .breadcrumbLabel{
        color: #2C2C2C;
        font-size: 1.4rem;
      }
    
      .breadcrumb-item {
        display: inline-block;
        font-size: 1.4rem;
      }
      .breadcrumb-item:last-child {
        color: #6b6b6b;
      }
      
      
      .separator {
        color: #aaa; /* Set the color of the separator */
        margin: 0 4px; /* Adjust the margin to control spacing around the separator */
      }
    
      .breadcrumb-item + .breadcrumb-item::before {   
        content: var(--bs-breadcrumb-divider, "") !important/* rtl: var(--bs-breadcrumb-divider, "/") */;
    }
      
}