/*Theme Colors*/
.navbar-footer.navigation.footerMain {
  padding: 10px 30px 12px;
  background-color: #F5F5F5 !important;
}

.navbar-footer.navigation.footerMain .navbar-brand {
  padding: 0 !important;
}

.navbar-footer .home-footer {
  padding: 0 !important;
}

.navbar-footer.navigation.footerMain a.nav-link {
  font-size: 1.7rem;
  color: #6B6B6B;
}

.footer-logo-css {
  cursor: default !important;
}

@media screen and (max-width: 767px) {
  .sticky-top .navbar .navbar-brand img {
    width: 80px;
  }
  .navbar-footer.navigation.footerMain {
    padding: 17px 24px 15px;
  }
  .navbar-footer.navigation.footerMain a.nav-link {
    font-size: 1.4rem;
  }
}/*# sourceMappingURL=Footer.css.map */