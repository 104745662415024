.item-name {
    display: inline-block;
    vertical-align: middle;
}
.coming-soon {
    font-size: 1.3rem;
    color: #ffffff;
    background-color: #21A5A2;
    padding: 3px 8px;
    border-radius: 12px;
    margin-left: 10px;
    line-height: 16px;
}