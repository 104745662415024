/*Theme Colors*/
.breadCrumb .link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  font-size: 1.4rem;
  color: #2C2C2C; /* or any link color of your choice */
}
.breadCrumb .breadcrumbLabel {
  color: #2C2C2C;
  font-size: 1.4rem;
}
.breadCrumb .breadcrumb-item {
  display: inline-block;
  font-size: 1.4rem;
}
.breadCrumb .breadcrumb-item:last-child {
  color: #6b6b6b;
}
.breadCrumb .separator {
  color: #aaa; /* Set the color of the separator */
  margin: 0 4px; /* Adjust the margin to control spacing around the separator */
}
.breadCrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "") !important;
}/*# sourceMappingURL=BreadCrumb.css.map */