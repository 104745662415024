.accordion-button {
	box-shadow: none;
}
.accordionArrow .accordion .accordion-item .accordion-header .accordion-button::after{
display: none;	
}
.accordionArrow .accordion .accordion-item .accordion-header .accordion-button{	
padding: 15px 20px;
}
/* .accordionArrow .accordion .accordion-item .accordion-header .accordion-button:last-child::after {
	margin-left: auto!important;
    margin-right: 0!important;
	display: block;
} */

.accordionArrow .accordion .accordion-item:last-child .accordion-header .accordion-button::after {
    /* display: block; */
	margin-left: auto!important;
    margin-right: 0!important;
	right: 20px !important;
}


.accordion-button:focus {
	box-shadow: none !important;
	/* border: none !important; */
}

.accordion-button:not(.collapsed) {
	color: #212529;
}

.accordion-item:not(:first-of-type) {
	border-top: 1px solid #E8E8E8;
	border-radius: 0;
}

.accordion-item .accordion-button::after {
	position: absolute !important;
	left: 20px;
	width: 24px;
	height: 24px;
	/* background-color: #3273DE; */
	background-size: 15px 15px;
	opacity: 1;
	background-position: center;
	background-color: transparent;
	border: 1px solid #A7A7A7;
	border-radius: 3px;
	background-image: url("../../assets//images/up-chevron-svgrepo-com-black.svg");
	transform: rotate(180deg);
}

.accordion-item .accordion-button:not(.collapsed)::after {
	position: absolute !important;
	left: 20px;
	width: 24px;
	height: 24px;
	background-size: 15px 15px;
	background-color: transparent !important;
	border: 1px solid transparent;
	border-radius: 3px;
	opacity: 1;
	background-position: center;

	/* background-image: url("../../assets//images/up-chevron-svgrepo-com-white.svg");
	transform: rotate(0deg); */
	background-image: url("../../assets//images/up-chevron-svgrepo-com-black.svg");
	transform: rotate(0deg);
	/* transition: var(--bs-accordion-btn-icon-transition); */
}

.accordion-item .accordion-button {
	border-radius: 0 !important;
	text-align: left;
	/* font: normal normal normal 18px/24px Adobe Clean !important; */
	font-size: 1.6rem;
	letter-spacing: 0px;
	color: #2C2C2C !important;
	opacity: 1;
	padding: 15px 65px;
}

.accordion-item {
	margin: 15px 0;
	border-radius: 0 !important;
}

.accordion-header .accordion-button {
	border: 1px solid #E8E8E8;
	text-align: left;
	/* font: normal normal normal 16px/20px Adobe Clean !important; */
	font-size: 1.6rem;
	letter-spacing: 0px;
	color: #2C2C2C !important;
	opacity: 1;
}

.accordion-button:not(.collapsed) {
	color: #2C2C2C !important;
	background-color: #F6F5FF !important;
	box-shadow: none !important;
}

.accordion-body .custom-accordion-header {
	color: #6B6B6B;
	border-bottom: 1px solid #EEEEEE;
	margin-bottom: 10px;

}

.custom-accordion .accordion-button {
	box-shadow: none;
}

.custom-accordion .accordion-button:focus {
	box-shadow: none;
}

.custom-accordion .accordion-button:not(.collapsed) {
	color: #212529;
}

.custom-accordion .accordion-item:not(:first-of-type) {
	border-top: 1px solid #E8E8E8;
	border-radius: 0;
}

.custom-accordion .accordion-item .accordion-button::after {
	position: absolute !important;
	left: 20px;
	width: 24px;
	height: 24px;
	/* background-color: #3273DE; */
	background-size: 15px 15px;
	border-radius: 3px;
	opacity: 1;
	background-position: center;

	background-color: #FFFFFF;
	border: none !important;
	background-image: url("../../assets//images/up-chevron-svgrepo-com-black.svg");
	transform: rotate(180deg);
}

.custom-accordion .accordion-item .accordion-button:not(.collapsed)::after {
	position: absolute !important;
	left: 20px;
	width: 24px;
	height: 24px;
	background-size: 15px 15px;
	background-color: #3273DE;
	border: 1px solid #3273DE;
	border-radius: 3px;
	opacity: 1;
	background-position: center;
	background-image: url("../../assets//images/up-chevron-svgrepo-com-black.svg");
	transform: rotate(0deg);
	/* transition: var(--bs-accordion-btn-icon-transition); */
}

.custom-accordion .accordion-item .accordion-button {
	border-radius: 0 !important;
	text-align: left;
	/* font: normal normal normal 18px/24px Adobe Clean !important; */
	letter-spacing: 0px;
	color: #2C2C2C !important;
	opacity: 1;
	padding: 15px 52px;
}

.custom-accordion .accordion-item {
	margin: 15px 0;
	border-radius: 0 !important;
}

.custom-accordion .accordion-header .accordion-button {
	border: 1px solid #E8E8E8;
	text-align: left;
	/* font: normal normal normal 16px/20px Adobe Clean !important; */
	/* font-size: 1.7rem; */
	letter-spacing: 0px;
	color: #2C2C2C !important;
	opacity: 1;
}

.custom-accordion .accordion-button:not(.collapsed) {
	color: #2C2C2C !important;
	background-color: #F6F5FF !important;
	box-shadow: none !important;
}

.custom-accordion .accordion-body .custom-accordion-header {
	color: #6B6B6B;
	border-bottom: 1px solid #EEEEEE;
	margin-bottom: 10px;
}

.custom-accordion-tbl td {
	text-align: left;
	padding: 5px 0;
	/* vertical-align: bottom; */
}
.custom-accordion-tbl td td{
	text-align: left;
	padding: 10px;
	padding-left: 0;
	/* vertical-align: bottom; */
}

.custom-accordion-tbl td .check-box {
	text-align: left;
	padding: 0 10px;
	vertical-align: middle;
	display: inline-block;
}

.custom-accordion-tbl td .nav-link,
.container-tbl-tr-tbl-tr .nav-link {
	text-align: left;
	letter-spacing: 0px;
	color: #2799F6 !important;
	opacity: 1;
	display: inline-block;
	vertical-align: middle;
	line-height: 2rem;
}

.custom-accordion-item-content .custom-accordion-tbl tr:first-child td {
	border-top: 0px solid transparent;
}

/* .custom-accordion-item-content .custom-accordion-tbl tr:last-child td {
  width:110px;
} */
/*.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr:last-child td {
	width: 110px;
}*/

/*.my-skill-journey .custom-accordion-header .custom-accordion-tbl tr td {
	width: 13% !important;
	max-width: 152px;
	min-width: 152px;



}*/



.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(1) {
	width: 17% !important;
	max-width: 189px;
	min-width: 189px;
	padding-right: 1.1rem;
}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(2) {
	width: 17% !important;
	max-width: 189px;
	min-width: 189px;

}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(3) {
	width: 10% !important;
	max-width: 118px;
	min-width: 118px;
	word-break: inherit;
	text-align: center;

}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(4) {
	width: 10% !important;
	max-width: 118px;
	min-width: 118px;
	text-align: center;
	
}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(5) {
	width: 10% !important;
	max-width: 100px;
	min-width: 100px;
	text-align: center;
	
}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(6) {
	width: 12% !important;
	max-width: 100px;
	min-width: 100px;
	text-align: center;
	
}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(7) {
	width: 12% !important;
	max-width: 160px;
	min-width: 160px;
	text-align: center;
	
}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(8) {
	width: 12% !important;
	max-width: 140px;
	min-width: 140px;
	text-align: center;
	
}
.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(9) {
	max-width: 100px;
	min-width: 100px;
	text-align: center;
	
}

@media (min-width: 1024px) and (max-width: 1280px) {
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(1) {
		min-width: 167px;
		vertical-align: middle;
		
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(2) {
		min-width: 155px;	
		vertical-align: middle;
		padding-bottom: 0;
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(3) {
		min-width: 106px;
		vertical-align: middle;
		padding-bottom: 0;
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(4) {
		min-width: 118px;
		padding-bottom: 0;	
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(5) {
		min-width: 100px;
		padding-bottom: 0;	
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(6) {
		min-width: 100px;
		padding-bottom: 0;	
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(7) {
		min-width: 130px;
		padding-bottom: 0;	
	}
	.my-skill-journey .custom-accordion-item-content .custom-accordion-tbl tr td:nth-child(8) {
		min-width: 140px;
				
	}
  }


/* style for Skill Submission table starts here */
.skill-submission .custom-accordion-tbl tr td:nth-child(1) {
	width: 12% !important;
	max-width: 126px;
	min-width: 126px;

}

.skill-submission .custom-accordion-tbl tr td:nth-child(2) {
	width: 14% !important;
	max-width: 126px;
	min-width: 126px;


}

.skill-submission .custom-accordion-tbl tr td:nth-child(3) {
	width: 10% !important;
	max-width: 118px;
	min-width: 118px;
	word-break: inherit;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(4) {
	width: 10% !important;
	max-width: 95px;
	min-width: 95px;
	text-align: center;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(5) {
	width: 8% !important;
	/* changed 82 to 88px */
	max-width: 88px;
	min-width: 88px;
	text-align: center;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(6) {
	width: 9% !important;
	max-width: 82px;
	min-width: 82px;
	text-align: center;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(7) {
	width: 13% !important;
	max-width: 82px;
	min-width: 82px;
	text-align: center;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(8) {
	width: 9% !important;
	/* changed 82 to 130px */
	max-width: 130px;
	min-width: 130px;
	text-align: center;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(9) {
	width: 10% !important;
	max-width: 140px;
	min-width: 140px;
	text-align: center;
}

.skill-submission .custom-accordion-tbl tr td:nth-child(10) {
	width: 10% !important;
	max-width: 100px;
	min-width: 100px;
	text-align: center;
}

/*.accord-ss{
	width: 1%;
}
.checkbox-ss{
	width: 1%;
}*/

/* style for Skill Submission table starts here */
.custom-accordion-item-content .custom-accordion-tbl tr:last-child th {
	width: 110px;
}

/* .custom-accordion-item-content .custom-accordion-tbl td {
	 border-top: 1px solid #EEEEEE; 
	 vertical-align: top; 
} */

.custom-accordion-header {
	display: flex;
	flex-direction: horizontal;
	padding-left: 2.8rem;
}

.custom-accordion-header .container-tbl-tr-btn .check-box {
	padding-top: 5px;
	padding-left: 2.5rem;
}



/*.custom-accordion-item*/
.container-tbl-tr-btn .custom-accordion-item-action {
 margin-left: 10px;
}
.custom-accordion-item .custom-accordion-item-content .btn-action {
    width: 24px;
    height: 24px;
    background-size: 15px 15px;
    border-radius: 3px;
    opacity: 1;
    background-position: center;
    background-color: #FFFFFF;
    border: 1px solid #A7A7A7;
	padding: 0;
	margin-right: 10px;
}
.custom-accordion-item .custom-accordion-item-content .btn-action .down {
	top: 0.6rem;
    left: 0.7rem;
    width: 8px;
    height: 8px;
}
.custom-accordion-item .custom-accordion-item-content .btn-action .up {
	top: 0rem;
    left: 0.7rem;
    width: 8px;
    height: 8px;
}
/*.custom-accordion-item*/

.skill-submission .custom-accordion-header {
	display: flex;
	flex-direction: horizontal;
	padding-left: 67px;
	border-bottom: 2px solid #E1E1E2;
}

.skill-submission .content-box {
	border: 1px solid #E8E8E8;
	max-width: 100%;
	overflow-x: auto;
}

.skill-submission .custom-accordion-item-action {
	margin: 0 8px 0 15px;
}

.skill-submission .custom-accordion-item-action .btn-action {
    width: 24px;
    height: 24px;
    background-size: 15px 15px;
    border-radius: 3px;
    opacity: 1;
    background-position: center;
    background-color: #FFFFFF;
    border: 1px solid #A7A7A7;
	padding: 0;
	margin-right: 5px;
}
.skill-submission .custom-accordion-item-action .btn-action .down {
	top: 0.5rem;
    left: 0.785rem;
    width: 8px;
    height: 8px;
}
.skill-submission .custom-accordion-item-action .btn-action .up {
	top: 0rem;
    left: 0.785rem;
    width: 8px;
    height: 8px;
}

/* .skill-submission .check-box {
	border: 1px solid #E8E8E8;
} */


.custom-accordion-container-tbl {
	width: 100%;
}

.custom-accordion-tbl td .tbl-btn-status {
	font-size: 1.4rem;
	width: 125px;
	border: none;
	border-radius: 30px;
	/* height: 28px; */
	text-align: center;
	padding: 5px 10px;
	margin: 0;
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
}

.custom-accordion-tbl td .tbl-btn-status:not(.rejected)+span {
	visibility: hidden;
}

.custom-accordion-tbl td .tbl-info-btn-status {
	display: inline-block;
	vertical-align: middle;
	border: none !important;
	width: 28px;
	height: 20px;
	background: url('../../assets/images/infoIcon.png') no-repeat;
	background-size: 20px 20px;
	background-position: 4px -1px;
}

.custom-accordion-tbl td .tbl-approve-btn-status {
	display: inline-block;
	vertical-align: middle;
	border: none !important;
	width: 16px;
	height: 13px;
	background: url('../../assets/images/approve.svg') no-repeat;
	margin: 0 0px 5px 5px;
}

.custom-accordion-tbl td .tbl-level-info-btn {
	display: inline-block;
    vertical-align: middle;
    border: none !important;
    width: 13px;
    height: 13px;
	background: url('../../assets/images/info.svg') no-repeat;
	position: absolute;
    /* left: 5p; */
    margin-left: 6px;
    margin-top: 5px;
}

/*.skill-submission .custom-accordion-header .container-tbl-tr-btn .check-box {
	padding-top: 3.7rem;
}*/

.tbl-btn-status.not-started {
	color: #2C2C2C;
	background-color: #DDDBDB;
}

.tbl-btn-status.rejected {
	color: #EB1000;
	background-color: #FFE6E6;
}

.tbl-btn-status.in-progress {
	color: #3273DE;
	background-color: #DAECFF;
}

.tbl-btn-status.waiting-approval {
	color: #E9740A;
	background-color: #FFE9D4;
}

.tbl-btn-status.approved {
	color: #009C3B;
	background-color: #DBF2E4;
}

.custom-accordion-header .custom-accordion-tbl {
	width: 100%;
}

.custom-accordion-item {
	display: flex;
	flex-direction: horizontal;
	/*padding-left: 50px;*/
	width: 100%;
}

.skill-submission .custom-accordion-item {
	display: flex;
	flex-direction: horizontal;
	padding-left: 0px;
	width: 100%;
}

.custom-accordion-item .btn-checkbox {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

.custom-accordion-item .btn-action {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border: none;
}

.custom-accordion-item .btn-action .no-collapse {
	width: 15px;
	height: 15px;
	background-image: var(--bs-accordion-btn-icon);
	transform: rotate(0deg);
}

.custom-accordion-item .btn-action .yes-collapse {
	width: 15px;
	height: 15px;
	background-image: var(--bs-accordion-btn-icon);
	transform: rotate(180deg);
}

.custom-accordion-item .custom-accordion-item-content {
	display: flex;
	flex-direction: 'horizontal';
	width: 100%;
	align-items: self-start;
}



.custom-accordion-item-content .custom-accordion-tbl {
	width: 100%;
}

.custom-accordion-item-content .custom-accordion-tbl-cus {
	width: 100%;
	border-bottom: 1px solid #eee;
}

/* 6th july table header changes start */

/*.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(1) {
	width: 15% !important;
}

.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(2) {
	width: 15% !important;
}

.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(3) {
	width: 15% !important;
}

.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(4) {
	width: 15% !important;
}

.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(5) {
	width: 15% !important;
}

.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(6) {
	width: 15% !important;
}

.custom-accordion-tbl-cus.custom-accordion-tbl tr td:nth-child(2) {
	width: 15% !important;
}*/

/* 6th july table header changes end */

.custom-accordion-item-content .custom-accordion-tbl tr:not(:first-of-type) td:first-of-type {
	visibility: hidden;
}

.custom-accordion-item-action .btn-action {
	width: 20px;
	height: 20px;
	background-color: transparent;
	margin-right: 15px;
	position: relative;
	padding: 1.3rem;
	margin-top: 1.2rem;
	border: 1px solid #a7a7a7;
	border-radius: 3px;
}

.custom-accordion-item-action .btn-action .arrow {
	border: solid #555252;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	width: 12px;
	height: 12px;

}

.custom-accordion-item-action .btn-action .up {
	border: solid #555252;
	border-width: 0 2px 2px 0;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	margin-top: 10px;
	position: absolute;
	top: 0.1rem;
	left: 0.7rem;
}

.custom-accordion-item-action .btn-action .down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	position: absolute;
	top: 0.4rem;
	left: 0.7rem;
}

.container-tbl-tr-btn {
	vertical-align: top;
	width: 1%;
}

.skill-submission .custom-accordion-header .container-tbl-tr-btn {
	vertical-align: top;
	width: 3%;
	margin-top: 4.7rem;
}

.container-tbl-tr-btn .check-box {
	padding-top: 18px;
}
.container-tbl-tr-btn .check-box .form-check-input {
	width: 1.5rem;
    height: 1.5rem;
	margin-left: 0;
	margin-top: 0;
}
.container-tbl-tr-btn .check-box.form-check {
	padding-left: 0;
	padding-right: 10px;
}


/*.container-tbl-tr-btn .check-box input {
	height: 20px;
	width: 20px;
}*/

.container-tbl-tr-tbl {
	width: 100%;
	/*padding-left: 5px;*/
}

.container-tbl-tr-tbl-tr {
	width: 10%;
	
}

.container-tbl-tr-tbl-tr-skillName {
	width: 22%;
	vertical-align: top;
}

.container-tbl-tr-tbl-tr-skillGroup {
	width: 18%;
	vertical-align: middle;
}

.container-tbl-tr-tbl-tr-currencyLevel {
	width: 12%;
	vertical-align: middle;
}

.container-tbl-tr-tbl-tr-targetLevel {
	width: 10%;
}

.container-tbl-tr-tbl-tr-submitDate {
	width: 10%;
}

.container-tbl-tr-tbl-tr-approvalDate {
	width: 12%;
}

.container-tbl-tr-tbl-tr-status {
	width: 10%;
}

.btn-checkbox {
	margin-top: 13px;
}

.btn-checkbox .form-check {
	width: 13px;
	height: 13px;
	border-radius: 5px;
	position: relative;
}

.custom-accordion-tbl td .tbl-info-container {
	position: relative;
	/* new code css changes */
	display: flex;
	justify-content: space-between;
	justify-content: center;
}

.tbl-info-btn-status-span- .popup {
	display: none;
}

.tbl-info-btn-status-span-true .popup {
	display: inline-block !important
}

.tbl-info-btn-status-span-false .popup {
	display: none
}

.custom-accordion-tbl td .tbl-info-info-box {
	/* width: 175px;
	position: absolute;
	right: 0;
	top: 36px; */
	width: 250px;
	border: 1px solid #ccc;
    position: absolute;
    right: 150px;
    top: -7px;
	min-height: 50px;
    max-height: 100px;
    overflow-y: auto;
	box-shadow: 2px 13px 11px -6px rgba(0,0,0,0.43);
	@media screen and (max-width:1280px) and (min-width: 700px) {
		right: 140px;
		}
	@media screen and (min-width:1281px) and (max-width: 1537px) {
		right: 140px;
		}
	@media screen and (min-width:2000px) {
		right: 175px;
		}
	@media screen and (min-width:3840px) {
		right: 240px;
		}
	@media screen and (min-width:5760px) {
		right: 340px;
		}
}
/* .custom-accordion-tbl td .tbl-info-info-box .popup-wrapper::after{
	content: "";
	background-image: url(../../assets/images/right-arrow.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
	position: absolute;
    right: -12px;
    bottom: 0;
    width: 30px;
    height: 40px;
    

} */
.custom-accordion-tbl td .tbl-info-info-box::-webkit-scrollbar {
      width: 0.5rem;
      background-color: #d9d6d6;
      height: 0.5rem;
}
 
.custom-accordion-tbl td .tbl-info-info-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.4); 
    border-radius: 8px;
    -webkit-border-radius: 8px;
}
 
.custom-accordion-tbl td .tbl-info-info-box::-webkit-scrollbar-thumb {
    background-color: #888888; 
}

.custom-accordion-tbl td .tbl-info-info-box .popup {
	width: 400px;
	margin: 0 auto;
	position: relative;
	text-align: center;
	z-index: 999;
}

.custom-accordion-tbl td .tbl-info-info-box .popup:after {
	content: "";
	height: 100%;
	width: 100%;
	border-radius: 4px;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
}

.custom-accordion-tbl td .tbl-info-info-box .popup:before {
	content: "";
	width: 20px;
	height: 20px;
	transform: rotate(45deg);
	box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
	background: #fff;
	position: absolute;
	z-index: 998;
}

/*
Different arrow positioning
*/
.custom-accordion-tbl td .tbl-info-info-box .popup.arrow-top:before {
	left: calc(50% - 10px);
	top: -8px;
}

.custom-accordion-tbl td .tbl-info-info-box .popup.arrow-right:before {
	top: calc(50% - 10px);
	right: -8px;
}

.custom-accordion-tbl td .tbl-info-info-box .popup.arrow-bottom:before {
	left: calc(50% - 10px);
	bottom: -8px;
}

.custom-accordion-tbl td .tbl-info-info-box .popup.arrow-left:before {
	top: calc(50% - 10px);
	left: -8px;
}

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.custom-accordion-tbl td .tbl-info-info-box .popup-wrapper {
	width: 100%;
	padding: 10px;
	border-radius: 3px;
	background: #fff;
	box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
	position: relative;
	z-index: 998;
	word-wrap: break-word;
	/* margin-bottom: 1rem; */
}

.custom-accordion-tbl td .tbl-info-info-box .popup-wrapper .popup-close-info {
	background: #adadad;
    border: none;
    border-radius: 50%;
    color: #fff;
    height: 15px;
    line-height: 6px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    padding: 0;
}

.custom-accordion-tbl td .tbl-info-info-box .popup-wrapper p {
	padding:0;
	white-space: break-spaces;
}

@media (max-width: 1200px) {

	.my-skill-journey-container .accordion-body {
		max-width: 100%;
		/* overflow-x: auto; */
		width: 100%;
	}
}
.custom-accordion-item {
	overflow-x: auto;
}

.hasActionBtnsContainer {
	display: table-cell;
	/*margin: 5px 0 0 0 !important;*/
	/* padding: 0 !important; */
}

.hasAction {
	display: inline-block;
	vertical-align: middle;
	margin: 0 5px;
	padding: 0;

	background: none;
	width: 30px;
	height: 30px;
	border: 2px solid;
	border-radius: 50%;
}

.hasAction i {
	width: 100%;
	height: 100%;
	margin-top: 7px;
	font-weight: bold;
}

.hasActionCancel {
	border-color: red;
	color: red;
}

.hasActionCorrect {
	border-color: green;
	color: green;
}

/* .skill-submission-modal {} */

.skill-submission-modal .top-section {
	background: #F6F5FF;
	padding: 30px;
	text-align: center;
}

.skill-submission-modal .top-section img {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.skill-submission-modal .top-section span {
	display: inline-block;
	vertical-align: middle;
	font-size: 2.1rem;
}

.skill-submission-modal .content-section {
	margin: 20px 0;
}

/* .skill-submission-modal .btn-cancel,
.skill-submission-modal .btn-cancel:hover {
	color: #2C2C2C;
	font-size: 1.5rem;
	border: none;
} */

/* .skill-submission-modal .btn-submit {} */

.skill-submission-modal .content-section .content-label {
	font-size: 1.8rem;
}

.skill-submission-modal .content-section .content-label .astrix {
	color: red;
}

.skill-submission-modal .content-section .content-textarea {
	font-size: 1.7rem;
	border: 1px solid #EDEDED;
}

.skill-submission .skill-submission-btn {
	margin: 15px 0;
	text-align: right;
}

.skill-submission .skill-submission-btn .btn-label img {
	display: inline-block;
	vertical-align: middle;
}

.skill-submission .skill-submission-btn .btn-label span {
	display: inline-block;
	vertical-align: middle;
}

.skill-submission .skill-submission-btn .btn-label {
	display: inline-block;
	vertical-align: middle;
	color: #2C2C2C;
	font-weight: 600;
	border-radius: 25px;
	padding: 5px 20px;
	text-transform: capitalize;
	margin-left: 15px;
	font-size: 1.7rem;
}

.skill-submission .skill-submission-btn .btn-label-reject {
	border: 1px solid red;
	color: red;
	cursor: pointer;
}

.skill-submission .skill-submission-btn .btn-label-reject i {
	display: inline-block;
	vertical-align: middle;
}

.skill-submission .skill-submission-btn .btn-label-approve i {
	display: inline-block;
	vertical-align: middle;
}

.skill-submission .skill-submission-btn .btn-label-approve {
	border: 1px solid green;
	color: green;
	cursor: pointer;
}

/* myskiljourney changes */


.my-skill-journey-container .skill-submission-btn {
	margin: 20px 0 0;
	text-align: right;
}

.my-skill-journey-container .skill-submission-btn .btn-label img {
	display: inline-block;
	vertical-align: middle;
}

.my-skill-journey-container .skill-submission-btn .btn-label span {
	display: inline-block;
	vertical-align: middle;
}

.my-skill-journey-container .skill-submission-btn .btn-label {
	display: inline-block;
	vertical-align: middle;
	color: #2C2C2C;
	font-weight: 600;
	border-radius: 25px;
	padding: 3px 20px;
	text-transform: capitalize;
	margin-left: 15px;
	font-size: 1.7rem;
}

.my-skill-journey-container .skill-submission-btn .btn-label-reject {
	border: 1px solid red;
	color: red;
	cursor: pointer;
	font-family: Adobe Clean,sans-serif;
}
.my-skill-journey-container .skill-submission-btn .btn-label-reject:hover {
 box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

.my-skill-journey-container .skill-submission-btn .btn-label-reject i {
	display: inline-block;
	vertical-align: middle;
}

.my-skill-journey-container .skill-submission-btn .btn-label-approve i {
	display: inline-block;
	vertical-align: middle;
}
.my-skill-journey-container .skill-submission-btn .btn-label .pi-times:before {
    position: relative;
    top:2px;

}
.my-skill-journey-container .skill-submission-btn .btn-label .pi-check:before {
    position: relative;
    top:2px;

}
.my-skill-journey-container .skill-submission-btn .btn-label-approve {
	border: 1px solid green;
	color: green;
	cursor: pointer;
	font-family: Adobe Clean,sans-serif;
}
.my-skill-journey-container .skill-submission-btn .btn-label-approve:hover {
	box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

.hidden-visibility {
	visibility: hidden;
}

.disable-component .btn-action:disabled,
.disable-component .btn-action[disabled] {
	border: 1px solid #e8e8e8;
}

.disable-component .btn-action[disabled] .down {
	border: solid #e8e8e8;
	border-width: 0 2px 2px 0;
}

.success-text-message2 {
	text-align: center;
	font-size: 1.9rem;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	padding: 36px 0 20px 0;
	width: 100%;
}

.no-data {
	font-size: 1.7rem;
	font-weight: bold;
	text-align: center;
	padding: 15px;
}

.container-tbl-tr-checkbox {
	position: relative;
	padding-right: 0.5rem;
	/*padding-left: 1rem;*/
}



.main-container-section.my-skill-journey.view-skill-journey .custom-thead .container-tbl-tr-btn-checkbox{
	position: absolute;
	top: -8px;
}
.container-tbl-tr-btn-checkbox {
	position: absolute;
	top: -3px;
}
.popup-wrapper .content-text {
	text-align: left;
}

.main-container-section.skill-submission .custom-accordion-container-tbl .custom-accordion-tbl .container-tbl-tr-tbl-tr{
vertical-align: top;
padding: 10px;
}
.main-container-section.my-skill-journey .custom-accordion-container-tbl .custom-accordion-tbl .container-tbl-tr-tbl-tr.container-tbl-tr-tbl-tr-status .tbl-info-container .rejected .tbl-info-btn-status-span  .tbl-info-info-box{
right: 180px;
@media screen and (max-width:1280px) and (min-width: 700px) {
right: 160px;
}
@media screen and (min-width:1281px) and (max-width: 1537px) {
right: 160px;
}

}
.main-container-section.skill-submission .custom-accordion-container-tbl .custom-thead .custom-accordion-tbl-cus td{
padding: 10px;
}
.main-container-section.skill-submission .custom-accordion-container-tbl .container-tbl-tr-btn .check-box.form-check{
padding-right: 0;
padding-top: 19px;
}
.main-container-section.my-skill-journey .custom-accordion-container-tbl .custom-thead .container-tbl-tr-btn .check-box.form-check{
padding-top: 15px;
}
.main-container-section.my-skill-journey .custom-accordion-container-tbl .container-tbl-tr-btn .check-box.form-check{
padding-top: 14px;
}
.main-container-section.my-skill-journey.view-skill .custom-accordion-container-tbl .container-tbl-tr-btn .check-box.form-check{
padding-top: 20px;
}
.main-container-section.my-skill-journey.view-skill .custom-accordion-container-tbl .custom-thead .container-tbl-tr-btn .check-box.form-check{
padding-top: 14px;
}
.main-container-section.my-skill-journey.view-skill-journey .custom-accordion-container-tbl .container-tbl-tr-btn .check-box.form-check{
padding-top: 18px;
}
/* .main-container-section.my-skill-journey.mySkillJourney .custom-accordion .accordion-item .accordion-header .accordion-button {
padding-left: 52px;
} */

.horizontal-tab-block .accordionArrow .accordion {
    border: 1px solid #ececed;
    margin-top: 20px;
}
.horizontal-tab-block .accordionArrow .accordion .accordion-item {
    border: none;
    margin: 0;
}
.horizontal-tab-block .accordionArrow .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #ececed;
    border-left: 3px solid #2799F6;
}
.horizontal-tab-block .accordionArrow .accordion .accordion-item .accordion-header .accordion-button {
    border: none;
    font-size: 1.6rem;
    padding: 15px;
    padding-bottom: 20px;
    position: relative;
    /* border: 3px solid transparent; */
	border-left: 3px solid transparent;
}
.accordionArrow .accordion .accordion-item .accordion-header .accordion-button:hover {
    background-color: #f6f5ff;
    border-left: 3px solid transparent;
}
.accordionArrow .accordion .accordion-item .accordion-header .accordion-button[aria-expanded="true"] {
    background-color: #E2E2E2;
    border-left: 3px solid #3273de!important;
	font-weight: bold;
}
.main-container-section.filter-section.level-options-mobile{
	
		padding-bottom: 0;
		margin-bottom: 0;
	  
	}

.myskilljourney .main-section .my-skill-journey-container .main-container-section.my-skill-journey .custom-accordion .accordion-item{
	margin-top: 0.8rem;
}
.myskilljourney .main-section .my-skill-journey-container .main-container-section.my-skill-journey .custom-accordion .accordion-item .accordion-body{
	padding-bottom: 0;
}
