@import '../../assets/scss/variables';

@font-face {
  font-family: ubuntuFont;
  src: url('../../Ubuntu/Ubuntu-Regular.ttf');
}

:root {
  --bs-link-color: #2799F6 !important;
}

a {
  text-decoration: none !important;
}

.navbar.navbar-expand,
.navbar.navbar-expand-lg {
  .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
    // @media screen and ((min-width: 992px) and (max-width:1366px)) {
    // 	padding-left: 15px;
    //   padding-right: 15px;
    // }   

    .navbar-toggler {
      border: none;
      color: #fff;
      position: absolute;
      right: 0;
      //top: 1.5rem;
      top: 1.5rem;

      .navbar-toggler-icon {
        width: 2em;
        height: 2em;
      }


      //@include respond(sm-deviec) {
      //  position: absolute;
      //  right: 50px;
      //  top: 10px;
      //}
      //@include respond(phone) {
      //  position: absolute;
      //  right: 0;
      //  top: 10px;
      //}
      //  @include respond(phone) {
      //    position: absolute;
      //    right: 33rem;
      //    top: 10px;
      //  }
      //  @media screen and (min-width: 481px) and (max-width: 992px) {
      //    position: absolute;
      //    right: 54.5rem;
      //    top: 10px;
      //  }
    }

    // .navbar-collapse.collapse.show {
    //   //  @include respond(sm-deviec) {
    //   //    min-width: 60vw;
    //   //  }
    //   //  @include respond(phone) {
    //   //    min-width: 50vw;
    //   //  }
    // }
  }

  .dropdown-item {
    font-size: 1.6rem;
    padding: 0 1rem 0 1rem;
    min-height: 34px;
    line-height: 24px;

    &.item-name {
      line-height: 34px;
    }

    @media screen and (min-width: 992px) and (max-width:1280px) {
      font-size: 1.4rem;
    }

    @media screen and (max-width: 991px) {
      padding: 0.5rem 1rem 0.5rem 2.5rem;
    }
  }

  padding: 0px 25px;

  .navbar-collapse#navbarScroll {
    .navbar-nav {
      &.navbar-nav-scroll {
        // transition: all 2s;

        @include respond(sm-deviec) {
          max-height: 100vh;
        }

        // .dropdown-menu.show {
        //     @include respond(sm-deviec) {
        //         min-width: 170px;
        //     }
        // }
      }

      &.navbar-two {
        .user-profile-mobile.nav-item.dropdown {
          @include respond(sm-deviec) {
            position: absolute;
            left: 80px;
          }

          .dropdown-toggle {
            @include respond(sm-deviec) {
              &::after {
                content: url(../../assets/images/down_arrow.svg);
                position: unset;
              }
            }
          }

          .dropdown-menu {
            position: absolute;
            top: 23px;
            left: 0;
            width: 170px;
          }
        }

        .nav-item.dropdown {
          //  margin-right: auto;
          padding-right: 1rem;
          padding-left: 1rem;
          margin: 0px auto;

          // padding: 0 !important;
          @media screen and (min-width: 992px) and (max-width:1280px) {
            padding-right: 0.785rem;
            padding-left: 0.785rem;
            margin: auto;
          }

          @media screen and (max-width: 991px) {
            width: 100%;
            min-height: 34px;
            line-height: 24px;
            padding-right: 0rem;
            padding-left: 0rem;
          }

          .dropdown-toggle {
            &::after {
              //content: url(../../assets/images/down_arrow.svg);
              border: none;
              position: absolute;
              transition: all 0.2s;
              content: "\e825";
              font-family: revicons, Adobe Clean, sans-serif;
              transform: rotate(90deg);
              font-size: 1.2rem;
              padding: 2px 4px;

              @include respond(sm-deviec) {
                //left: auto;
                rotate: 270deg;
                //top: 0.67rem;
              }

              //  @include respond(portView) {
              //     left: 20rem;
              // }
              // @include respond(tab-land) {
              //     left: 24rem;
              // }
            }

            &.show {
              &::after {
                rotate: 180deg;
                //top: 0.8rem;
                transition: all 0.2s;
                letter-spacing: 10px;
                padding: 0;
                line-height: 14px;

                @include respond(sm-deviec) {
                  // left: auto;
                  // top: 0.50rem;
                  rotate: 360deg;
                  line-height: 34px;
                }
              }
            }
          }

          &.support-engg {
            .dropdown-toggle {
              &::after {
                rotate: 270deg;
                top: 0.2rem !important;
                transition: all 0.2s;

                @include respond(sm-deviec) {
                  left: auto;
                  //top: 0.2rem !important;
                  rotate: 270deg;
                  top: 0 !important;
                  line-height: 34px;
                }
              }
            }

            .show {
              &::after {
                rotate: 270deg;
                top: 0.8rem !important;
                transition: all 0.2s;
                padding-left: 4px;

                @include respond(sm-deviec) {
                  left: auto;
                  //top: 0.2rem !important;
                  rotate: 360deg;
                  top: 0 !important;
                  line-height: 39px;
                }
              }
            }
          }

          .dropdown-menu {
            background: #F5F5F5;
            border: none;
            -webkit-box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
            border-radius: 3px !important;
            padding: 0;
            min-width: 170px;

            @media screen and (max-width: 991px) {
              -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
              box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
            }

            @include respond(phone) {
              box-shadow: none;
            }

            @include respond(phone) {
              // position: relative;
              width: 50vw;
              width: 100%;
              // left: -1rem;
            }

            .com-soon {
              background-color: #3273de;
              color: $primaryColorWhite;
              font-size: 1.3rem;
              border-radius: 25px;
              padding: 1px 4px;
              /* line-height: 13px; */
              display: inline-block;
              position: relative;
              top: -5px;
              border-bottom-left-radius: 0;
            }

            .supportMenu {
              &:hover {
                @include respond(portView) {
                  // background-color: #fff;
                }
              }
            }

            .sub-menu {
              position: relative;
              font-weight: 600;

              &::after {
                content: url(../../assets/images/down_arrow.svg);
                position: absolute;
                // right: 8px;
                rotate: -90deg;
                top: 0.4rem;
                left: 10.5rem;
              }

              .dropdown-submenu {
                position: absolute;
                // width: 100%;
                background: #fff;
                top: 0px;
                left: 100%;
                margin-left: 1rem;
                // -webkit-box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
                // -moz-box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
                // box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
                z-index: 1;

                @include respond(sm-deviec) {
                  left: 0;
                  top: 2.5rem;
                  // display: none;
                }
              }

              &.showMenu {
                @include respond(sm-deviec) {
                  //  height: 140px;

                  .dropdown-submenu {
                    display: block;
                    // position: unset;
                  }
                }
              }
            }
          }
        }

        .support-engg {
          padding: 0.5rem 1rem 0rem 0rem;

          &:hover {
            background-color: #e9ecef;

            @media screen and (max-width: 991px) {
              background-color: transparent;
            }

            @include respond(phone) {
              background-color: transparent;
            }
          }

          &:active,
          &:focus {
            background-color: #e9ecef;

            @media screen and (max-width: 991px) {
              background-color: transparent !important;
            }

            @include respond(phone) {
              background-color: transparent;
            }
          }

          .nav-link {
            font-size: 1.6rem;
            padding: 0;

            @media screen and (min-width: 992px) and (max-width:1280px) {
              font-size: 1.4rem;
            }

            &:hover {
              border-bottom-color: transparent !important;

            }

            &:active {
              background-color: #fff;
            }

            &::after {
              rotate: 270deg;
              top: 0.2rem;
            }

            &.show {
              &::after {
                rotate: 270deg;
                top: 0;
              }
            }
          }

          .dropdown-menu {
            &.show {
              left: 100%;
              top: -1px;
              border-radius: 0;
              padding-top: 0;

              @media screen and (max-width: 991px) {
                left: 0;
              }
            }
          }

          &.left-menu {
            .dropdown-menu {
              &.show {
                left: auto;
                right: 100%;
                top: 0;
                border-radius: 0;
                padding-top: 0;

                &[aria-expanded=true] {
                  padding-left: 15px;
                  padding-right: 0;
                }

                @media screen and (max-width: 991px) {
                  left: 0;
                  right: auto;
                }
              }

            }
          }
        }

        //.support-engg {
        //  .dropdown-menu {
        //    position: absolute;
        //    left: 100%;
        //    top: 0;
        //    border-radius: 0;
        //    margin-left: 0rem;
        //  }
        //  &.nav-item.dropdown {
        //    .dropdown-toggle {
        //      &::after {
        //        rotate: 270deg;
        //      }
        //    }
        //  }
        //}

        :not(#resources):not(#skills):not(#onboarding).nav-link {
          //    margin-right: auto;
          position: relative;
          //padding:0.2rem 1rem;
          padding: 0.2rem 2rem 0.2rem 0rem;
          text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;

          @media screen and (max-width: 991px) {
            padding: 0.5rem 1.5rem;
            padding: 0.5rem 2.5rem 0.5rem 1.5rem;
          }

          &.desktop-home {
            padding-right: 0;

            @media screen and (max-width: 991px) {
              border-top: 1px solid #e8e8e8;
              padding-top: 1.5rem;
              margin-top: 1.5rem;
              width: 100%;
            }
          }

          &.active {
            //font-weight: bold;
            // font-size: 1.5rem;
            border-bottom: 3px solid $primaryColorRed;

            @media screen and (max-width: 991px) {
              border-bottom: 0;
            }

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid $primaryColorRed;
              position: absolute;
              bottom: -6px;
              left: 50%;
              transform: translate(-50%, -50%);

              @include respond(sm-deviec) {
                display: none;
              }
            }
          }

          &:hover {
            //font-weight: bold;
            // text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;
            // border-bottom: 3px solid $primaryColorRed;
            text-shadow: none !important;
            color: #3273DE;

            @media screen and (max-width: 991px) {
              border-bottom: none;
            }
          }
        }

        .allcourses {
          @media screen and (max-width: 991px) {
            margin-top: 3rem;
          }
        }

        .user-profile-pic {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
      }

      .nav-link {
        font-size: 1.7rem;
        border-bottom: 3px solid transparent;
        color: $primaryColorBlack;
        font-weight: normal;
        padding: 0.2rem 2rem 0.2rem 0rem;
        line-height: 22px;

        @media screen and (min-width: 992px) and (max-width:1280px) {
          font-size: 1.4rem;
        }

        @media screen and (max-width: 991px) {
          padding: 0.5rem 1.5rem;
        }

        &.right-bar {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 8px;
            width: 1.5px;
            height: 20px;
            background-color: #dadada;
          }
        }

        &:hover {
          //  font-weight: bold;
          border-bottom: 3px solid $primaryColorRed;
          text-shadow: 0 0 0.65px #2c2c2c, 0 0 0.65px #2c2c2c;

          @media screen and (max-width: 991px) {
            border-bottom: none;
            //background: #e9ecef;
            background: #3273DE;
            color: #fff !important;
            width: 100%;
          }
        }

        @media screen and (max-width: 991px) {
          border-bottom: none;
        }
      }
    }
  }

  .navbar-nav {
    width: 100%;

    @media screen and (max-width: 991px) {
      width: auto;
    }

    // margin-right: -7px;
    // justify-content: space-evenly;
    &.navbar-one {
      justify-content: end;
      align-items: center;

      //  @include respond(sm-deviec) {
      //    padding-right: 70px;
      //  }

      .search-input-nav {
        position: relative;

        .nav-search-icon {
          height: 14px;
          position: absolute;
          bottom: -6px;
          border-bottom: 0;
          cursor: pointer;
          right: 7px;

          &.hideGray {
            display: none;
          }
        }

        input[type='search']::-webkit-search-decoration,
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-results-button,
        input[type='search']::-webkit-search-results-decoration {
          display: none;
        }

        .search-input {
          padding: 5px 10px;
          font-size: 1.5rem;
          width: 30rem;
          border: 1px solid #bcbcbc;
          outline: none;
          border-radius: 50px;
          // background-image: url('/src/assets/images/search-filter.svg');
          background-repeat: no-repeat;
          background-position: 2%;
          padding-left: 3.5rem;
        }

        .searchIcon {
          @media screen and (max-width: 991px) and (orientation: landscape) {
            height: 18px;
            position: absolute;
            bottom: -10px;
            border-bottom: 0;
          }

          &.white {
            position: absolute;
            left: auto;
            right: 2%;
            background: #2799f6;
            padding: 5px;
            border-radius: 50px;
            top: 13%;
            height: 25px;
            cursor: pointer;
          }

          &.filter {
            position: absolute;
            top: 26%;
            left: 3%;
            height: 16px;
            background-color: white;
            display: grid;
            place-items: center;
            border: 0;
            cursor: pointer;
          }

          &.gray {
            margin-bottom: 5px;
            height: 14px;
            position: absolute;
            bottom: -13px;
            border-bottom: 0;
            cursor: pointer;
            right: 7px;

            //@include respond(sm-deviec) {
            //  display: none;
            //}
            @media screen and (max-width: 991px) {
              margin-bottom: 2px;
            }

            &.hideGray {
              display: none;

              @media screen and (max-width: 991px) {
                // height: 18px;
                position: absolute;
                bottom: -10px;
                right: 5px;
                border-bottom: none;
              }

              @include respond(sm-deviec) {
                display: block;
                margin-bottom: 0;
              }
            }
          }
        }

        .searchDiv {
          transition: all 2s;
          display: none;
          position: relative;

          &.showSearchDiv {
            display: block;

            @include respond(sm-deviec) {
              display: none;
            }
          }
        }

        .searchResult {
          display: none;
          position: absolute;
          width: 30rem;
          background: white;
          z-index: 9999;
          // border: 1px solid gray;
          padding: 5px 15px;

          li {
            list-style: none;
          }
        }

        .showSearchDiv {
          .searchResult {
            display: block;
          }
        }
      }

      .user-profile-pic {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        margin-right: 1rem;
        object-fit: cover;
        background: #ccc;
      }

      .nav-item.dropdown {
        .dropdown-toggle {
          &:hover {
            color: #3273DE;
          }

          &::after {
            content: url(../../assets/images/down_arrow.svg);
            border: none;
            position: absolute;
            right: 0;
          }
        }

        .dropdown-menu {
          .dropdown-item {
            font-size: 1.5rem;

            &:hover {
              background: #3273DE !important;
              color: #fff !important;
              text-shadow: none !important;
              transition: none;

              img {
                //filter: invert(200) sepia(145) saturate(2) hue-rotate(160deg);
                //filter: invert(2) sepia(1) saturate(5) hue-rotate(175deg);
                filter: grayscale(100%) brightness(220%) sepia(0%) hue-rotate(-50deg) saturate(100%) contrast(1);
              }
            }
          }
        }
      }

      .nav-link {
        color: $ColorDarkGray;
      }
    }

    .nav-link {
      font-size: 1.5rem;
      border-bottom: 3px solid transparent;
      color: $primaryColorBlack;
      font-weight: normal;

      &.right-bar {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 8px;
          width: 1.5px;
          height: 20px;
          background-color: #dadada;
        }
      }

      @media screen and (max-width: 991px) {
        border-bottom: none;
      }
    }

    .my-team-nav {
      .dropdown-menu {
        z-index: 9999;
        left: -26px;
        padding: 0;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3019607843);
      }
    }

    .dropdown-menu {
      z-index: 9999;
      //  left: -135px;
    }

    .profile-menu {
      .dropdown-menu {
        z-index: 9999;
        right: 0;
        left: unset;
        top: 4rem;

        @include respond(sm-deviec) {
          position: absolute;
          right: 0;
          top: 4rem;
        }
      }
    }
  }
}

.profileNavMenu {
  filter: brightness(50%);
}

.manageTemplateIcon {
  height: 23px;
}

.navbar.navbar-expand-lg.navbar-light {
  position: relative;

  @media screen and (max-width: 991px) {
    position: absolute;

    &.menuOpen {
      width: 100%;
      max-height: 50rem;
      background: #fff;
      overflow-y: scroll;
      transition: all 1s;
      box-shadow: -3px 8px 16px whitesmoke;

      .container-fluid {
        padding-left: 0;
        padding-right: 0;

        .navbar-toggler {
          top: 0.5rem;
        }

        .nav-mobile {
          margin-top: 2.5rem !important;
        }

        .desktop-home {
          @media screen and (max-width: 991px) {
            border-top: 1px solid #e8e8e8;
            padding-top: 1.5rem;
            margin-top: 1.5rem;
            width: 100%;
          }
        }
      }

      .collapse {
        &.collapsing {
          display: none;
        }
      }
    }
  }
}

.navbar-light.bg-light {
  background-color: $primaryColorWhite !important;
  padding: 0.5rem;

  .navbar-brand {
    @include respond(sm-deviec) {
      margin-left: 4rem;
    }
  }
}

// .navbar-footer.navbar.navbar-expand.navbar-light.bg-light.fixed-bottom {
//   padding: 0px;
//   background-color: #f5f5f5 !important;
// }

.navbar-nav .nav-link.active {
  //  font-weight: bold;
  border-bottom: 3px solid $primaryColorRed;
  position: relative;
}

.user-bg {
  background-color: #000000;
  border-radius: 6px;
  padding: 15px 5px;
  width: 99%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  img {
    border: 5px solid $primaryColorWhite;
    border-radius: 50%;
    background-color: $primaryColorWhite;
    height: 100px;
    width: 100px;
  }

  .username {
    font-size: 2.3rem;
    color: $primaryColorWhite;

    .designation {
      font-size: 1.7rem;
      color: #a7a7a7;
    }

    .seprator {
      color: #6b6b6b;
    }
  }

  .address {
    font-size: 1.4rem;
    color: #f5f5f5;

    .more {
      color: #2799f6;
      font-size: 1.5rem;
      font-weight: bold;
      border: none;
      background: none;
    }
  }

  .approved-skill {
    font-size: 1.4rem;
    color: #a7a7a7;
  }
}

button.btn.full-btn {
  width: 100%;
  background: $primaryColorRed;
  color: $primaryColorWhite;
  border-radius: 25px;
  font-weight: bold;
  font-size: 2.1rem;
  margin-bottom: 20px;
  border: none;
}

.calendar-block {
  background-color: $primaryColorWhite;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 15px 15px 0 15px;
  min-height: 648px;

  @media screen and (max-width:768px) {
    min-height: 448px;
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .enroll-date {
    color: #2c2c2c;
    font-size: 1.7rem;
    line-height: 25px;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 5px;
  }

  .event-enroll-card {
    overflow-y: auto;
    max-height: 297px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      -webkit-border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(100, 100, 100, 0.8);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    .enroll-list {
      border-bottom: 1px solid rgba(204, 204, 204, 0.6);
      padding: 15px 0px;

      &:last-child {
        border-bottom: 0px solid rgba(204, 204, 204, 0.6);
        padding-bottom: 0px;
      }

      .event-sm {
        .event-time {
          font-size: 1.4rem;
          color: #6b6b6b;

          .enrollStatus {
            color: #009c3b;
            font-size: 1.5rem;
          }
        }

        .event-head {
          font-size: 1.5rem;
          color: #2c2c2c;
          font-weight: bold;
          line-height: normal;
        }

        .event-subhead {
          font-size: 1.5rem;
          color: #6b6b6b;
          font-weight: bold;
          //  padding-top: 5px;
          line-height: normal;
        }

        .event-btn {
          margin-top: 8px;
          padding: 4px 10px;
        }
      }
    }

    .enroll-list {
      border-bottom: 1px solid rgba(204, 204, 204, 0.6);
      padding: 15px 0px;

      &:last-child {
        border-bottom: 0px solid rgba(204, 204, 204, 0.6);
        padding-bottom: 0px;
      }

      .event-sm {
        .event-time {
          font-size: 1.4rem;
          color: #6b6b6b;

          .enrollStatus {
            // color: #009c3b;
            background: #009c3b;
            color: #fff;
            border-radius: 13px;
            font-size: 1.3rem;
            padding: 3px 12px 4px !important;
            line-height: normal;
          }
        }

        .event-head {
          font-size: 1.5rem;
          color: #2c2c2c;
          font-weight: bold;
          line-height: normal;
        }

        .event-subhead {
          font-size: 1.5rem;
          color: #6b6b6b;
          font-weight: normal;
          //  padding-top: 5px;
          line-height: normal;
        }

        .event-btn {
          margin-top: 20px;
          padding: 4px 10px;
        }
      }
    }
  }

  .react-calendar {
    width: 90%;
    margin: 0 auto;
    border: none;
  }

  .react-calendar__tile--active {
    background-color: #3273de;

    abbr {
      color: #fff;
    }
  }

  .cal-head {
    font-size: 2.5rem;
    font-weight: bold;
    color: $primaryColorBlack;
  }

  .enroll-list {
    margin-left: 10px;

    .all-list {
      height: 220px;
      overflow-y: auto;

      .enroll-head {
        font-weight: 600;
      }
    }

    .enroll-date {
      font-size: 2.1rem;
      font-weight: 600;
    }
  }
}


.react-calendar {
  .react-calendar__viewContainer {
    button {
      border-radius: 50%;
      padding: 0px;
      height: 30px;
      width: 14.28%;
      margin: auto;
      flex: none !important;
      //color: #7D7D7D;

      abbr {
        color: #7d7d7d;
      }

      @include respond(sm-deviec) {
        border-radius: 50%;
      }
    }
  }
}

.react-calendar {
  .react-calendar__viewContainer {
    button {
      &.react-calendar__tile--active {
        abbr {
          color: #fff;
        }
      }
    }
  }
}

.btn.primary-btn {
  border: 1.5px solid $primaryColorBlack;
  color: $primaryColorBlack;
  font-weight: 600;
  border-radius: 25px;
  padding: 5px 20px;
  font-size: 1.5rem;
  background-color: #fff;

  // text-transform: capitalize;
  label {
    cursor: pointer;
  }

  &.primary-blue {
    color: #fff;
    border: 1.5px solid #3273DE;
    background-color: #3273DE;

    &:hover {
      color: #fff;
      background-color: #0057be;
      border: 1.5px solid #0057be;
    }
  }

  &:hover {
    // border: 1.5px solid $primaryColorBlack;
    // color: $primaryColorBlack;
    // background-color: #e9ecef;
    color: #fff;
    background-color: #3273DE;
  }
}

.btn.primary-btn {
  &.learner {
    color: #000000;
    border: 1.5px solid #000000;
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: #3273DE;
      border: 1.5px solid #3273DE;
    }
  }

  &:hover {
    // border: 1.5px solid $primaryColorBlack;
    // color: $primaryColorBlack;
    // background-color: #e9ecef;
    color: #fff;
    background-color: #3273DE;
  }
}

.view-skill-journey-btn.btn.primary-btn {
  border: 1px solid #2c2c2c !important;
  white-space: nowrap;
  color: #2c2c2c;
  font-weight: 600;
  border-radius: 25px;
  padding: 5px 20px !important;
  font-size: 1.5rem;

  // text-transform: capitalize;
  &:hover {
    // border: 1.5px solid $primaryColorBlack;
    // color: $primaryColorBlack;
    // background-color: #e9ecef;
    color: #fff;
    background-color: #0057be;
    border: 1px solid #0057be !important;
  }
}

.main-head {
  font-size: 2.5rem;
  font-weight: bold;
  color: $primaryColorBlack;
}

.Carousel-block {
  padding: 0 !important;
  background-color: $primaryColorWhite;
  padding-bottom: 0;

  .btn-view {
    font-size: 1.5rem;
    color: #2799f6;
    font-weight: 600;

    &:hover {
      color: #2799f6;
    }
  }

  .title-block {
    padding-bottom: 20px;

    .view-all {
      float: right;
    }
  }
}

.react-multiple-carousel__arrow:disabled {
  cursor: default;
  background: #f5f5f5;
  pointer-events: none;
}

.headerImage {
  background-image: url('/src/assets/images/fullHeaderImageImportCSV.png');
  min-height: 11.2rem;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;
  position: relative;
  // overflow: hidden;
  opacity: 1;
  background-size: cover;
  border-radius: 5px;
  overflow: visible;

  h1 {
    padding: 0 15px;
    font-size: 2.8rem;
    color: $primaryColorWhite;
    font-family: ubuntuFont, Adobe Clean, sans-serif;

    @media screen and (max-width: 767px) {
      font-size: 2.1rem;
    }
  }

  @include respond(tab-port2) {
    background-image: url('/src/assets/images/mobileImage.png');
  }
}

.sub-menu-list {
  .sub-menu {
    font-size: 1.4rem;
    color: $primaryColorBlack;

    .sub-page {
      color: #6b6b6b;
    }
  }

  .description-block {
    img {
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}

.react-multi-carousel-dot-list {
  @include respond(sm-deviec) {
    display: none;
  }
}

.mobileSearchDiv {
  position: absolute;
  display: none;
  transition: all 1s;
  background-color: #fff;
  width: 100%;

  &.showMobileSearchDiv {
    display: none;

    @include respond(sm-deviec) {
      display: block;
      margin-right: 1rem;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .search-input {
      padding: 5px 10px 5px 35px;
      font-size: 1.5rem;
      width: 98%;
      border: 1px solid #2799f6;
      outline: none;
      border-radius: 50px;
    }

    .searchIcon {
      top: 1rem;
      left: 4rem;
      height: auto;
    }

    .nav-search-icon {
      &.white {
        position: absolute;
        right: 5.2rem;
        background: #2799f6;
        padding: 5px;
        border-radius: 50px;
        top: 0.5rem;

        @media screen and (max-width:769px) {
          right: 4.7rem;
        }

        @media screen and (max-width:400px) {
          right: 4.3rem;
        }
      }

      &.gray {
        margin-bottom: 5px;
      }

      .mobileSearchIcon {
        display: none;

        @include respond(sm-deviec) {
          display: block;
        }
      }
    }
  }

  .mobileSearchResult {
    display: none;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 9999;
    // border: 1px solid gray;
    padding: 5px 15px;

    li {
      list-style: none;
    }
  }

  &.showMobileSearchDiv {
    .mobileSearchResult {
      display: block;
    }
  }

  .searchResult {
    list-style: none;
    padding-left: 10px;
    padding-bottom: 5px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3019607843);

    p {
      margin: 0;
      padding: 5px 0;
    }
  }
}

.breadcrumbcss {
  font-size: 1.7rem;
  background-color: #fff;
  padding: 0 0.5rem 1rem;
}

.headerLimiter {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  .seprator {
    &:last-child {
      display: none;
    }
  }
}

.role-packets {
  .seprator {
    &:last-child {
      display: none;
    }
  }
}

.morebtndashboard {
  color: #2799f6;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
  background: none;
  padding: 0;
}

.mobileProfileDashboard {
  @media screen and (max-width: 765px) {
    flex-direction: column;
  }
}

.notifyIconStyle {
  width: 25px;
}

.otherTexts {
  color: #8f8f91;
  font-size: 1.7rem;
}

.other-Text {
  font-size: 1.9rem;
}

//table-striped
.table-striped {
  thead {
    tr {
      th {
        font-size: 1.4rem;
        font-weight: 900;
        color: #6b6b6b;
        padding: 1rem;
      }
    }
  }

  tbody {
    tr td {
      background: #ffffff !important;
    }
  }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: transparent !important;
  font-size: 1.5rem;
  padding: 1rem;
}

.table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-accent-bg: transparent !important;
  font-size: 1.5rem;
  padding: 1rem;
}

.navbar-toggler[aria-expanded="true"] {
  background-color: transparent;
}


.navbar-toggler .navbar-toggler-icon {
  background-image: url('../../assets/images/closeMenuIcon.png');
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: var(--bs-navbar-toggler-icon-bg);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContentArea {
  flex: 1;
}

.head-text-1 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #3273de;

  @media screen and (max-width: 767px) and (orientation: portrait) {
    text-align: justify;
  }
}

.fontSize13 {
  font-size: 1.4rem;
}

.fontSize14 {
  font-size: 1.5rem;
}

.fontSize15 {
  font-size: 1.6rem;
}

.fontSize16 {
  font-size: 1.7rem;
}

.fontSize17 {
  font-size: 1.8rem;
}

.fontSize18 {
  font-size: 1.9rem;
}

.mt2custom {
  margin-top: 1.5rem;
}



//home-profile-info
.user-bg {
  width: 100%;
  padding: 0;

  .home-profile-info {
    display: flex;
    flex-direction: row;
    text-align: center;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    .home-profile-img-box {
      padding: 14px 5px 14px 15px;
      flex: 114px;
      align-items: center;
      vertical-align: middle;
      display: flex;
      justify-content: flex-start;

      @media (max-width: 991px) {
        justify-content: center;
      }

      .home-profile-img {
        width: 114px;
        height: 114px;

        .img-fluid {
          width: 100%;
          height: 100%;
          background: #ccc;

          @media (max-width: 991px) {
            width: 114px;
          }
        }
      }
    }

    .home-profile-short-info {
      padding: 30px 15px 20px 15px;
      flex: 91%;
      width: 91%;

      @media (max-width: 991px) {
        width: 100%;
        flex: 100%;
      }
    }
  }
}

//home-user-info
.dashboard-user-info {
  display: flex;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .left-info {
    flex: 80%;
    width: 80%;
    padding-right: 20px;

    @media (min-width: 992px) and (max-width: 1280px) {
      flex: 70%;
      width: 70%;
    }

    @media (max-width: 991px) {
      width: 100%;
      flex: 100%;
      padding-right: 0px;
    }
  }

  .right-info {
    flex: 245px;
    margin-bottom: 3rem;

  }
}

//Header Nav
.sticky-top {
  .navbar-light.bg-light {
    padding: 0;

    .container-fluid {
      padding: 12px 30px;

      .navbar-brand {
        padding: 0;
      }
    }
  }
}

//common-sections-top-spce
.common-sections-top-spce {
  margin-top: 30px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #3273DE !important;
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}

.stickyBoxShadow {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.scrollBottom {
  img {
    cursor: pointer;
    width: 30px;
    position: absolute;
    right: 10px;
    z-index: 999;
    top: 123px;
  }
}

.scrollTop {
  img {
    cursor: pointer;
    width: 35px;
    position: fixed;
    right: 18px;
    z-index: 99;
    bottom: 100px;
    outline: 4px solid #fff;
    background: #fff;
    border-radius: 50%;
    box-shadow: 6px 6px 6px #d3d3d3;
  }
}

.modal-dialog .modal-footer {
  justify-content: center;

  .btn-link {
    font-size: 1.4rem !important;
    text-decoration: none !important;
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #fff !important;
  color: #0057be !important;
}

.dropdown-item .nav-link:hover,
.dropdown-item .item-name:hover {
  text-shadow: none !important;
}

.dropdown-item .nav-link:hover,
.dropdown-item.item-name:hover {
  text-shadow: none !important;
}

.dropdown-menu.show .item-name.dropdown-item {
  white-space: break-spaces;
  display: flex;
  align-items: center;
  line-height: 1.4;
  padding-top: 3px;
  padding-bottom: 3px;
}

// .nav-item.show.support-engg.supportMenu{
//   position: static;
// }
// .navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .nav-item.dropdown .dropdown-menu  :not(.dropdown-show-second)
.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .nav-item.dropdown.show {
  .nav-link[aria-expanded=true] {
    //font-weight: bold;
    // font-size: 1.5rem;
    border-bottom: 3px solid $primaryColorRed;

    @media screen and (max-width: 991px) {
      border-bottom: 0;
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $primaryColorRed;
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translate(-50%, -50%);

      @include respond(sm-deviec) {
        display: none;
      }

    }
  }
}

.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .nav-item.dropdown.show.dropdown-show-second {
  .nav-link[aria-expanded=true] {
    &::before {
      display: none;
    }

    border: none;
  }
}

// .navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .nav-item.dropdown.show.dropdown-show-second:nth-child(10){
//   .nav-link[aria-expanded=true]{
//     left: auto;
//     right: 100%;
//   }
// }

.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .menu-list.nav-item.dropdown [aria-labelledby=menu-3]{
  .supportMenu.support-engg.dropdown-item.dropdown-show-second .dropdown-menu{
    left: auto;
    right: 100%;
  }
}
.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .menu-list.nav-item.dropdown [aria-labelledby=menu-4]{
  .supportMenu.support-engg.dropdown-item.dropdown-show-second .dropdown-menu{
    left: auto;
    right: 100%;
  }
}
.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .menu-list.nav-item.dropdown [aria-labelledby=menu-5]{
  .supportMenu.support-engg.dropdown-item.dropdown-show-second .dropdown-menu{
    left: auto;
    right: 100%;
  }
}
.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .menu-list.nav-item.dropdown:last-child{
  .supportMenu.support-engg.dropdown-item.dropdown-show-second .dropdown-menu{
    left: auto;
    right: 100%;
  }
}

.dateCss {
  min-height: 2rem;
  white-space: nowrap;

  .c-date {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two {
  .nav-item.dropdown {
    [aria-labelledby=menu-6] {
      .supportMenu.support-engg {
        .dropdown-toggle.nav-link {
          // padding-left: 15px !important;
          padding-right: 0 !important;

          // &::after{
          //   rotate: 90deg;
          //   top: 0rem !important;
          //   left: -4px;
          // }
        }

        .dropdown-toggle.nav-link.show {
          // &::after{
          //   rotate: 90deg;
          //   top: 0.6rem !important;
          //   left: -9.2px;

          // }
        }

        .dropdown-menu {
          &.show {
            left: auto;
            right: 100%;
            top: 0;
            border-radius: 0;
            padding-top: 0;


            @media screen and (max-width: 991px) {
              left: 0;
              right: auto;
            }
          }

        }
      }
    }
  }
}

.react-calendar {
  .react-calendar__viewContainer {
    button.react-calendar__tile.react-calendar__year-view__months__month {
      border-radius: 10%;
      height: 30px;
      width: 33.28%;

      abbr {

        &:hover,
        &:focus {
          background: #ccc;
          border-radius: 10%;
          width: 100%;
          height: 100%;
          line-height: 27px;
          display: inline-block;
        }
      }

    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
      abbr {
        color: #fff !important;
        background: #3273de !important;
        font-weight: 400;
        border-radius: 10%;
        width: 100%;
        height: 100%;
        line-height: 27px;
        padding: 0 5px;

      }
    }

    button.react-calendar__tile.react-calendar__decade-view__years__year,
    button.react-calendar__tile.react-calendar__century-view__decades__decade {
      width: 20% !important;
    }

  }
}

.emailTemplate {
  &.container {
    @media (min-width: 767px) {
      max-width: 870px;
    }
  }

  .newTemplate {
    position: relative;

    .adobeImage {
      height: 13rem;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logo-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      border-radius: 5px;
      font-weight: bold;
      white-space: nowrap;
    }

    .contentPart {
      background-color: #fff;
      padding: 10px;
      font-size: 1.9rem;

      p {
        margin-bottom: 2rem;
      }

      // text-align: unset;
    }
  }
}

.paginationAlign {
  min-height: 6rem;
  align-items: center;
}

.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__year-view__months__month {
  border-radius: 0%;
  height: 30px;
  width: 33.28%;
  font-size: 1.3rem;
}

.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__year-view__months__month abbr:hover,
.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__year-view__months__month abbr:focus {
  background: #D1E2FF;
  border-radius: 0%;
  width: 100%;
  height: 100%;
  line-height: 27px;
  display: inline-block;
}

.react-calendar .react-calendar__viewContainer .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month abbr {
  color: #fff !important;
  background: #3273de !important;
  font-weight: 400;
  border-radius: 0%;
  width: 100%;
  height: 100%;
  line-height: 27px;
  padding: 0 5px;
}

.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__decade-view__years__year,
.react-calendar .react-calendar__viewContainer button.react-calendar__tile.react-calendar__century-view__decades__decade {
  width: 20% !important;
  border-radius: 0;
}



.feedbackForm1 {
  min-height: 4rem !important;
  font-size: 1.4rem !important;
}

.textarea.form-control.feedbackForm1 {
  min-height: 4rem !important;
}

.modalFeedbackContent {
  .modal-dialog {
    max-width: 60rem;

    .modal-content {
      .modal-header {
        padding-left: 1.5rem;

        .feedbackHeader {
          font-size: 2.1rem;
          font-weight: bold;
        }
      }

      .modal-body {
        padding: 2rem;

        .form-check {
          font-size: 1.5rem;
        }

        .question-text {
          font-size: 1.7rem;
          font-weight: bold;
        }

        h5 {
          font-size: 1.9rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

}

.roleSkillSelectALL {
  white-space: nowrap;
  margin-right: 1.5rem;
}

.urlContent {
  word-break: break-word;
}

.detailed {
  position: relative;
  padding-left: 5rem;

  .locator {
    position: absolute;
    left: 1.5rem;
    top: -2.5px;
  }

  .urlContent {
    word-break: break-word;

  }
}

.homeTitle {
  margin-bottom: 7rem;
}


//notifications-container
.notifications-container {
  position: relative;
  border: 1px solid #ECECED;
  font-size: 1.7rem;
  width: 100%;
  padding: 0;
}

.notifications-list {
  display: flex;
  padding: 18px 30px;
  flex-flow: nowrap;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    border-top: 1px solid #ECECED;
    display: block;
    margin: 0 auto;
    top: 0;
    left: 30px;
    right: 30px;
    width: -webkit-fill-available;
  }

  &:first-child {
    &::before {
      content: "";
      position: absolute;
      border-top: 0px solid #ECECED;
      display: block;
      top: 0;
      width: -webkit-fill-available;
    }
  }

  &:hover {
    background: #F6F5FF;
  }

  .notifications-icon {
    margin-right: 2rem;
    display: flex;
    align-items: flex-start;
    position: relative;
    top: 4px;

    .icon {
      height: 16px;
    }
  }

  .notifications-details {
    display: flex;

    @media (max-width: 767px) {
      flex-flow: wrap;
    }

    .assignTime {
      display: inline-flex;
      font-size: 1.4rem;
      color: #707070;
      min-width: 165px;
      padding-right: 2rem;
      line-height: normal;
      padding-top: 2px;
    }

    .assigned {
      display: inline-flex;
      font-size: 1.5rem;
      color: #2C2C2C;
      line-height: normal;

      a {
        color: #2799F6;
        text-decoration: none;
      }

      @media (max-width: 767px) {
        width: 100%;
        display: flex;
      }
    }
  }
}

//notifications-container end


//navbar dropdown start
.navbar.navbar-expand-lg .navbar-collapse#navbarScroll .navbar-nav.navbar-two .nav-item.dropdown {
  >.dropdown-menu {
    .dropdown-item {
      transition: none;

      @media (max-width: 991px) {
        .nav-link {
          padding-left: 2.5rem !important;
        }
      }

      &:hover {
        background: #3273DE !important;
        color: #fff !important;
        text-shadow: none !important;
        transition: none;
      }

      &:focus {
        background: #3273DE !important;
        color: #fff !important;
        text-shadow: none !important;
        transition: none;
      }
    }

    .supportMenu.dropdown-show-second {
      &:hover {
        background: #3273DE !important;
        color: #fff !important;
        text-shadow: none !important;
        transition: none;

        .nav-link {
          color: #fff !important;
          background: transparent !important;
          text-shadow: none !important;
          transition: none;
        }

        >.dropdown-menu {
          .supportMenu {
            .nav-link {
              color: var(--bs-dropdown-link-color) !important;
              transition: none;

              @media (max-width: 991px) {
                padding-left: 2.5rem !important;
              }

              &:hover {
                color: #fff !important;
                background: transparent !important;
                text-shadow: none !important;
                transition: none;
              }
            }

            &:hover {
              color: #fff !important;
              background: #3273DE !important;
              transition: none;
            }
          }
        }
      }

      &:focus {
        transition: none;
      }

      .nav-link {
        transition: none;

        &:hover {
          transition: none;
        }

        &:hover,
        &:focus {
          transition: none;
        }
      }

      >.dropdown-menu {
        @media (max-width: 991px) {
          .dropdown-item {
            .dropdown-menu .show {
              .dropdown-item {
                padding-left: 3.5rem;
              }
            }
          }
        }

        .supportMenu.dropdown-show-second {
          transition: none;

          &:hover {
            background: #3273DE !important;
            color: #fff !important;
            text-shadow: none !important;
            transition: none;

            .nav-link {
              color: #fff !important;
              background: transparent !important;
              text-shadow: none !important;
              transition: none;
            }

            >.dropdown-menu {
              .supportMenu.dropdown-show-second {
                .nav-link {
                  color: var(--bs-dropdown-link-color) !important;
                  transition: none;
                  background: transparent !important;
                  text-shadow: none !important;
                  transition: none;

                  &:hover {
                    color: #fff !important;
                    transition: none;
                  }
                }

                &:hover, &:focus-within, &:focus {
                  color: #fff !important;
                  background: #3273DE !important;
                  transition: none;
                  .nav-link {
                    color: #fff !important;
                    transition: none;
                    &:hover {
                      color: #fff !important;
                      transition: none;
                      background: transparent !important;
                      text-shadow: none !important;
                    }
                  }
                  >.dropdown-menu {
                    .supportMenu.dropdown-show-second {
                      .nav-link {
                        color: var(--bs-dropdown-link-color) !important;
                        transition: none;
                        background: transparent !important;
                        text-shadow: none !important;
                        transition: none;
      
                        &:hover {
                          color: #fff !important;
                          transition: none;
                        }
                      }
      
                      &:hover, &:focus-within, &:focus {
                        color: #fff !important;
                        background: #3273DE !important;
                        transition: none;
                        .nav-link {
                          color: #fff !important;
                          transition: none;
                          &:hover {
                            color: #fff !important;
                            transition: none;
                            background: transparent !important;
                            text-shadow: none !important;
                          }
                        }
                        >.dropdown-menu {
                          .supportMenu.dropdown-show-second {
                            .nav-link {
                              color: var(--bs-dropdown-link-color) !important;
                              transition: none;
                              background: transparent !important;
                              text-shadow: none !important;
                              transition: none;
            
                              &:hover {
                                color: #fff !important;
                                transition: none;
                              }
                            }
            
                            &:hover, &:focus-within, &:focus {
                              color: #fff !important;
                              background: #3273DE !important;
                              transition: none;
                              .nav-link {
                                color: #fff !important;
                                transition: none;
                                &:hover {
                                  color: #fff !important;
                                  transition: none;
                                  background: transparent !important;
                                  text-shadow: none !important;
                                }
                              }
                              >.dropdown-menu {
                                .supportMenu.dropdown-show-second {
                                  .nav-link {
                                    color: var(--bs-dropdown-link-color) !important;
                                    transition: none;
                                    background: transparent !important;
                                    text-shadow: none !important;
                                    transition: none;
                  
                                    &:hover {
                                      color: #fff !important;
                                      transition: none;
                                    }
                                  }
                  
                                  &:hover, &:focus-within, &:focus {
                                    color: #fff !important;
                                    background: #3273DE !important;
                                    transition: none;
                                    .nav-link {
                                      color: #fff !important;
                                      transition: none;
                                      &:hover {
                                        color: #fff !important;
                                        transition: none;
                                        background: transparent !important;
                                        text-shadow: none !important;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:focus {
            transition: none;
          }
        }
      }

    }
  }
}

//navbar dropdown end

.labels-group {
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;

  @media screen and (max-width:498px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width:899px) and (min-width:600px) {
    top: 2px;
  }

  .labels-item {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    padding-left: 10px;
    padding-right: 14px;
    position: relative;

    &::after {
      width: 1px;
      height: 18px;
      content: "";
      border-left: 1px solid #2C2C2C;
      display: block;
      left: 0;
      position: absolute;
    }

    &:first-child {
      &::after {
        display: none;
      }
    }

    &:nth-child(2) {
      &::after {
        display: none;
      }
    }

    &:last-child {
      padding-right: 0px;
    }

    .text {
      color: #2C2C2C;
      font-size: 1.5rem;
      font-weight: bold;

      .sub {
        color: #6B6B6B;
        font-weight: normal;
        padding-left: 5px;
      }
    }
  }

  .info-icon {
    position: relative;
    top: -3px;
    margin-right: 4px;
    filter: grayscale(200%) brightness(84%) sepia(0%) hue-rotate(-92deg) saturate(100%) contrast(1);
  }
}



.circled {
  width: 12px;
  height: 12px;
  background: #000000;
  border-radius: 50%;
  margin-right: 8px;

  &.beginner {
    background: #e63888;
  }

  &.intermediate {
    background: #FFCE2E;
  }

  &.expert {
    background: #009C3B;
  }
}

.label-count {
  display: flex;
  align-items: center;
  justify-content: center;

}

.level-options-device {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width:1423px) {
    margin-right: auto;
    margin-left: 0;
  }
}

.level-options-device-myskill {
  @media screen and (max-width:1537px) {
    margin-right: auto;
    margin-left: 0;
  }
}

.level-options-device-skillssub {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width:1746px) {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

.search-bar-skillssub {
  margin-top: 3rem !important;

  @media screen and (max-width:1746px) {
    // align-self: flex-start !important;   
    margin-top: 0 !important;
  }
}

.no-content-border {
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin-top: 2rem !important;
  height: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-content-noborder {
  .no-content-border {
    border: none;
    padding: 0;
    margin-top: 0 !important;
    height: auto;
  }
}

.sort-minHeight {
  min-height: 6.8rem;
}

.all-courses-info {
  .left-info {
    .sidebarOther {
      .scrolling {
        .scroller {
          .form-check {
            .form-check-input {
              margin-left: -1.1em;
              margin-top: 1.7px;
            }
          }
        }
      }
    }

  }
}

.capsWord {
  text-transform: capitalize;
}


//filterby new changes
.filterby-top-btn {
  padding-right: 2rem;

  .filterby-btn {
    &:hover {
      .tooltip-box {
        display: block !important;
      }
    }
  }

  .tooltip-box {
    position: absolute;
    top: -35px;
    min-width: 115px;
    padding: 4px 8px;
    left: 0%;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
    border: 1px solid rgb(167, 167, 167);
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.btn.filterby-btn {
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 6px 14px;

  @media (max-width: 767px) {
    display: none;
  }

  .label {
    font-size: 17px;
    line-height: 20px;
  }

  .filterby-icon {
    margin-left: 15px;
    width: 18px;
    height: 16px;
    position: relative;
    top: -3px;
  }

  &:hover {
    .filterby-icon {
      filter: grayscale(100%) brightness(500%) sepia(0%) hue-rotate(-50deg) saturate(100%) contrast(2);
    }
  }

  &:focus {
    .filterby-icon {
      filter: none
    }
  }
}

.btn-link.filterby-collpase {
  display: contents;
  padding-right: 0px;

  &:hover {
    .tooltip-box2 {
      display: block !important;
    }
  }

  .tooltip-box2 {
    position: absolute;
    top: -21px;
    min-width: 115px;
    padding: 4px 8px;
    right: -17%;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
    border: 1px solid rgb(167, 167, 167);
  }

  .filterby-collpase-icon {
    width: 30px;
    height: 18px;
    position: relative;

    &:hover {
      filter: brightness(0) saturate(100%) invert(42%) sepia(23%) saturate(2403%) hue-rotate(183deg) brightness(95%) contrast(96%);
      ;
    }

    //top: -1px;
    padding-left: 10px;
    border-left: 1px solid #ccc;
    margin-left: 8px;

    @media (max-width: 1024px) {
      padding-left: 6px;
      width: 20px;
    }
  }
}

.btn-link.reset-filter {
  font-size: 16px;
  padding-left: 0;
  padding-top: 0;
  font-weight: bold;
  margin-bottom: 0.785rem;
}

.search-filterby-titlehead {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.filterby-status {
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex: none;
    display: block;
  }
}

//filterby new changes


//coursesgrid-container
.coursesgrid-container {
  display: grid;

  .all-courses {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.coursesgrid-container-fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(29.5rem, 1fr));

  &.all-courses-div {
    margin-left: 0 !important;
    width: 100%;
    display: grid !important;
    gap: 15px;
    //margin: 0;
    padding: 0 1.5rem !important;
    padding-bottom: 1.5rem !important;

    @media screen and (max-width: 376px) and (orientation: portrait) {
      grid-template-columns: repeat(auto-fill, minmax(25.5rem, 1fr));
      padding: 0 0.785rem !important;
    }
  }
}

.coursesgrid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.coursesgrid-element {
  flex: 1 0 auto !important;
  // padding: 0;    
  padding: 0 !important;
}

//coursesgrid-container end 

.font-bold {
  font-weight: bold;
}

[aria-labelledby="myTeamDropDown"].dropdown-menu.show {
  .dropdown-item {
    line-height: 34px;
  }
}

.mlm {
  margin-left: -5px;
}

.enrollment-header {
  font-weight: bold;
}

.resetbtn-border {
  .btn {
    border-width: 0;
  }
}

.tableBorder {
  border: 1px solid #e8e8e8;
}

.table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-title {
  flex-grow: 1;
  text-align: left;
  padding: 0 10px;
  /* Adjust padding as needed */
}

.table-header-sort-icon {
  margin-left: 5px;
}

.learnerReportsTables{
  table {
    tr{
      th:first-child{
        .templateColumns{
          justify-content: flex-start;
          
        }
      }
     
    }
  }
  .columnHeaders{
    @media screen and (min-width:1200px){
    padding: 10px;
    width: 8%;
    }
  }
  .columnHeaders:first-child{
   @media screen and (min-width:1200px){
    width: 12%;}
  }
  .columnHeaders:nth-child(4),.columnHeaders:nth-child(3){
     @media screen and (min-width:1200px){
    width: 12%;}
  }
  .columnHeaders:nth-child(2){
    @media screen and (min-width:1200px){
    width: 8%;}
  }
  .templateColumns{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sortIcons{
    top: 2px;
    margin-left: 3px !important;
  }
}

//MultiSelect Dropdown and Content

.rmsc {
  --rmsc-hover: #F6F5FF !important;
  --rmsc-selected: #F6F5FF !important;

  .dropdown-container {
    .dropdown-heading {
      .dropdown-heading-value {
        font-size: 1.5rem;
      }
    }
  }

}

//rmsc end

.dropdown-content {
  transition: none;

  .panel-content {
    transition: none;

    .select-panel {
      transition: none;
      color: #2c2c2c !important;
      font-size: 1.5rem;

      .select-item {
        margin: 1px 0px 0px;
        font-size: 1.5rem;
        line-height: normal;
        transition: none !important;

        span {
          width: calc(100% - 20px);
          padding-top: 3px;
          padding-bottom: 5px;
        }

        .item-renderer {
          transition: none !important;

          input[type=checkbox] {
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            background: none;
            cursor: pointer;
            line-height: 0;
            margin: 0 .6em 0 0;
            outline: 0;
            padding: 0 !important;
            vertical-align: text-top;
            height: 16px;
            width: 16px;
            -webkit-appearance: none;
            opacity: 1;
            top: 3px;

            &:hover {
              opacity: 1;
            }

            &:checked {
              background-color: #ffffff;
              opacity: 1;
              height: 16px;
              width: 16px;

              &:before {
                content: '';
                position: absolute;
                right: 50%;
                top: 50%;
                width: 4px;
                height: 10px;
                border: solid #3273DE;
                border-width: 0 1px 1px 0;
                margin: -1px -1px 0 -1px;
                transform: rotate(45deg) translate(-50%, -50%);
                z-index: 2;
              }
            }
          }

        }
      }

      .selected {
        color: #2c2c2c !important;
        transition: none !important;

        &:focus {
          color: #2c2c2c !important;
          transition: none;
        }
      }
    }
  }
}

//dropdown-content end 
//MultiSelect Dropdown and Content end
.filterEffect {
  color: #2799F6 !important;
  font-weight: 600 !important;

  &:hover {
    color: var(--bs-btn-hover-color) !important;
  }
}

//mylearning-radio-box
.mylearning-radio-box {
  display: inline-flex;
  position: relative;
  top: 4px;
  align-items: center;

  @media screen and (max-width:498px) {
    padding: 15px 0px 25px;
  }

  .radio-item {
    padding-right: 20px;
    display: inline-block;
    font-size: 1.5rem;

    input[type=radio].radioinput {
      accent-color: #3273DE;
      width: 18px;
      height: 18px;
      position: relative;
      top: 4px;
      margin-right: 5px;
    }
  }
}

.mylearning-btn-view {
  @media screen and (max-width:498px) {
    float: right;
  }
}

//mylearning-radio-box end

.dashboard-user-info {
  .left-info {
    .react-multi-carousel-list {
      margin-left: 3px;
      padding-left: 5px !important;
    }
  }
}

.popover-body-rejectioned {
  inset: -1px 75px auto auto !important;
  z-index: 8 !important;
  // min-height: 100px;
  // overflow-y: auto;
  // overflow-x: hidden;
}

.current-level-popover {
  //transform: translate(-750px, 1219px)!important;
  width: 225px !important;
  z-index: 0 !important;

  .popover-body {
    font-size: 13px !important;

    .current-level-title {
      font-weight: 700;
    }
  }
}

.container-tbl-tr-btn {
  position: relative;
}

//custom-tooltip
.custom-tooltip {
  position: absolute;
  top: -35px;
  min-width: 115px;
  width: 230px;
  padding: 4px 8px;
  left: 0%;
  color: #000000;
  background-color: #fff;
  font-size: 13px;
  font-weight: normal;
  border-radius: 5px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
  border: 1px solid rgb(167, 167, 167);
  z-index: 10;
}

.currentLevel-tooltip {
  position: relative;

  .custom-tooltip {
    position: absolute;
    top: -20px;
    min-width: 115px;
    width: 140px;
    padding: 4px 8px;
    left: 0%;
    color: #000000;
    background-color: #fff;
    font-size: 13px;
    font-weight: normal;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
    border: 1px solid rgb(167, 167, 167);
    z-index: 10;
  }
}

//custom-tooltip end

.module-details-content {
  width: 50%;
  float: left;
  left: -136px;

  @media screen and (max-width:667px) {
    width: 100%;
  }
}

//loadmore-courses
.loadmore-courses {
  width: 100%;
  margin: 0 auto;
  position: relative;
  bottom: 0;
  text-align: center;
  padding: 30px 20px 20px;
  min-height: 90px;

  .loadmore-courses-lable {
    position: relative;
    color: #2799F6;
    font-size: 1.8rem;
    font-weight: 700;
    display: block;
    width: 170px;
    text-align: center;
    margin: 0px auto;

    button {
      color: #2799F6;
      font-size: 1.8rem;
      font-weight: 700;
      background-color: transparent;
      border: 0px solid transparent;
    }

    .down-arrow {
      position: absolute;
      //bottom: calc(100vh - 80px);
      left: calc(50% + 0px);
      width: 0;
      height: 30px;
      top: 10px;
      //border: 2px solid;
      border-radius: 2px;
      animation: jumpInfinite 1.5s infinite;
    }

    .down-arrow:after {
      content: " ";
      position: absolute;
      top: 12px;
      left: -10px;
      width: 16px;
      height: 16px;
      border-bottom: 4px solid;
      border-right: 4px solid;
      border-radius: 4px;
      transform: rotateZ(45deg);
    }

    @keyframes jumpInfinite {
      0% {
        margin-top: 0;
      }

      50% {
        margin-top: 10px;
      }

      100% {
        margin-top: 0;
      }
    }
  }
}

.section-btn {
  display: inline-flex;
  background: #ffdbdb;
  color: #000000;
  padding: 3px 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%, 0 50%);
  font-size: 1.3rem;
  margin-left: 10px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
}

.notStarted-btn {
  display: inline-flex;
  background: hsl(0deg 0% 77.25%);
  color: #000000;
  padding: 3px 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%, 0 50%);
  font-size: 1.3rem;
  margin-left: 10px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  float: right;
}

.completed-btn {
  display: inline-flex;
  background: #c6eed4;
  color: #000000;
  padding: 3px 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%, 0 50%);
  font-size: 1.3rem;
  margin-left: 10px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  float: right;
}

.inprogress-btn {
  display: inline-flex;
  background: #feeebc;
  color: #000000;
  padding: 3px 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%, 0 50%);
  font-size: 1.3rem;
  margin-left: 10px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  float: right;
}

.no-margin {
  .section-btn {
    margin-left: 0px;
  }
}

.optional-btn {
  display: inline-flex;
  background: #feeebc;
  color: #000000;
  padding: 3px 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%, 0 50%);
  font-size: 1.3rem;
  margin-left: 10px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
}

//loadmore-courses End