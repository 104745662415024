// Custom Variables
// @import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);

/*Theme Colors*/
$primaryColor: var(--primaryColor);
$secondaryColor: var(--secondaryColor);
$lightblue: rgba(127, 106, 239, .4);
$secondaryLightBlue: var(--secondaryColor);
$secondaryColor-hover: var(--secondaryColor-hover);
$primaryColor-hover: var(--primaryColor-hover);
$buttonColor: var(--buttonColor);
$buttonColor-hover: var(--buttonColor-hover);
$primaryColorRed: #EB1000;
$primaryColorBlack: #2C2C2C;
$primaryColorGray: #F5F5F5;
$primaryColorWhite: #FFFFFF;
$ColorDarkGray: #6B6B6B;
$primaryColorGreen: #009C3B;


@mixin respond($breakpoint) {
  @if $breakpoint ==phone {
    @media (max-width: 480px) {
      @content
    }

    ;
  }

  @if $breakpoint ==phone-land {
    @media (min-device-width: 481px) and (max-device-width: 767px) and (orientation: landscape) {
      @content
    }

    ;
  }

  @if $breakpoint ==tab-port {
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      @content
    }

    ;
  }

  @if $breakpoint ==tab-land {
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      @content
    }

    ;
  }
  @if $breakpoint ==portView {
    @media (max-width: 1024px) {
      @content
    }

    ;
  }
  @if $breakpoint ==laptop {
    @media (min-width: 1024px) and (max-width: 1366px) {
      @content
    }

    ;
  }
  @if $breakpoint ==lg-deviec {
    @media (max-width: 1200px) {
      @content
    }

    ;
  }
  @if $breakpoint ==sm-deviec {
    @media (max-width: 991px) {
      @content
    }

    ;
  }

  @if $breakpoint ==big-desk {
    @media (min-width: 1367px) {
      @content
    }

    ;
  }
}