@import '../../assets/scss/variables';
.horizontal-tab-block {
  border: 1px solid #e8e8e8;
  // padding: 20px;
  padding-top: 5px;

  .nav.nav-tabs {
    width: 100%;
    position: sticky;
    top: 148px;
    z-index: 999;
    background: #fff;
    @media screen and (max-width:991px) {
      top: 90px;
    }
    @media screen and (max-width:700px) and (min-width:300px) {
      top: 120px;
    }

    .nav-item {
      margin-right: auto;
      .nav-link {
        color: #6b6b6b;
        font-size: 16px;
        //&.active {
        //    font-weight: bold;

        //}
      }
    }
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-color: transparent !important;
  }
  .nav-tabs .nav-link.active:hover,
  .nav-tabs .nav-link:hover {
    border-color: #3273de;
  }
  .tab-content {
    .accordion {
      border: 1px solid #ececed;
      margin-top: 20px;
      .accordion-item {
        border: none;
        margin: 0;
        &:not(:last-child) {
          .accordion-header {
            .accordion-button {
              &:not(.collapsed) {
                &::after {
                  display: none;
                }
              }
              &::after {
                display: none;
              }
            }
          }
        }
        .accordion-header {
          .accordion-button {
            font-size: 15px;
            padding: 15px;
            padding-bottom: 28px;
            position: relative;
            // border: 3px solid transparent;
            border-left: 3px solid transparent;
            &:not(.collapsed) {
              background-color: #ececed;
              border-left: 3px solid #3273de;
              &::after {
                right: 20px;
                border: none;
              }
            }
            &::after {
              //  right: 20px;
              //  border: none;
              margin-left: 0 !important;
              margin-right: auto;
              border: none;
              right: 20px !important;
              height: 23px;
              background-size: 12px;
              background-position: center;
            }

            .sub-text {
              color: #6b6b6b;
              font-size: 13px;
              position: absolute;
              top: 25px;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
  .list-view {
    .list-view-block {
      border: 1px solid #e8e8e8;
      margin-top: 20px;
      margin-left: 0;
      margin-right: 0;
      &.cd {
        .list-view-block-img {
          @include respond(sm-deviec) {
            width: 100%;
            height: auto;
          }
        }
      }
      .list-view-block-img {
        border-radius: 5px;
        // TODO - for virtual classromm css adjustment
        width: 60px;
        height: 60px;
      }
    }
    .head-text {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      // color: #3273de;
      @media screen and (max-width: 767px) and (orientation: portrait) {
        text-align: justify;
      }
    }

    .main-text {
      @media screen and (max-width: 767px) and (orientation: portrait) {
        text-align: justify;
      }
    }
    .sub-text {
      font-size: 1.3rem;
    }
    .list-footer {
      .associated-skill{
          background: transparent;
          border: none;
          color: #3273de;
      }
      background-color: #ececed;
      display: flex;
      padding: 3px 15px;
      color: #707070;
      &.right-side-block{
        flex-direction: column;
        justify-content: space-around;
        background-color: #fff;
        .time-lable{
          justify-content: flex-end;
        }
      }
      @media screen and (max-width: 400px) {
        font-size: 12px;
      }
      img {
        vertical-align: unset;
      }
      .progress {
        width: 30%;
        background-color: #d9d9d9;
        // margin-top: 1px;
        height: 5px !important;
        margin-left: 1rem;
        margin-top: 2px;
      }
      .primary-btn {
        margin-left: auto;
      }
    }
  }
}
.link-pointer {
  cursor: pointer;
  padding: 1rem 0;
  margin-bottom: 0;
  &:hover {
    background-color: #e9ecef;
  }
}
.horizontal-tab-block
  .tab-content
  .hideCollpseExpandArrow
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button::after {
  visibility: hidden;
}
.solutionTechnical {
  input[type='checkbox'] {
    height: 22px;
    width: 22px;
    margin: 0.5rem;
    border-radius: 2px;
  }
  label {
    margin-top: 4px;
    span {
      vertical-align: middle;
      margin-left: 0.5rem;
    }
  }
}

 
  .horizontal-tab-block {
    .tab-content {
      .accordin-right-arrow{
      .accordion {
        .accordion-item {
          .accordion-header {
            .accordion-button {
              &::after {
                // margin-left: auto !important;
                // margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
.horizontal-tab-block {
  .tab-content {
    .accordin-right-arrow{
    .accordion {
      .arrowLeft {
        .accordion-header {
          .accordion-button:not(.collapsed){
            &::after {
              background-color: transparent !important; 
     border: none !important; 
     border-radius: 0 !important;
            }
          }
        }
      }
    }
  }
}
}
.horizontal-tab-block {
  .tab-content {
    .accordin-right-arrow{
    .accordion {
      .arrowLeft {
        .accordion-header {
          .accordion-button{
            &::after {
              background-color: unset !important; 
     border: none !important; 
            }
          }
        }
      }
    }
  }
}
}
.arrowLeft .accordion-button{
  &::after {
    left: 3px !important;
  }
}
.selectedWeekOption {
  background-color: #e9ecef;
}

.info-show{
  position: absolute;
    background-color: #fff;
    left: 16%;
    padding: 8px;
    min-width: 250px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.17);
    border: 1px solid rgb(167, 167, 167);
    z-index: 2;
    top: -49px;
  @media (max-width: 991px) {
    min-width: 300px
  } 
  P{
    margin-bottom: 5px!important;
    font-size: 1.4rem;
  }
  &::after{
    content: "";
    background-image: url(../../assets/images/right-arrow.svg);
    display: inline-block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 35px;
    width: 35px;
    left:10%;
    bottom: -22px;
    rotate: 90deg;
  }
}