@import '../../assets/scss/variables';

.react-multi-carousel-item {
  transform-style: initial;
  // margin-left: 1px;
}

.react-multi-carousel-list {
  padding-bottom: 40px;
  padding-left: 0!important;
  padding-right: 0!important;
  position: unset !important;

  @media only screen and (max-width: 767px) {
    padding-bottom: 0;
  }

  // overflow-x: hidden;
  // overflow: visible;
}

.card-block {
  width: 93%;
  background: #FFFFFF;
  text-align: left;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
  position: relative;
  height: 100%;
  border: 1px solid rgba(204, 204, 204, 0.6);

  // max-width: 394px;
  @media only screen and (max-width: 990px) {
    width: auto;
    margin-right: 20px;
  }

  .card-img-block {
    position: relative;
    overflow: hidden;
    height: 162px;
    // max-height: 180px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    // border-radius: 6px;    
    // border: 1px solid rgba(204, 204, 204, 0.6);
    .due-date-strip {
      position: absolute;
      z-index: 1;
      border-radius: 12px;
      padding: 2px 10px;
      font-size: 1.2rem;
      line-height: normal;
      color: #fff;
      background: #EB1000;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
      margin: 8px 8px 8px 8px;
      &.overdue {
        background: #FFE772;
        color: #2C2C2C;
      }
      &.due-red {
        background: #EB1000;
        color: #ffffff;
      }
      &.due-orange {
        background: #E9740A;
        color: #ffffff;
      }
    }
    img {
      // max-width: 326px;
      // object-fit: contain;
      height: 100%;
      width: 100%;
      filter: brightness(0.95);
      object-fit: cover;

    }

    // three dots
    // &::after {
    //     content: '\2807';
    //     font-size: 40px;
    // }

    span {
      position: absolute;
      top: 10px;
      border-radius: 4px;
      padding: 0 10px;
      color: #fff;
      font-size: 1.5rem;
      right: 0;
    }

    .purple-bg {
      background-color: #a00dcd;
    }
  }

  .card-content {
    // border: 1px solid rgba(204, 204, 204, 0.6);
    padding: 8px 15px 15px;
    flex: 1;
    @media screen and (max-width: 1024px) {
      padding: 12px;
    }
    // position: relative;
    // top: -22px;

    .card-view {
      position: relative;
      top: -70px;
    }
    .listview-flex {
      // height: 70px;
      .time-recent {
        // white-space: nowrap;
        // width: 100%;
        .small-separator {
          margin: 0 20px;
        }
      }
      .btnParent{
        position: relative;
      }
      .btnChild{
        position: absolute;
        top:4rem;
      }
    }

    .activity {
      &:first-child {
        // background-color: $buttonColor-hover;
        border-radius: 6px;
        font-size: 1.4rem;
        // font-weight: bold;
        color: $primaryColorWhite;

        &:active {
          border-color: $buttonColor;
        }
      }
      &:last-child {
        font-size: 1.4rem;
      }

      .flex-end {
        // flex: 1 1;
        // text-align: center;
        span:last-child {
          font-weight: bold;
        }
      }
    }
    .rating-cls {
      .rating-count {
        color: $primaryColorWhite;
        margin: 0 10px;
      }
    }
  }

  .card-title {
    color: #2C2C2C;
    font-size: 1.7rem;
    font-weight: 600;
    //height: 4rem !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .discription {
    font-size: 1.4rem;
    margin: 1rem 0;
    height: 4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .assigned-by {
    color: #6b6b6b;
    font-size: 1.4rem;
    height: 3rem;
    margin: 0;
    .c-date {
      color: $primaryColorRed;
      visibility: hidden;
    }
    span {
      color: $primaryColorBlack;
    }
  }
}
.react-multi-carousel-track {
  // padding-bottom: 30px;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    // padding-bottom: 40px;
  }
  .react-multi-carousel-item {
    // margin-right: 3px;
  }
}
.react-multi-carousel-dot-list {
  bottom: 0px;
}
// .react-multiple-carousel__arrow:disabled
button.react-multiple-carousel__arrow {
  background: $primaryColorWhite;
  border: 2px solid #f0f0f0;
  min-width: 30px;
  min-height: 30px;
  font-weight: 600;
  &::before {
    display: inline-block;
    border-radius: 30px;
    font-size: 1.6rem;
    color: $primaryColorBlack;
  }
  &:hover {
    background: $primaryColorWhite;
    border: 2px solid #f0f0f0;
  }
}
.react-multiple-carousel__arrow:disabled {
  background: $primaryColorWhite;
  border: 2px solid #f0f0f0;
  min-width: 30px;
  min-height: 30px;
  font-weight: 600;
  &::before {
    display: inline-block;
    border-radius: 30px;
    font-size: 1.6rem;
    color: lighten($ColorDarkGray, 30);
  }
  &:hover {
    background: $primaryColorWhite;
    border: 2px solid #f0f0f0;
  }
}

.react-multiple-carousel__arrow--left {
  &::before {
    font-size: 1.6rem;
    display: inline-block;
    border-radius: 30px;
    color: $primaryColorBlack;
  }
}
.react-multiple-carousel__arrow--right {
  bottom: 25px;
  right: 50%;
  margin-right: -240px;
  @media screen and (max-width: 992px) {
    margin-right: -40px;
  }
}
.react-multiple-carousel__arrow--left {
  bottom: 25px;
  left: 50%;
  margin-left: -240px;
 
  @media screen and (max-width: 992px) {
    margin-left: -40px;
  }
 
}
.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow {
  padding: 0;
}

.foolter-link {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.progress {
  overflow: visible;
  margin-top: -5px;
  height: 4px !important;
  margin-bottom: 0 !important;
  background-color: gray;
  .progress-bar {
    // background: $secondaryColor;
    background: transparent linear-gradient(90deg, $primaryColorGreen 0%, $primaryColorGreen 100%)
      0% 0% no-repeat padding-box;
    width: 50%;
  }

  span {
    position: absolute;
    z-index: 1;
    background: $secondaryColor;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 1.1rem;
    visibility: hidden;
    // left: 50%;
  }
}
.coure-duration {
  color: #707070;
  font-size: 1.4rem;
  display: flex;
  padding-top: 2px;
  .time {
    display: inline-block;
    padding-left: 10px;
  }
  .progress-status {
    margin-left: auto;
    text-align: right;
  }
}
.star svg {
  font-size: 1.3rem;
  color: #ffffff;

  .cy {
    color: #fec030;
  }
}

.time-recent {
  color: #6b6b6b;
  padding-bottom: 5px;
  font-size: 1.4rem;
  .clock-img {
    padding-right: 8px;
    margin-top: -5px;
  }
  .user-img {
    margin-top: -5px;
  }
  .progress-status {
    float: right;
  }
}
.Carousel-block .carousel-container .react-multi-carousel-track .react-multi-carousel-item .card-block .userIMG{
  margin-top: 0;
} 
.react-multi-carousel-dot button {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background: #a7a7a7;
}
.react-multi-carousel-dot--active button {
  background: #3273de;
}
.carousel-container {
  position: relative;
}

.prev-button,
.next-button {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: transparent;
    font-weight: bold;
    border: none;
}

.prev-button {
  left: -20px;
  &::before {
    content: "\e824";
    display: inline-block;
    font-size: 1.6rem;
    color: #2C2C2C;
    font-family: revicons,sans-serif;
    text-align: center;
    z-index: 2;
}
&.disable-btn
{
  cursor: default;
  pointer-events: none;
  &::before{
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
}
.left-info .prev-button {
  left: -35px;
}
.left-info .next-button {
    right: -25px;
  }
.next-button {
  right: -5px;
  &::before {
    content: "\e825";
    display: inline-block;
    border-radius: 30px;
    font-size: 1.6rem;
    color: #2C2C2C;
    font-family: revicons,sans-serif;
    text-align: center;
    z-index: 2;
}
&.disable-btn{
  cursor: default;
  pointer-events: none;
  &::before{
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
}
