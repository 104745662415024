/*Theme Colors*/
.allCourses .filterbtn {
  display: none;
}
@media (max-width: 767px) {
  .allCourses .filterbtn {
    display: block;
    font-size: 2.1rem;
    font-weight: bold;
  }
}
.allCourses .search-cls:focus {
  border-bottom: none;
}
@media (max-width: 767px) {
  .allCourses .leftSectionAllCourses {
    display: none;
  }
}
.allCourses .leftSectionAllCourses {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background: #fff;
  scrollbar-width: thin;
}
@-moz-document url-prefix() {
  .allCourses .leftSectionAllCourses.scroller {
    overflow-y: scroll;
    scrollbar-color: #d9d6d6 #888;
    scrollbar-width: thin;
  }
}
.allCourses .leftSectionAllCourses.scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
  height: 0.5rem;
}
.allCourses .leftSectionAllCourses.scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.allCourses .leftSectionAllCourses .titleHead {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 1.5rem;
}
.allCourses .leftSectionAllCourses .titleHead .filterBy {
  font-size: 2.1rem;
  font-weight: bold;
  white-space: nowrap;
}
@media screen and (max-width: 991px) {
  .allCourses .leftSectionAllCourses .titleHead .filterBy {
    font-size: 1.9rem;
  }
}
.allCourses .leftSectionAllCourses .titleHead .btn-view {
  font-size: 1.5rem;
  color: #2799f6;
  font-weight: 600;
  white-space: nowrap;
}
.allCourses .leftSectionAllCourses .subHead {
  font-size: 1.7rem;
  color: #6b6b6b;
  font-weight: bold;
}
.allCourses .leftSectionAllCourses .scroller {
  max-height: 14.4rem;
  overflow: auto;
  scrollbar-width: thin;
}
@-moz-document url-prefix() {
  .allCourses .leftSectionAllCourses .scroller {
    overflow-y: scroll;
    scrollbar-color: #d9d6d6 #888;
    scrollbar-width: thin;
  }
}
.allCourses .leftSectionAllCourses .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
  height: 0.5rem;
}
.allCourses .leftSectionAllCourses .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.allCourses .leftSectionAllCourses .filterCheckbox.form-check {
  position: relative;
  padding-left: 2em;
}
.allCourses .leftSectionAllCourses .filterCheckbox.form-check .form-check-input {
  position: absolute;
  top: 0;
  left: 2rem;
}
.allCourses .rightSectionAllCourses {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.allCourses .rightSectionAllCourses .css-1u9des2-indicatorSeparator {
  display: none;
}
.allCourses .rightSectionAllCourses .css-1nmdiq5-menu div {
  max-height: 50rem;
  width: auto;
  overflow: auto;
}
@media (max-width: 449px) {
  .allCourses .rightSectionAllCourses .css-1nmdiq5-menu {
    min-width: 9rem;
    overflow: auto;
  }
}
.allCourses .rightSectionAllCourses .css-1nmdiq5-menu div::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.allCourses .rightSectionAllCourses .css-1nmdiq5-menu div::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.allCourses .rightSectionAllCourses .card-block {
  width: 100%;
  background: #ffffff;
  text-align: left;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
}

@media screen and (max-width: 767px) {
  .mobileCard {
    display: flex !important;
    margin-left: 0 !important;
  }
}
.mobileCard .infinite-scroll-component__outerdiv {
  width: 100%;
}

.centeredCards .infinite-scroll-component__outerdiv {
  width: 100%;
}

.success-modal-container-mobile {
  padding: 1rem;
}
.success-modal-container-mobile .titleHead {
  border-bottom: 1px solid #e8e8e8;
}
.success-modal-container-mobile .titleHead .filterBy {
  font-size: 1.7rem;
  font-weight: bold;
}
.success-modal-container-mobile .titleHead .btn-view {
  font-size: 1.4rem;
  color: #2799f6;
  font-weight: 600;
}
.success-modal-container-mobile .subHead {
  font-size: 1.7rem;
  color: #6b6b6b;
}
.success-modal-container-mobile .scroller {
  max-height: 13.6rem;
  overflow: auto;
  scrollbar-width: thin;
}
.success-modal-container-mobile .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.success-modal-container-mobile .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}

.searh-icon {
  background-image: url("../../assets/images/search.png");
  background-repeat: no-repeat;
  background-position: 99%;
}
.searh-icon::-moz-placeholder {
  color: red;
}
.searh-icon::placeholder {
  color: red;
}

.icon-class {
  display: none;
}

.css-1nmdiq5-menu .icon-class {
  display: block;
}

.all-courses-info {
  display: flex;
  flex-direction: row;
}
@media (max-width: 991px) {
  .all-courses-info {
    flex-direction: column;
  }
}
.all-courses-info .right-info {
  flex: 80%;
  width: 80%;
  transform: translateX(0px);
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1280px) {
  .all-courses-info .right-info {
    flex: 70%;
    width: 70%;
  }
}
@media (max-width: 991px) {
  .all-courses-info .right-info {
    width: 100%;
    flex: 100%;
    padding-left: 0px;
  }
}
.all-courses-info .left-info {
  flex: 22% 1;
  margin-bottom: 3rem;
  padding-right: 20px;
}
@media (max-width: 991px) {
  .all-courses-info .left-info {
    display: none;
  }
}

.sidebarOther {
  position: relative !important;
  z-index: 4;
  width: calc(100% + 0px);
}

.sidebarBotton {
  position: fixed;
  bottom: 60px; /* Stick to the bottom */
  top: auto;
  width: calc(20% - 6px);
}
@media (max-width: 1720px) {
  .sidebarBotton {
    width: calc(20% - 10px);
  }
}
@media (max-width: 1660px) {
  .sidebarBotton {
    width: calc(20% - 13px);
  }
}
@media (max-width: 1366px) {
  .sidebarBotton {
    width: calc(20% - 14px);
  }
}
@media (max-width: 1280px) {
  .sidebarBotton {
    width: calc(24% - 14px);
  }
}
@media (max-width: 1024px) {
  .sidebarBotton {
    width: calc(24% - 20px);
  }
}
@media (max-width: 991px) {
  .sidebarBotton {
    position: relative;
    bottom: none;
    width: 100%;
  }
}/*# sourceMappingURL=SearchCourseDetails.css.map */