/*Theme Colors*/
.headerTeam {
  border: 1px solid #e8e8e8;
  padding: 2.5rem 2rem;
  position: relative;
}
.headerTeam .resetbtnFont .btn {
  border-width: 0;
}
.headerTeam .resetbtnFont .btn-view {
  font-size: 1.5rem;
  color: #2799f6;
  font-weight: 600;
}
.headerTeam .resetbtnFont .btn-view:active {
  border: none;
}
.headerTeam .resetbtnFont .btn-view:first-child:active {
  border: none;
}
.headerTeam .resetbtnFont .btn-view:hover {
  color: var(--bs-btn-active-color);
}
.headerTeam .input-group {
  border-bottom: 1px solid #e8e8e8;
  width: 20rem;
}
@media screen and (max-width: 767px) {
  .headerTeam .input-group {
    width: 100%;
  }
}
.headerTeam .input-group input {
  font-size: 1.5rem;
  padding: 10px 30px 5px 5px;
}
.headerTeam .search-icon {
  padding: 0px;
  background-image: url("../../assets/images/search-bar.svg");
  background-repeat: no-repeat;
  background-position: 95%;
  border: none;
}
.headerTeam .css-13cymwt-control .css-1u9des2-indicatorSeparator {
  width: 0px;
}
.headerTeam .filterMargin {
  margin-bottom: 0.05rem;
  color: #6B6B6B;
}
.headerTeam .css-1nmdiq5-menu .scroller::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.3rem;
  background-color: #d9d6d6;
}
.headerTeam .css-1nmdiq5-menu div {
  width: 100%;
}
.headerTeam .css-1nmdiq5-menu div::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
  background-color: #d9d6d6;
}
.headerTeam .css-1nmdiq5-menu div::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.headerTeam .css-1dimb5e-singleValue, .headerTeam .css-1jqq78o-placeholder {
  font-weight: bold;
}

.myteam-search {
  position: absolute;
  top: 4.5rem;
}
@media screen and (max-width: 1023px) {
  .myteam-search {
    position: relative;
    top: unset;
    width: 100%;
    margin-bottom: 2rem;
  }
}

.headerTable {
  background-color: #f6f5ff;
  border: 1px solid #ececed;
}

.tableContainer {
  position: relative;
  border: 1px solid #ececed;
  font-size: 1.7rem;
}
.tableContainer .rol-nme {
  color: #2799f6;
}
.tableContainer .table {
  margin-bottom: 0;
}
.tableContainer .table tr {
  border-width: 1px;
}
.tableContainer .table .columnHeaders {
  color: #6b6b6b;
  font-size: 1.7rem;
  text-align: center;
  padding: 1rem;
  white-space: nowrap;
  width: 12.5%;
}
.tableContainer .table .columnHeaders:first-child {
  text-align: left;
  width: 15%;
}
.tableContainer .table .columnHeaders:last-child {
  width: 10%;
}
.tableContainer .table .columnHeaders span svg {
  position: absolute;
  top: 0.9rem;
  height: 11px;
  width: 14px;
}
.tableContainer .table .columnHeaders .sortIcon {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  flex-wrap: nowrap;
}
.tableContainer .table .columnHeaders .sortIcon .cpb-1-up {
  height: auto;
}
.tableContainer .table .columnData {
  color: #2c2c2c;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
  vertical-align: middle;
}
.tableContainer .table .columnData:first-child {
  text-align: left;
}

.status-notStart {
  border: 0px solid #2c2c2c !important;
  background-color: #dddbdb !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 11rem !important;
}

.status-approvd {
  border: 0px solid #2c2c2c !important;
  background-color: #dbf2e4 !important;
  color: #009c3b !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 11rem !important;
}

.status-awaitng {
  border: 0px solid #2c2c2c !important;
  background-color: #daecff !important;
  color: #3273de !important;
  padding: 1px 20px !important;
  font-size: 1.3rem !important;
  border-radius: 30px !important;
  width: 11rem !important;
}

.manager-my-team {
  display: none;
}

.manager-my-team-filter-by-skill {
  display: none;
}

.filterBy,
.pgno,
.select-option {
  margin-right: 15px;
}

.noData {
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;
}/*# sourceMappingURL=MyTeam.css.map */