/*Theme Colors*/
.signin-block {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.76) 10%, rgba(255, 255, 255, 0)), url(../../assets/images/login_bg.png) no-repeat fixed;
  background-size: cover;
  background-position: right;
  position: relative;
}
@media (max-width: 1024px) {
  .signin-block {
    background-position: center top;
  }
}
.signin-block .login-block-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 50rem;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .signin-block .login-block-txt {
    left: 50%;
    width: auto;
  }
}
.signin-block .login-block-txt .login-box {
  border-radius: 5px;
  background: #fff;
  align-items: center;
  display: flex;
  padding: 3rem;
  flex-direction: column;
}
.signin-block .login-text {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
}
.signin-block .login-text .president-text {
  color: #6B6B6B;
}
.signin-block .round-cta, .signin-block .fill {
  border-radius: 20px;
  background: #eff6ff;
  color: var(--secondaryColor);
  border: 1px solid #cbcdfa;
  padding: 8px 26px;
  font-size: 1.6rem;
  font-weight: 600;
}
.signin-block .fill {
  border-color: var(--buttonColor);
  background: #EB1000;
  color: #FFFFFF;
  margin-left: 14px;
}

.headding-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  font-weight: 700;
  color: #EB1000;
}
.headding-title span {
  font-weight: 400;
}/*# sourceMappingURL=LoginFailed.css.map */