
.side-menu .accordion {
    border: 1px solid #ececed;
    margin-top: 20px;
  }
  .side-menu .accordion .accordion-item {
    border: none;
    margin: 0;
  }
  .side-menu .accordion .accordion-item:not(:last-child) .accordion-header .accordion-button:not(.collapsed)::after {
    display: none;
  }
  .side-menu .accordion .accordion-item:not(:last-child) .accordion-header .accordion-button::after {
    display: none;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button {
    border: none;
    font-size: 1.6rem;
    padding: 15px;
    padding-bottom: 20px;
    position: relative;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button[aria-expanded=true] {
    background-color: #f6f5ff;
    border-left: 3px solid #3273de !important;
    font-weight: bold;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button:hover {
    background-color: #f6f5ff;
    border-left: 3px solid #3273de;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #ececed;
    border-left: 2px solid #3273de;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    right: 20px;
    border: none;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button::after {
    right: 20px;
    border: none;
  }
  .side-menu .accordion .accordion-item .accordion-header .accordion-button .sub-text {
    color: #6b6b6b;
    font-size: 1.4rem;
    position: absolute;
    top: 25px;
    padding-top: 7px;
  }
  .side-menu .accordion::after {
    right: 20px;
    border: none;
  }
  .side-menu .accordion .sub-text {
    color: #6b6b6b;
    font-size: 1.4rem;
    position: absolute;
    top: 25px;
    padding-top: 7px;
  }
  
  .side-menu .accordin-right-arrow .accordion .arrowLeft .accordion-header .accordion-button:not(.collapsed)::after {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
  }
  
  .side-menu .accordin-right-arrow .accordion .arrowLeft .accordion-header .accordion-button::after {
    background-color: unset !important;
    border: none !important;
  }
  
  .arrowLeft .accordion-button::after {
    left: 3px !important;
  }
  
  .no-content-data {
    border: 1px solid #e8e8e8;
    padding: 1rem;
    margin-top: 2rem !important;
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .arrow {
    border: solid #333;
    border-width: 0 1.4px 1.4px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.3s ease;
  }
   
  .arrow.right, .arrow.down {
    transform: rotate(45deg);
    position: absolute;
    left: 4%;
    top: 32%;
  }
   
  .arrow.down {
    transform: rotate(225deg);
  }
  
  .nestedSubmenuArrow.right, .nestedSubmenuArrow.down {
    transform: rotate(315deg);
    position: absolute;
    left: 4%;
    top: 32%;
  }
   
  .nestedSubmenuArrow.down {
    transform: rotate(45deg);
  }
   
  .accordionArrow .accordion .accordion-item .accordion-header .accordion-button[aria-expanded="true"] {
    font-weight: 700;
  }
   
  .horizontal-tab-block .accordionArrow .accordion .accordion-item .accordion-header .accordion-button{
    font-size: 15px;
    padding: 15px 15px 15px;
  }
  /* .no-content-data {
    .no-content-data {
      border: none;
      padding: 0;
      margin-top: 0 !important;
      height: auto;
    }
  } */
  