/*Theme Colors*/
.react-multi-carousel-item {
  transform-style: initial;
}

.react-multi-carousel-list {
  padding-bottom: 40px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: unset !important;
}
@media only screen and (max-width: 767px) {
  .react-multi-carousel-list {
    padding-bottom: 0;
  }
}

.card-block {
  width: 93%;
  background: #FFFFFF;
  text-align: left;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
  position: relative;
  height: 100%;
  border: 1px solid rgba(204, 204, 204, 0.6);
}
@media only screen and (max-width: 990px) {
  .card-block {
    width: auto;
    margin-right: 20px;
  }
}
.card-block .card-img-block {
  position: relative;
  overflow: hidden;
  height: 162px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.card-block .card-img-block .due-date-strip {
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 1.2rem;
  line-height: normal;
  color: #fff;
  background: #EB1000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  margin: 8px 8px 8px 8px;
}
.card-block .card-img-block .due-date-strip.overdue {
  background: #FFE772;
  color: #2C2C2C;
}
.card-block .card-img-block .due-date-strip.due-red {
  background: #EB1000;
  color: #ffffff;
}
.card-block .card-img-block .due-date-strip.due-orange {
  background: #E9740A;
  color: #ffffff;
}
.card-block .card-img-block img {
  height: 100%;
  width: 100%;
  filter: brightness(0.95);
  -o-object-fit: cover;
     object-fit: cover;
}
.card-block .card-img-block span {
  position: absolute;
  top: 10px;
  border-radius: 4px;
  padding: 0 10px;
  color: #fff;
  font-size: 1.5rem;
  right: 0;
}
.card-block .card-img-block .purple-bg {
  background-color: #a00dcd;
}
.card-block .card-content {
  padding: 8px 15px 15px;
  flex: 1;
}
@media screen and (max-width: 1024px) {
  .card-block .card-content {
    padding: 12px;
  }
}
.card-block .card-content .card-view {
  position: relative;
  top: -70px;
}
.card-block .card-content .listview-flex .time-recent .small-separator {
  margin: 0 20px;
}
.card-block .card-content .listview-flex .btnParent {
  position: relative;
}
.card-block .card-content .listview-flex .btnChild {
  position: absolute;
  top: 4rem;
}
.card-block .card-content .activity:first-child {
  border-radius: 6px;
  font-size: 1.4rem;
  color: #FFFFFF;
}
.card-block .card-content .activity:first-child:active {
  border-color: var(--buttonColor);
}
.card-block .card-content .activity:last-child {
  font-size: 1.4rem;
}
.card-block .card-content .activity .flex-end span:last-child {
  font-weight: bold;
}
.card-block .card-content .rating-cls .rating-count {
  color: #FFFFFF;
  margin: 0 10px;
}
.card-block .card-title {
  color: #2C2C2C;
  font-size: 1.7rem;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
}
@supports (-webkit-line-clamp: 2) {
  .card-block .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.card-block .discription {
  font-size: 1.4rem;
  margin: 1rem 0;
  height: 4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
}
@supports (-webkit-line-clamp: 2) {
  .card-block .discription {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.card-block .assigned-by {
  color: #6b6b6b;
  font-size: 1.4rem;
  height: 3rem;
  margin: 0;
}
.card-block .assigned-by .c-date {
  color: #EB1000;
  visibility: hidden;
}
.card-block .assigned-by span {
  color: #2C2C2C;
}

.react-multi-carousel-track {
  justify-content: space-between;
}
.react-multi-carousel-dot-list {
  bottom: 0px;
}

button.react-multiple-carousel__arrow {
  background: #FFFFFF;
  border: 2px solid #f0f0f0;
  min-width: 30px;
  min-height: 30px;
  font-weight: 600;
}
button.react-multiple-carousel__arrow::before {
  display: inline-block;
  border-radius: 30px;
  font-size: 1.6rem;
  color: #2C2C2C;
}
button.react-multiple-carousel__arrow:hover {
  background: #FFFFFF;
  border: 2px solid #f0f0f0;
}

.react-multiple-carousel__arrow:disabled {
  background: #FFFFFF;
  border: 2px solid #f0f0f0;
  min-width: 30px;
  min-height: 30px;
  font-weight: 600;
}
.react-multiple-carousel__arrow:disabled::before {
  display: inline-block;
  border-radius: 30px;
  font-size: 1.6rem;
  color: #b8b8b8;
}
.react-multiple-carousel__arrow:disabled:hover {
  background: #FFFFFF;
  border: 2px solid #f0f0f0;
}

.react-multiple-carousel__arrow--left::before {
  font-size: 1.6rem;
  display: inline-block;
  border-radius: 30px;
  color: #2C2C2C;
}

.react-multiple-carousel__arrow--right {
  bottom: 25px;
  right: 50%;
  margin-right: -240px;
}
@media screen and (max-width: 992px) {
  .react-multiple-carousel__arrow--right {
    margin-right: -40px;
  }
}

.react-multiple-carousel__arrow--left {
  bottom: 25px;
  left: 50%;
  margin-left: -240px;
}
@media screen and (max-width: 992px) {
  .react-multiple-carousel__arrow--left {
    margin-left: -40px;
  }
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow {
  padding: 0;
}

.foolter-link {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.progress {
  overflow: visible;
  margin-top: -5px;
  height: 4px !important;
  margin-bottom: 0 !important;
  background-color: gray;
}
.progress .progress-bar {
  background: transparent linear-gradient(90deg, #009C3B 0%, #009C3B 100%) 0% 0% no-repeat padding-box;
  width: 50%;
}
.progress span {
  position: absolute;
  z-index: 1;
  background: var(--secondaryColor);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 1.1rem;
  visibility: hidden;
}

.coure-duration {
  color: #707070;
  font-size: 1.4rem;
  display: flex;
  padding-top: 2px;
}
.coure-duration .time {
  display: inline-block;
  padding-left: 10px;
}
.coure-duration .progress-status {
  margin-left: auto;
  text-align: right;
}

.star svg {
  font-size: 1.3rem;
  color: #ffffff;
}
.star svg .cy {
  color: #fec030;
}

.time-recent {
  color: #6b6b6b;
  padding-bottom: 5px;
  font-size: 1.4rem;
}
.time-recent .clock-img {
  padding-right: 8px;
  margin-top: -5px;
}
.time-recent .user-img {
  margin-top: -5px;
}
.time-recent .progress-status {
  float: right;
}

.Carousel-block .carousel-container .react-multi-carousel-track .react-multi-carousel-item .card-block .userIMG {
  margin-top: 0;
}

.react-multi-carousel-dot button {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background: #a7a7a7;
}

.react-multi-carousel-dot--active button {
  background: #3273de;
}

.carousel-container {
  position: relative;
}

.prev-button,
.next-button {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: transparent;
  font-weight: bold;
  border: none;
}

.prev-button {
  left: -20px;
}
.prev-button::before {
  content: "\e824";
  display: inline-block;
  font-size: 1.6rem;
  color: #2C2C2C;
  font-family: revicons, sans-serif;
  text-align: center;
  z-index: 2;
}
.prev-button.disable-btn {
  cursor: default;
  pointer-events: none;
}
.prev-button.disable-btn::before {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.left-info .prev-button {
  left: -35px;
}

.left-info .next-button {
  right: -25px;
}

.next-button {
  right: -5px;
}
.next-button::before {
  content: "\e825";
  display: inline-block;
  border-radius: 30px;
  font-size: 1.6rem;
  color: #2C2C2C;
  font-family: revicons, sans-serif;
  text-align: center;
  z-index: 2;
}
.next-button.disable-btn {
  cursor: default;
  pointer-events: none;
}
.next-button.disable-btn::before {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}/*# sourceMappingURL=sliderStyle.css.map */