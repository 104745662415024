.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 6rem;
    margin: 15px !important;
}

.pagination button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: #f0f0f0;
    color: #A7A7A7;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button.active {
    background-color: #3273DE;
    color: #fff;
}

.pagination .arrow-button {
    background: #FFFFFF;
    border: 2px solid #f0f0f0;
    min-width: 30px;
    min-height: 30px;
    font-weight: 600;
}

.pagination .arrow-button::before{
    content: "\e825";
    display: inline-block;
    border-radius: 30px;
    font-size: 1.6rem;
    color: #2C2C2C;
    font-family: revicons,Adobe Clean,sans-serif;
    text-align: center;
    z-index: 2;
    position: relative;
}

.pagination .arrow-button-prev {
    background: #FFFFFF;
    border: 2px solid #f0f0f0;
    min-width: 30px;
    min-height: 30px;
    font-weight: 600;
}

.pagination .arrow-button-prev::before{
    content: "\e824";
    display: inline-block;
    border-radius: 30px;
    font-size: 1.6rem;
    color: #2C2C2C;
    font-family: revicons,Adobe Clean,sans-serif;
    text-align: center;
    z-index: 2;
    position: relative;
}
  