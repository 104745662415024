@import '../../assets/scss/variables';
.Carousel-block.sioenablement {
  border: 1px solid hsla(0, 0%, 80%, 0.6);
  border-radius: 5px;
  //   padding: 15px !important;
  .title-block {
    padding: 15px;
    min-height: 35px;
  }
  .card {
    border: none;
    .card-img-top {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }

    .card-body {
      text-align: center;
      .card-title {
        font-size: 1.6rem;
        color: $primaryColorBlack;
        font-weight: 600;
      }
      .card-subtitle {
        font-size: 1.5rem;
        color: #6b6b6b;
      }
      .card-text {
        font-size: 1.3rem;
        color: #a7a7a7;
      }
    }
  }
  .reachout {
    background-color: $primaryColorWhite;
    height: 100%;
    padding: 0;
    border-left: 1px solid #e8e8e8;
    .mail {
      // padding: 2rem;
      .desc-text {
        padding-top: 10px;
      }
      a {
        color: #2799f6;
        text-decoration: none;
      }
    }
    .submit-file {
      // padding: 2rem;

      .desc-text {
        padding-top: 10px;
      }
      .submit-enablement {
        background-color: $primaryColorRed;
        color: $primaryColorWhite;
        font-weight: 600;
        font-size: 1.5rem;
        border-radius: 25px;
        margin-top: 10px;
      }
    }
  }
  .dotted-border {
    border-bottom: 1.5px dashed #e8e8e8;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .react-multiple-carousel__arrow--right {
    bottom: 25px;
    right: 50%;
    margin-right: -240px;
    @media screen and (max-width: 992px) {
      margin-right: -40px;
    }
  }
  .react-multiple-carousel__arrow--left {
    bottom: 25px;
    left: 50%;
    margin-left: -240px;
   
    @media screen and (max-width: 992px) {
      margin-left: -40px;
    }
   
  }
  .react-multi-carousel-list{
    width: 100%;
  }
  .prev-button {
  left: 0px;
  }
  .next-button {
    right:0px;
  }
}
