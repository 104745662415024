@import '../../assets/scss/variables';

.course_details {
  
  .card-footer {
    background-color: #f6f5ff;
    margin-bottom: 1rem;
    // color: #6B6B6B;
  }
  .sharebtn-box {
    position: relative;
  }
  .sharebtn {
    //background-color: transparent;
    background: #00000033;
    color: #ffffff;
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    float: right;
    margin: 1rem 2.5rem;
    border-color: #fff;
    img {
      vertical-align: revert;
      margin-right: 0.25rem;
      // padding: 0.5rem;
      height: 10px;
    }
  }
  .sharebtn-dropdown {
    background: #F5F5F5;
    border-radius: 4px;
    box-shadow: 0px 1px 2px #00000029;
    color: #2C2C2C;
    position: absolute;
    width: 100%;
    right: 20%;
    top: 48px;
    z-index: 2;
    .share-item {
      display: flex;
      padding: 10px 15px;
      cursor: pointer;
      // align-items: flex-start;
      // justify-items: flex-start;
      .icon {
        display: inline-flex;
        margin-right: 10px;
        position: relative;
        top: 1px;
      }
      .item-name {
        display: inline-flex;
      }
      // &:hover {
      //   background: #ffffff;
      //   color: #3273DE;
      //   border-radius: 4px;
      // }
      &:hover {
        background: #3273DE !important;
        color: #fff !important;
        text-shadow: none !important;
        transition: none;
        border-radius: 4px;
        .icon img {
          filter: grayscale(100%) brightness(500%) sepia(0%) hue-rotate(-50deg) saturate(100%) contrast(2);
        }
      }
    }
  }
  .percentageValue {
    color: #fff;
  }
  .right-bar {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 3px;
      width: 1.5px;
      height: 1.2rem;
      background-color: #dadada;
    }
  }
  h4 {
    padding-left: 1.5rem;
    font-size: 1.5rem;
    color: #ffffff;
  }
  //  .course-rating{
  //	i{

  //	}
  //  }
  .custom-width {
    width: 50%;
    @media screen and (max-width: 1024px) {
      width: 62%;
    }
    .pi-star {
      color: #fff;
    }
  }
  .enrollbtn {
    width: 100%;
  }
  .sub-menu-list {
    .sub-menu {
      img {
        vertical-align: unset;
        top: 2px;
        position: relative;
      }
    }
  }
  .rating-container {
    border: 1px solid #e8e8e8;
    .rating-title {
      font-size: 1.7rem;
      font-weight: bold;
    }
    .feedback-content .feedback-input {
      border: 1px solid #e8e8e8;
      width: 100%;
      height: 8rem;
      resize: none;
    }
    .ratingImg {
      img {
        margin-right: 0.5rem;
      }
    }
  }
  .roleSkill {
    border: 1px solid #e8e8e8;
    .roleSkillsTitle {
      font-size: 1.7rem;
      font-weight: bold;
    }
    .btn-view {
      font-size: 1.5rem;
      color: #2799f6;
      font-weight: normal;
      text-decoration: none;
    }
    .submitapproval {
      padding: 0.7rem;
      text-transform: none;
      min-width: 19rem;
      // @media screen and (max-width:1602px) {
      //   width: 100%;
      // }
      // @media screen and ((min-width:1444px) and (max-width:1602px)) {
      //   padding: 0.2rem;
      // }
    }
    .footSection {
      flex-wrap: wrap;
    }
  }
  .scroller {
    max-height: 10rem;
    overflow: auto;
    scrollbar-width: thin;
  }
  .scroller::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #d9d6d6;
  }

  .scroller::-webkit-scrollbar-thumb {
    background-color: #888888;
  }
  
  .horizontal-tab-block{
    // border: none;
      border-top: 1px solid #e1e1e1;
    padding: 0 10px 10px 10px;
    position: relative;
    &.blue-border{
      border : 2px solid #bdd4e7;
    } 
  }
}


.list-view {
  .list-view-block {
    border: 1px solid #e8e8e8;
    //width: 100%;
    //margin-left: 2rem;

    .list-view-block-img {
      border-radius: 5px;
      width: 98px;
      height: 68px;
      @include respond(sm-deviec) {
        width: auto;
        height: auto;
      }
    }
    .text-padding {
      padding: 1rem;
      //  @include respond(lg-deviec) {
      //    padding: 0 2rem;
      //  }
    }

    // .list-footer {
    .inner-module-name {
      background-color: #f5f5f5;
      margin-bottom: 0;
      font-weight: bold;
      visibility: hidden;
      height: 0;
    }
    .vc-div {
      background-color: #f5f5f5;
      padding: 1rem 0;

      .list-view-block-img {
        height: 2rem !important;
        width: 2.5rem !important;
        vertical-align: text-bottom;
        @include respond(sm-deviec) {
          width: 2rem;
          height: 2.5rem;
        }
      }
      .locator {
        color: #6b6b6b;
        font-size: 2.1rem;
      }
      .vc-link {
        text-decoration: none;
        color: #2799f6;
      }
      //  .cd {
      //    .list-view-block-img {
      //      @include respond(sm-deviec) {
      //        width: 2rem;
      //        height: 2.5rem;
      //      }
      //    }
      //  }
    }
    // }
  }
  .head-text {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 767px) and (orientation: portrait) {
      text-align: center;
    }
  }
  .sub-text {
    font-size: 1.4rem;
    @media screen and (max-width: 767px) and (orientation: portrait) {
      text-align: justify;
    }
  }
  .module {
    color: #2799f6;
  }
  .list-footer {
    background-color: #ececed;
    display: flex;
    padding: 3px 15px;
    color: #707070;
    flex-wrap: wrap;
    justify-content: space-between;
    .inner-span {
      @include respond(sm-deviec) {
        display: block;
      }
    }
    img {
      vertical-align: unset;
    }
    .progress {
      width: 30%;
      background-color: #d9d9d9;
      margin-top: 1px;
      height: 5px !important;
    }
    .primary-btn {
      margin-left: auto; 
      // margin: 0.5rem 0; removed this as getting issue in button margin
    }
  }
}
.learning-accordion {
  background-color: #ececed;
  border-top: 1px solid #e1e1e1;
  .accordion-content {
    border: 1px solid #e1e1e1;
    // margin-bottom: 1.5rem;
    background-color: #fff;
    .head-text {
      padding-top: 0.5rem;
      @include respond(lg-deviec) {
        padding-top: 1rem;
      }
    }
    //.list-footer {
    //	display: block;
    //  }
  }
  .list-footer {
    //border-top: 1px solid #aeaec1;
    color: #000;
    background-color: transparent;
    //justify-content: flex-end;
    //box-shadow: 1px 0 2px -1px rgba(0, 0, 0, 0.75);
  }
  .inner-content {
    // display: flex;
    // flex-wrap: wrap;
    // @media screen and (max-width:767px) {
    // 	flex-wrap: wrap;
    // }
    .head-text {
      margin-bottom: 0;
    }
    .text-padding {
      padding: 1rem 0;
      .last-visited {
        color: #2799f6;
      }
    }
  }
  .accordion-collapse {
    // padding-left: 3rem;
    .list-view {
      background-color: #fff;
    }
    //.list-view-block {
    //  &:first-of-type {
    //    border: none;
    //  }
    //}
    //&.collapse {
    //  &.show {
    //    background-color: #fff;
    //  }
    //}
    //  .cd{
    //	margin-left: 2rem !important;
    //  }
  }
  .accordionBtn {
    background-image: url('../../assets/images/downarrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: transparent;
    border: none;
    width: 100%;
    &:focus {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
      //  rotate: 90deg;
      //  transition: all 0.2s;
    }
    &:hover {
      background-color: transparent;
    }
    &:focus-visible {
      box-shadow: none;
    }
  }
  //  .list-view-block {
  //    border: 1px solid #aeaec1;
  //  }
  .inner-module-name {
    background-color: #f5f5f5;
    margin-bottom: 0;
    padding: 1rem 0;
    font-weight: bold;
  }
  .vc-div {
    background-color: #f5f5f5;

    .list-view-block-img {
      height: 2rem;
      width: 2.5rem;
    }
    .locator {
      color: #6b6b6b;
      font-size: 2.1rem;
    }
    .vc-link {
      text-decoration: none;
      color: #2799f6;
    }
  }

  .alm-file-upload-conatainer-content {
    display: block;
    margin: 30px auto;
    position: relative;
  }
  .alm-file-upload-content {
    width: 300px;
    margin: 0 auto;
  }
  .alm-file-upload-content .alm-file-upload-icon {
    display: inline-block;
    vertical-align: middle;
    width: 3.1rem;
    height: 3.1rem;
    margin-right: 1rem;
  }
  .alm-file-upload-content .alm-file-upload-input {
    display: inline-block;
    vertical-align: middle;
    width: 22%;
  }
  .alm-file-upload-content .alm-file-upload-label {
    color: #707070;
    font-size: 1.5rem;
    vertical-align: middle;
    width: 30%;
    padding-left: 20px;
  }
  .alm-file-upload-uploaded-files {
    position: absolute;
    left: 27%;
    bottom: -50px;
  }
  .alm-file-upload-uploaded-files .navBackgroundColor {
    margin: 0 5px;
    display: block;
  }
}
.prerequisite {
  padding: 1rem;
  // background: #f6f6f6;
  border: 2px solid #bdd4e7;
  margin: 1rem 0;
  h3 {
    font-weight: bold;
  }
  .list-view {
    background-color: #fff;
  }
}
.accord-cls {
  background-color: #fafafa;
}
.ratingStyles{
  line-height: 18px;
  font-size: 1.7rem;
    display: inline-block;
    height: 20px;
    position: relative;
    top: 3px;
}
.ratingStar{
    font-weight: bold;
    font-size: 1.9rem;
    line-height: 18px;
    position: relative;
    top: 3px;
    margin: 0 3px 0 10px;;
}
.blankstar{
  position: absolute;
    top: 0;
    z-index: 1;
  button{
    cursor: default !important;
  }
  .pi-star{
    font-size: 2.1rem;
  }
}
.fill-star{
  position: relative;
  z-index: 2;
  button{
    cursor: default !important;
    &:active{
      // color: transparent !important;
      border: 1px solid transparent !important;
    }
  }
}
.ratingImg{
  button{
    &:active{
      // color: transparent !important;
      border: 1px solid transparent !important;
    }
  }
}
.file-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 150px;
  margin-bottom: -6px; /* Adjust this value as needed to limit the visible characters */
}
// nested lp sectoin ui
.learning-accordion{
  .accordion-collapse{
    .nested-inner-box{
       .list-view{
          margin:10px;
          margin-bottom: 0;
          .list-view-block{
            margin:0 !important;
          }
     }
     .list-view:last-child{
      padding-bottom: 10px;
     }
    }}}
    .Certificate-block{
      padding : 15px;
      border: 2px solid #bdd4e7;
      .list-view-block{
        background-color: #fff;
        
      }
    }
.horizontal-tab-block .nav.nav-tabs .nav-item {
  margin-right: 3.5rem !important;
}
.details.instructorName{
  position: relative;
  padding-left: 50px !important;
  span{
    position: absolute;
    left: 5px;
    top:-3px;
  }
}