/*Theme Colors*/
.certificaion-all {
  border: 1px solid #E8E8E8;
  padding: 2rem;
}
.certificaion-all .head-text {
  font-size: 1.7rem;
  font-weight: 600;
}
.certificaion-all .card {
  border: none;
}
.certificaion-all .card .card-img-top {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}
.certificaion-all .card .card-body {
  text-align: center;
}
.certificaion-all .card .card-body .card-title {
  font-size: 1.6rem;
  color: #2C2C2C;
  font-weight: 600;
}
.certificaion-all .card .card-body .card-subtitle {
  font-size: 1.5rem;
  color: #6B6B6B;
}
.certificaion-all .card .card-body .card-text {
  font-size: 1.3rem;
  color: #A7A7A7;
}/*# sourceMappingURL=certificationWallPage.css.map */